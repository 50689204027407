import * as React from 'react';
import ButtonWithRoute from '../Common/ButtonWithRoute';

type TrailerLoadingOperationsProps = {
    operationsCanPerform: string[] | undefined
}

// [PALLETS_NUMBER REMOVE] - Remove UpdatePalletsNumber section

export default class TrailerLoadingOperations extends React.PureComponent<TrailerLoadingOperationsProps, {}> {
    public render() {
        const panelButtonClasses = "col-sm btn btn-lg btn-panel";

        return (
            <div className="row">
                {this.canPerform("StartLoading") && <ButtonWithRoute className={panelButtonClasses} path="/zott/startloading">Rozpocznij załadunek</ButtonWithRoute>}
                {this.canPerform("EndLoading") && <ButtonWithRoute className={panelButtonClasses} path="/zott/endloading">Zakończ załadunek</ButtonWithRoute>}
                {this.canPerform("UpdatePalletsNumber") && <ButtonWithRoute className={panelButtonClasses} path="/zott/updatepalletsnumber">Podaj ilość palet</ButtonWithRoute>}
                {this.canPerform("UnloadAtRamp") && <ButtonWithRoute className={panelButtonClasses} path="/zott/unloadatramp">Ustaw na rampie</ButtonWithRoute>}
                {this.canPerform("Shuttle") && <ButtonWithRoute className={panelButtonClasses} path="/zott/shuttle">Wahadło</ButtonWithRoute>}
                {this.canPerform("StartUnloading") && <ButtonWithRoute className={panelButtonClasses} path="/zott/startunloading">Rozpocznij rozładunek</ButtonWithRoute>}
                {this.canPerform("EndUnloading") && <ButtonWithRoute className={panelButtonClasses} path="/zott/endunloading">Zakończ rozładunek</ButtonWithRoute>}
                {this.canPerform("StartExtraRoute") && <ButtonWithRoute className={panelButtonClasses} path="/zott/startextraroute">Trasa extra</ButtonWithRoute>}
            </div>
        );
    }

    private canPerform(operationName:string)
    {
        if (!this.props.operationsCanPerform)
        {
            return false;
        }

        return this.props.operationsCanPerform.indexOf(operationName) >= 0;
    }
}