import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ApplicationState } from '../store';
import * as Authorization from '../store/Authorization';

type NavMenuProps = typeof Authorization.actionCreators & Authorization.AuthorizationState;

class NavMenu extends React.PureComponent<NavMenuProps, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img src="wdowiak_logo.svg" alt="Wdowiak logo" className="menu-logo" />
                            <img src="logo-fresh.svg" alt="fresh logo" className="menu-logo" />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                {this.hasPermission("admin") &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/admin">Panel administratora</NavLink>
                                    </NavItem>
                                }
                                {this.hasPermission("zott:panel") &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/zott">Panel Zott</NavLink>
                                    </NavItem>
                                }
                                {this.hasPermission("status") &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/status">Statusy</NavLink>
                                    </NavItem>
                                }
                                {this.hasPermission("fueling") &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/fueling">Tankowanie</NavLink>
                                    </NavItem>
                                }
                                {this.hasPermission("reports") &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/reports">Raporty</NavLink>
                                    </NavItem>
                                }

                                {!this.props.isLogged &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/login">Zaloguj</NavLink>
                                    </NavItem>
                                }
                                {this.props.isLogged &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/" onClick={this.logoutClicked.bind(this)}>Wyloguj</NavLink>
                                    </NavItem>
                                }
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private logoutClicked()
    {
        this.props.logout();
    }

    private hasPermission(permissionName: string):boolean {
        return this.props.permissions.some(function(permission) {
            return permission.startsWith(permissionName);
        });
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
    (state:ApplicationState) => state.authorization, 
    Authorization.actionCreators
)(NavMenu as any);