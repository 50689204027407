import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { TransportStatus, BriefOperationLogEntry } from '../../types/global';
import { ApiRequest } from '../../services/apiClient';
import Loader from '../Common/Loader';
import { Alert } from 'reactstrap';
import { HasPermission } from '../../services/authService';
import NotificationMessage from '../Common/NotificationMessage';
import { DangerousOperationButtonClasses } from '../../services/styleService';
import { EnhanceBriefOperationLogs } from '../../helpers/DaoHelper';

type ThisFormProps = RouteComponentProps<{ transportId: string }>;

type ThisFormState = {
    transportId: number,
    isLoading: boolean,
    isLoaded: boolean
    logs: BriefOperationLogEntry[],
    transport: TransportStatus | undefined,
    error: string,
    showConfirmation: boolean,
    confirmationTitle: string,
    confirmationText: string,
    confirmationFunction: () => void,
    confirmationButtonText: string,
    redirectUrl: string
};

type TransportOperationLogResponse = {
    transport: TransportStatus,
    logs: BriefOperationLogEntry[]
}

export default class TransportOperationLog extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        const id = parseInt(this.props.match.params.transportId) || 0;
        this.state = {
            transportId: id,
            isLoading: false,
            isLoaded: false,
            logs: [],
            transport: undefined,
            error: '',
            showConfirmation: false,
            confirmationTitle: '',
            confirmationText: '',
            confirmationFunction: () => {},
            confirmationButtonText: '',
            redirectUrl: ''
        }
    }

    public render() {
        if (this.state.redirectUrl)
        {
            return <Redirect to={this.state.redirectUrl} />
        }
        if (this.state.isLoading) {
            return <Loader/>;
        }
        if (!this.state.isLoaded) {
            return null;
        }
        return (
            <div>
                <div className="row">
                    <h3>Historia operacji transportu {this.transportDescription()}</h3>
                </div>
                {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                {HasPermission('zott:managetransportsstate') && <button onClick={() => this.removeTransportEntry()} className={DangerousOperationButtonClasses}>Usuń transport</button>}
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Data/Czas</th>
                            <th>Operacja</th>
                            <th>Dodatkowe informacje</th>
                            {HasPermission('zott:managetransportsstate') && <th>Akcje</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.logs.map((log: BriefOperationLogEntry) => 
                            <tr key={log.id}>
                                <td>{log.operationDateTimeText}</td>
                                <td>{log.operationNameText}</td>
                                <td>{log.additionalInfo}</td>
                                {HasPermission('zott:managetransportsstate') && <td><button className={DangerousOperationButtonClasses} onClick={() => this.setEntryAsFinished(log.id, log.operationNameText)}>Kończący przejazd</button></td>}
                            </tr>
                        )}
                    </tbody>
                </table>
                <NotificationMessage show={this.state.showConfirmation}
                    id="deleteConfirmation"
                    title={this.state.confirmationTitle}
                    message={this.state.confirmationText}
                    cancelButtonText="Anuluj"
                    okButtonText={this.state.confirmationButtonText}
                    cancelFunction={this.cancelConfirmation.bind(this)}
                    confirmationFunction={this.state.confirmationFunction} />
            </div>
        );
    }

    public componentDidMount() {
        this.fetchTransportLogs();
    }

    public transportDescription() {
        if (!this.state.transport) {
            return ''
        }

        return 'WZ=' + this.state.transport.wz;
    }

    private async fetchTransportLogs()
    {
        this.setState({
            isLoading: true
        });

        const formData = new FormData();
        formData.set('transportId', this.state.transportId.toString());

        const response = await ApiRequest('api/zott/transportoperationlog', formData);

        if (response.status !== 200) {
            this.setState({
                isLoading: false,
                isLoaded: true,
                error: 'Bład wczytywania logu transportu'
            });
            return;
        }

        const responseData = await response.json() as TransportOperationLogResponse;

        EnhanceBriefOperationLogs(responseData.logs);

        this.setState({
            isLoaded: true,
            isLoading: false,
            logs: responseData.logs,
            transport: responseData.transport
        });
    }

    private removeTransportEntry()
    {
        this.setState({
            showConfirmation: true,
            confirmationTitle: 'Usuń transport',
            confirmationText: 'Usunąć aktualny transport?',
            confirmationButtonText: 'Usuń',
            confirmationFunction: () => this.doRemoveTransportEntry()
        });
    }

    private async doRemoveTransportEntry()
    {
        const formData = new FormData();
        formData.set('transportId', this.state.transportId.toString());

        const response = await ApiRequest('api/zott/removetransportentry', formData);
        
        if (response.status !== 200) {
            this.setState({
                error: 'Błąd usunięcia transportu',
                showConfirmation: false
            });
            return;
        }

        const result = await response.json() as boolean;
        if (result) {
            this.setState({
                redirectUrl: '/zott/transportsstatus',
                showConfirmation: false
            });
        } else {
            this.setState({
                showConfirmation: false,
                error: 'Błąd usunięcia transportu'
            });
        }
    }

    private setEntryAsFinished(id: number, operationName: string)
    {
        this.setState({
            showConfirmation: true,
            confirmationTitle: 'Ustaw jako zakończenie transportu',
            confirmationText: `Ustawić operację ${operationName} jako zakończenie transportu?`,
            confirmationButtonText: 'Ustaw',
            confirmationFunction: () => this.doSetEntryAsFinished(id)
        });
    }

    private async doSetEntryAsFinished(id : number)
    {
        const formData = new FormData();
        formData.set('operationLogEntryId', id.toString());

        const response = await ApiRequest('api/zott/setlogentryasfinished', formData);
        
        if (response.status !== 200) {
            this.setState({
                error: 'Błąd ustawiania wpisu zakończenia',
                showConfirmation: false
            });
            return;
        }

        const result = await response.json() as boolean;
        if (result) {
            this.setState({
                redirectUrl: '/zott/transportsstatus',
                showConfirmation: false
            });
        } else {
            this.setState({
                showConfirmation: false,
                error: 'Błąd ustawiania wpisu zakończenia'
            });
        }
    }

    private cancelConfirmation()
    {
        this.setState({
            showConfirmation: false
        });
    }
}