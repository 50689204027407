import * as Permissions from './Permissions';
import * as UserTypes from './UserTypes';
import * as Users from './Users';
import * as Authorization from './Authorization';
import * as Trailers from './Trailers';
import * as Drivers from './Drivers';
import * as DriverStatus from './DriverStatus';

// The top-level state object
export interface ApplicationState {
    permissions: Permissions.PermissionsState | undefined;
    userTypes: UserTypes.UserTypesState | undefined;
    users: Users.UsersState | undefined;
    trailers: Trailers.TrailersState | undefined;
    drivers: Drivers.DriversState | undefined;
    authorization: Authorization.AuthorizationState | undefined;
    driverStatus: DriverStatus.ZottPanelDriverStatusStore | undefined; 
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    permissions: Permissions.reducer,
    userTypes: UserTypes.reducer,
    users: Users.reducer,
    trailers: Trailers.reducer,
    drivers: Drivers.reducer,
    authorization: Authorization.reducer,
    driverStatus: DriverStatus.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
