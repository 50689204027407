// [PALLETS_NUMBER REMOVE] - Remove whole file
import * as React from 'react';
import ZottForm from './ZottForm';
import FormTextField from '../Common/FormTextField';

export default class UpdatePalletsNumberForm extends React.PureComponent {
    public render() {
        return(
            <ZottForm operationName="UpdatePalletsNumber" operationTitle="Podaj liczbę palet" operationButtonText="Zapisz">
                <FormTextField isMandatory={true} type="number" label="Liczba palet" name="numberOfPallets"/>
            </ZottForm>
        );
    }
}