import * as React from 'react';
import ZottForm from './ZottForm';
import { connect } from 'react-redux';
import HitchTrailerFields from './HitchTrailerFields';
import * as DriverStatus from '../../store/DriverStatus';
import { ApplicationState } from '../../store';

type HitchTrailerProps = DriverStatus.ZottPanelDriverStatusStore
    & typeof DriverStatus.actionCreators;

class HitchTrailerForm extends React.PureComponent<HitchTrailerProps, {}> {
    public render() {
        return (
            <ZottForm initFormError={this.props.fetchTrailersError} operationName="HitchTrailer" operationTitle="Zaczep naczepę" operationButtonText="Zaczep naczepę">
                <HitchTrailerFields trailersStates={this.props.trailersToHitchStates} />
            </ZottForm>
        );
    }

    public componentDidMount() {
        this.props.requestTrailersToHitch();
    }
}

export default connect(
    (state:ApplicationState) => state.driverStatus,
    DriverStatus.actionCreators
)(HitchTrailerForm as any);
