import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Authorization from '../../store/Authorization';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { PanelButtonClasses } from '../../services/styleService';

type ThisFormProps = Authorization.AuthorizationState;

class StatusesPanel extends React.PureComponent<ThisFormProps, {}> {
    public render() {
        return(
            <div className="container panel">
                <h3>Statusy</h3>
                {this.hasPermission("status:trailers")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/status/trailers">Naczepy</ButtonWithRoute>}
                {this.hasPermission("status:drivers")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/status/drivers">Kierowcy</ButtonWithRoute>}
                {this.hasPermission("status:transports")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/status/transports">Transporty</ButtonWithRoute>}
                {this.hasPermission("status:trailercleanings")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/status/trailers-cleaning">Mycie naczep</ButtonWithRoute>}
            </div>
        );
    }

    private hasPermission(permissionName: string):boolean {
        return this.props.permissions.some(function(permission) {
            return permission.startsWith(permissionName);
        });
    }
}

export default connect(
    (state:ApplicationState) => state.authorization, 
    null
)(StatusesPanel as any);