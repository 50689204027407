import * as React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { errorText, haveErrors } from '../../helpers/FormHelper';

const defaultPropsValues = {
    isMandatory: false as boolean,
    errors: {} as {[key: string]:string[]}
};

type DefaultProps = Readonly<typeof defaultPropsValues>;

type ThisFormProps = {
    label: string
} & Partial<DefaultProps> & ReactDatePickerProps;

export default class FormDatePicker extends React.PureComponent<ThisFormProps, {}> {
    public render() {
        let {label, isMandatory, errors, ...datePickerProps} = this.props;

        var wrapperClassName = 'form-control';
        if (haveErrors(this.props)) wrapperClassName += ' is-invalid';
        datePickerProps.dateFormat = 'yyyy-MM-dd';
        datePickerProps.wrapperClassName = wrapperClassName;
        datePickerProps.className = 'form-control';

        return(
            <div className="form-group">
                {label && <label htmlFor={datePickerProps.name}>{label}</label>}
                <DatePicker {...datePickerProps}/>
                <div className="invalid-feedback">{errorText(this.props)}</div>
            </div>
        );
    }
}
