import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import FormTextField from '../Common/FormTextField';
import { ApiRequest } from '../../services/apiClient';
import { Tractor } from '../../types/global';
import Loader from '../Common/Loader';
import FormCheckboxField from '../Common/FormCheckboxField';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { AdminSaveButtonClasses, AdminCancelButtonClasses } from '../../services/styleService';

type TractorFormProps = RouteComponentProps<{ tractorId: string }>;

type TractorFormState = {
    errors: {[key: string]:string[]},
    success: boolean,
    isEditMode: boolean,
    editedTractor: Tractor,
    tractorId: number,
    isLoaded: boolean,
    isLoading: boolean
}

export default class TractorForm extends React.PureComponent<TractorFormProps, TractorFormState> {
    constructor(props: TractorFormProps) {
        super(props);

        const id = parseInt(this.props.match.params.tractorId) || 0;

        this.state = {
            errors: {},
            success: false,
            isEditMode: id > 0,
            editedTractor: {
                id: 0,
                registrationNumber: '',
                description: '',
                isActive: true
            },
            tractorId: id,
            isLoaded: false,
            isLoading: false
        }
    }

    public componentDidMount() {
        if (this.state.isEditMode) {
            this.requestTractor(this.state.tractorId);
        } else {
            this.setState({
                isLoaded: true
            });
        }
    }

    public render() {
        const tractor = this.state.editedTractor;

        if (this.state.success) {
            return <Redirect to='/admin/tractors' />
        }
        if (this.state.isLoading) {
            return <Loader></Loader>
        }
        if (!this.state.isLoaded) {
            return null;
        }
        return (
        <form className="form" onSubmit={this.onSubmit.bind(this)}>
            <h3>
                {this.state.isEditMode ? `Edycja ciągnika ${tractor.registrationNumber}` : "Nowy ciągnik" }
            </h3>
            {this.state.isEditMode && <input type="hidden" name="id" value={this.state.tractorId}/>}
            <FormTextField name="registrationNumber" label="Numer rejestracyjny" defaultValue={tractor.registrationNumber} errors={this.state.errors} />
            <FormTextField name="description" label="Opis" defaultValue={tractor.description} errors={this.state.errors} />
            {this.state.isEditMode && <FormCheckboxField label="Aktywny" value="true" defaultChecked={tractor.isActive} name="isActive"/>}
            <button type="submit" className={AdminSaveButtonClasses}>Zapisz</button>
            <ButtonWithRoute className={AdminCancelButtonClasses} path='/admin/tractors'>Anuluj</ButtonWithRoute>
        </form>
        );
    }

    private async requestTractor(id: number)
    {
        this.setState({
            isLoading: true
        });

        const formData = new FormData();
        formData.set('id', id.toString());

        const response = await ApiRequest('api/tractor/getById', formData);
        if (response.status !== 200) {
            const data = await response.json();
            if (data.errors) {
                this.setState({errors: data.errors});
            }
            return;
        }

        this.setState({isLoading: false});

        const tractor = await response.json() as Tractor;
        this.setState({
            isLoaded: true,
            editedTractor: tractor 
        });
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.validateForm()) {
            await this.submitForm(formEvent.target as HTMLFormElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        data.set('registrationNumber', data.get('registrationNumber')?.toString().toUpperCase()!);

        const url = this.state.isEditMode ? 'api/tractor/update' : 'api/tractor/create';

        var result = await ApiRequest(url, data);

        if (result.status === 200) {
            this.setState({success: true});
            return true;
        } else {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
        }
    }

    private errorText(fieldName: string) : string {
        if (this.state.errors[fieldName] && this.state.errors[fieldName][0]) {
            return this.state.errors[fieldName][0];
        } else {
            return '';
        }
    }

    private haveErrors(fieldName: string) : boolean {
        return this.state.errors[fieldName] != null;
    }
}
