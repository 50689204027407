const AuthTokenKey: string = 'authToken';
const LogoutTokenKey: string = 'logoutToken';
const PermissionsKey: string = 'permissions';

export function Login(authToken: string, logoutToken: string, permissionSystemNames:string[]):void {
    localStorage.setItem(AuthTokenKey, authToken);
    localStorage.setItem(LogoutTokenKey, logoutToken);
    localStorage.setItem(PermissionsKey, permissionSystemNames.join(','));
}

export function AuthHeader(): Headers {
    var headers = new Headers();
    var authToken = localStorage.getItem(AuthTokenKey);
    if (authToken !== null) {
        headers.append('Authorization', `Bearer ${authToken}`);
    }
    return headers;
}

export function Logout(): void {
    localStorage.removeItem(AuthTokenKey);
    localStorage.removeItem(LogoutTokenKey);
    localStorage.removeItem(PermissionsKey);
}

export function IsLoggedIn(): boolean {
    return !!localStorage.getItem(AuthTokenKey);
}

export function HasPermission(permissionName:string): boolean {
    var permissionsArray = CurrentUserPermissions();
    return permissionsArray.indexOf(permissionName) >= 0;
}

export function CurrentUserPermissions(): string[] {
    var permissionsArrayText = localStorage.getItem(PermissionsKey);
    
    if (!permissionsArrayText) {
        return [];
    }

    return permissionsArrayText.split(',');
}
