import * as React from 'react';
import ZottForm from './ZottForm';
import { RouteComponentProps } from 'react-router';

type ThisFormProps = RouteComponentProps<{ trailerId: string }>;

type ThisFormState = {
    hasTrailerId: boolean;
    trailerId: string;
};

export default class TrailerFailureForm extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        const trailerId = parseInt(this.props.match.params.trailerId) || 0;
        this.state = {
            hasTrailerId: trailerId > 0,
            trailerId: this.props.match.params.trailerId
        };
    }

    public render() {
        return(
            <ZottForm operationName="TrailerFailure" operationTitle="Awaria naczepy" operationButtonText="Awaria">
                {this.state.hasTrailerId && <input type="hidden" name="trailerId" value={this.state.trailerId}/>}
            </ZottForm>
        );
    }
}