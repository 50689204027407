import * as React from 'react';
import { ZottTrailerStatus, StringDictionary, ZottLocationEnum, ZottCargoTypeEnum } from '../../types/global';
import FormSelect from '../Common/FormSelect';
import FormCheckboxField from '../Common/FormCheckboxField';
import FormTextField from '../Common/FormTextField';

const defaultPropsValues = {
    errors: {} as {[key: string]:string[]}
}

type DefaultProps = Readonly<typeof defaultPropsValues>;

type ThisFormProps = {
    trailersStates: ZottTrailerStatus[] | undefined
} & Partial<DefaultProps>;

type ThisFormState = {
    selectedTrailerDescription: string,
    isSelectedTrailerLoaded: boolean,
    isSelectedTrailerOnRamp: boolean,
    hasSelectedCargoTypeExtra: boolean,
    setTrailerAsUnloadedKey: string,
    isTemporaryChecked: boolean
}

export default class HitchTrailerFields extends React.PureComponent<ThisFormProps, ThisFormState> {

    constructor(props: ThisFormProps)
    {
        super(props);

        this.state = {
            isTemporaryChecked: false,
            selectedTrailerDescription: '',
            isSelectedTrailerLoaded: false,
            isSelectedTrailerOnRamp: false,
            hasSelectedCargoTypeExtra: false,
            setTrailerAsUnloadedKey: 'setTrailerAsUnloadedKey'
        }
    }

    public render() {
        return(
            <div>
                <FormSelect description={this.state.selectedTrailerDescription} onChange={this.onTrailerChange.bind(this)} defaultValue = '0' values={this.trailers()} keysOrder={this.trailerKeys()} label="Numer naczepy" name="trailerId" disabled={this.state.isTemporaryChecked}/>
                {this.state.isSelectedTrailerLoaded
                    && !this.state.hasSelectedCargoTypeExtra
                    && <FormCheckboxField key={this.state.setTrailerAsUnloadedKey} defaultChecked={this.state.isSelectedTrailerOnRamp} value="1" label="Oznacz jako rozładowaną" name="setTrailerAsUnloaded" disabled={this.state.isTemporaryChecked}/>}
                <hr/>
                <FormCheckboxField onChange={this.onTemporaryTrailerCheckboxChange.bind(this)} value="1" label="Nowa naczepa tymczasowa" name="isTemporaryTrailer"/>
                <FormTextField isMandatory={this.state.isTemporaryChecked} type="number" placeholder="numer naczepy tymczasowej" name="temporaryTrailerNumber" disabled={!this.state.isTemporaryChecked} errors={this.props.errors}/>
                {this.state.hasSelectedCargoTypeExtra && <FormTextField
                        type="number"
                        name="kilometer"
                        label="Przebieg"
                        isMandatory={true}
                        defaultValue={0}
                        errors={this.props.errors}/>}
            </div>
        );
    }
    
    private onTrailerChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;
        const trailerId = parseInt(value);

        this.setTrailerDescriptionAndFields(trailerId);
    }

    private onTemporaryTrailerCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            isTemporaryChecked: event.target.checked
        })
    }

    private setTrailerDescriptionAndFields(trailerId: number)
    {
        const trailerState = this.props.trailersStates!.find(ts => ts.trailerId === trailerId);
        const isLoaded = trailerState != null
            && trailerState.isLoaded;
        const isOnRamp = trailerState != null
            && !!trailerState.rampNumber
            && trailerState.zottLocationEnum === ZottLocationEnum.Fresh;

        const hasCargoTypeExtra = trailerState != null
            && trailerState.cargoTypeId === ZottCargoTypeEnum.Extra;

        this.setState({
            selectedTrailerDescription: trailerState != null
                ? this.buildTrailerDescription(trailerState)
                : '',
            isSelectedTrailerLoaded: isLoaded,
            isSelectedTrailerOnRamp: isOnRamp,
            hasSelectedCargoTypeExtra: hasCargoTypeExtra,
            setTrailerAsUnloadedKey: 'setTrailerAsUnloadedKey' + Date.now()
        })
    }

    private buildTrailerDescription(trailerState: ZottTrailerStatus) : string {
        const parts : string[] = [trailerState.status];

        if (trailerState.isLoaded)
        {
            parts.push('Ładunek:' + trailerState.cargoType);
        }
        if (trailerState.wz)
        {
            parts.push('WZ:' + trailerState.wz);
        }
        if (trailerState.loadWaitDurationMinutes)
        {
            const hours = Math.floor(trailerState.loadWaitDurationMinutes / 60);
            const minutes = trailerState.loadWaitDurationMinutes % 60;

            const durationString = (hours === 0)
                ? minutes.toString() + 'm'
                : hours.toString() + 'h ' + minutes.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                }) + 'm';
            
            parts.push('Okres załadunku:' + durationString);
        }
        if (trailerState.locationTitle)
        {
            parts.push('Lokacja:' + trailerState.locationTitle);
        }
        if (trailerState.rampNumber)
        {
            parts.push('Nr rampy:' + trailerState.rampNumber);
        }
        if (trailerState.driverName)
        {
            parts.push('Kierowca:' + trailerState.driverName);
        }
        return parts.join(', ');
    }

    private trailers() : StringDictionary
    {
        const trailers = {} as StringDictionary;

        if (this.props.trailersStates) {
            this.props.trailersStates!.forEach(ts => trailers[ts.trailerId] = ts.number);
        }

        return trailers;
    }

    private trailerKeys() : string[] {

        const keys = [] as string[];

        if (this.props.trailersStates) {
            this.props.trailersStates!.forEach(ts => keys.push(ts.trailerId.toString()));
        }

        return keys;
    }
}