import * as React from 'react';
import { Switch, Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import UserTypes from './components/Admin/UserTypes';
import UserTypeForm from './components/Admin/UserTypeForm';
import Users from './components/Admin/Users';
import UserForm from './components/Admin/UserForm';
import Login from './components/Login';
import Trailers from './components/Admin/Trailers';
import TrailerForm from './components/Admin/TrailerForm';
import Tractors from './components/Admin/Tractors';
import TractorForm from './components/Admin/TractorForm';
import Drivers from './components/Admin/Drivers';
import AdminPanel from './components/Admin/AdminPanel';
import AddDriversForm from './components/Admin/AddDriversForm';
import UserTypeDriversForm from './components/Admin/UserTypeDriversForm'

import ZottPanel from './components/Zott/ZottPanel';
import StartWorkForm from './components/Zott/StartWorkForm';
import EndWorkForm from './components/Zott/EndWorkForm';
import HitchTrailerForm from './components/Zott/HitchTrailerForm';
import UnHitchTrailerForm from './components/Zott/UnHitchTrailerForm';
import GoToZottForm from './components/Zott/GoToZottForm';
import LocationZottForm from './components/Zott/LocationZottForm';
import StartLoadingForm from './components/Zott/StartLoadingForm';
import EndLoadingForm from './components/Zott/EndLoadingForm';
import GoToParkingForm from './components/Zott/GoToParkingForm';
import ParkingForm from './components/Zott/ParkingForm';
import GoToFreshForm from './components/Zott/GoToFreshForm';
import FreshForm from './components/Zott/FreshForm';
// [PALLETS_NUMBER REMOVE] - remove component import
import UpdatePalletsNumberForm from './components/Zott/UpdatePalletsNumberForm';
import UpdatePalletsNumberByZottForm from './components/Zott/UpdatePalletsNumberByZottForm';
import UpdateInternationalPalletsNumberForm from './components/Zott/UpdateInternationalPalletsNumber';
import UnloadAtRampForm from './components/Zott/UnloadAtRampForm';
import StartUnloadingForm from './components/Zott/StartUnloadingForm';
import EndUnloadingForm from './components/Zott/EndUnloadingForm';
import ReHitchTrailerForm from './components/Zott/ReHitchTrailerForm';
import StartExtraRouteForm from './components/Zott/StartExtraRouteForm';
import ZottTrailersStatus from './components/Statuses/ZottTrailersStatus';
import ZottDriversStatus from './components/Statuses/ZottDriversStatus';
import TrailerCleaningStatus from './components/Statuses/TrailerCleaningStatus';
import TrailerCleaningLog from './components/Logs/TrailerCleaningLog';
import TrailerOperationLog from './components/Logs/TrailerOperationLog';
import DriverOperationLog from './components/Logs/DriverOperationLog';
import TrailerFailureForm from './components/Zott/TrailerFailureForm';
import TrailerFixedForm from './components/Zott/TrailerFixedForm';
import TrailerServiceForm from './components/Zott/TrailerServiceForm';
import TrailerEndServiceForm from './components/Zott/TrailerEndServiceForm';
import ShuttleForm from './components/Zott/ShuttleForm';
import SetTrailerStatusForm from './components/Zott/SetTrailerStatusForm';
import TransportsStatus from './components/Statuses/TransportsStatus';
import TransportOperationLog from './components/Logs/TransportOperationLog';
import AdminSettings from './components/Admin/AdminSettingsPanel';
import GoToLocationPointForm from './components/Zott/GoToLocationForm';

import StatusesPanel from './components/Statuses/StatusesPanel';

import ReportsPanel from './components/Reports/ReportsPanel';
import TransportsMonthlyReport from './components/Reports/TransportsMonthlyReport';
import ShippingsReport from './components/Reports/ShippingsReport';
import TransportsExtraMonthlyReport from './components/Reports/TransportsExtraMonthlyReport';
import FuelingsReport from './components/Reports/FuelingsReport';
import TrailerCleaningsReport from './components/Reports/TrailerCleaningsReport';
import PalletsNumberUpdatesReport from './components/Reports/PalletsNumberUpdatesReport';
import InternationalPalletsNumberUpdatesReport from './components/Reports/InternationalPalletsNumberUpdatesReport';
import TransportingDriversReport from './components/Reports/TransportingDriversReport';

import FuelingPanel from './components/Fueling/FuelingPanel';
import SaveFuelingForm from './components/Fueling/SaveFuelingForm';

import TrailerCleaningForm from './components/Zott/TrailerCleaningForm';

import './custom.css'

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/admin/settings' component={AdminSettings} />
            <Route path='/admin/user-types/editdrivers/:userTypeId' component={UserTypeDriversForm} />
            <Route path='/admin/user-types/edit/:userTypeId' component={UserTypeForm} />
            <Route path='/admin/user-types/new' component={UserTypeForm} />
            <Route path='/admin/user-types' component={UserTypes} />
            <Route path='/admin/users/edit/:userId' component={UserForm} />
            <Route path='/admin/users/new' component={UserForm} />
            <Route path='/admin/users' component={Users} />
            <Route path='/admin/tractors/edit/:tractorId' component={TractorForm} />
            <Route path='/admin/tractors/new' component={TractorForm} />
            <Route path='/admin/tractors' component={Tractors} />
            <Route path='/admin/trailers/edit/:trailerId' component={TrailerForm} />
            <Route path='/admin/trailers/new' component={TrailerForm} />
            <Route path='/admin/trailers' component={Trailers} />
            <Route path='/admin/drivers/add' component={AddDriversForm} />
            <Route path='/admin/drivers' component={Drivers} />
            <Route path='/admin' component={AdminPanel} />
            <Route path='/zott/startwork' component={StartWorkForm} />
            <Route path='/zott/endwork' component={EndWorkForm} />
            <Route path='/zott/hitchtrailer' component={HitchTrailerForm} />
            <Route path='/zott/unhitchtrailer' component={UnHitchTrailerForm} />
            <Route path='/zott/gotozott' component={GoToZottForm} />
            <Route path='/zott/zott' component={LocationZottForm} />
            <Route path='/zott/startloading' component={StartLoadingForm} />
            <Route path='/zott/endloading' component={EndLoadingForm} />
            <Route path='/zott/gotoparking' component={GoToParkingForm} />
            <Route path='/zott/parking' component={ParkingForm} />
            <Route path='/zott/gotofresh' component={GoToFreshForm} />
            <Route path='/zott/fresh' component={FreshForm} />
            <Route path='/zott/updatepalletsnumber' component={UpdatePalletsNumberForm} />
            <Route path='/zott/updatepalletsnumberbyzott' component={UpdatePalletsNumberByZottForm} />
            <Route path='/zott/update-international-pallets-number' component={UpdateInternationalPalletsNumberForm} />
            <Route path='/zott/unloadatramp' component={UnloadAtRampForm} />
            <Route path='/zott/startunloading' component={StartUnloadingForm} />
            <Route path='/zott/endunloading' component={EndUnloadingForm} />
            <Route path='/zott/startextraroute' component={StartExtraRouteForm} />
            <Route path='/zott/rehitchtrailer' component={ReHitchTrailerForm} />
            <Route path='/zott/shuttle' component={ShuttleForm} />
            <Route path='/zott/trailer-cleaning-log/:trailerId' component={TrailerCleaningLog} />
            <Route path='/zott/trailerlog/:trailerId' component={TrailerOperationLog} />
            <Route path='/zott/driverlog/:driverId' component={DriverOperationLog} />
            <Route path='/zott/trailerfailure/:trailerId' component={TrailerFailureForm} />
            <Route path='/zott/trailerfixed/:trailerId' component={TrailerFixedForm} />
            <Route path='/zott/trailerservice/:trailerId' component={TrailerServiceForm} />
            <Route path='/zott/trailerendservice/:trailerId' component={TrailerEndServiceForm} />
            <Route path='/zott/managetrailerstatus/:trailerId' component={SetTrailerStatusForm} />
            <Route path='/zott/gotolocation' component={GoToLocationPointForm} />
            <Route path='/zott/transportlog/:transportId' component={TransportOperationLog} />
            <Route path='/zott/trailer-cleaning' component={TrailerCleaningForm} />
            <Route path='/zott' component={ZottPanel} />
            <Route path='/status/trailers' component={ZottTrailersStatus} />
            <Route path='/status/drivers' component={ZottDriversStatus} />
            <Route path='/status/transports' component={TransportsStatus} />
            <Route path='/status/trailers-cleaning' component={TrailerCleaningStatus} />
            <Route path='/status' component={StatusesPanel} />
            <Route path='/reports/transportsmonthly' component={TransportsMonthlyReport} />
            <Route path='/reports/transports-extra-monthly' component={TransportsExtraMonthlyReport} />
            <Route path='/reports/shippings' component={ShippingsReport} />
            <Route path='/reports/fuelings' component={FuelingsReport} />
            <Route path='/reports/trailer-cleanings' component={TrailerCleaningsReport} />
            <Route path="/reports/pallets-updates" component={PalletsNumberUpdatesReport} />
            <Route path="/reports/international-pallets-updates" component={InternationalPalletsNumberUpdatesReport} />
            <Route path="/reports/transporting-drivers" component={TransportingDriversReport} />
            <Route path='/reports' component={ReportsPanel} />
            <Route path='/login' component={Login} />
            <Route path='/fuelling/save-fueling' component={SaveFuelingForm} />
            <Route path='/fueling' component={FuelingPanel} />
        </Switch>
    </Layout>
);
