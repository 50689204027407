import * as React from 'react';
import { connect } from 'react-redux';
import * as TrailersStore from '../../store/Trailers';
import { ApplicationState } from '../../store';
import { Redirect, RouteComponentProps } from 'react-router';
import FormTextField from '../Common/FormTextField';
import { ApiRequest } from '../../services/apiClient';
import FormSelect from '../Common/FormSelect';
import { StringDictionary, Trailer } from '../../types/global';
import { Driver } from '../../store/Drivers';
import Loader from '../Common/Loader';
import FormCheckboxField from '../Common/FormCheckboxField';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { AdminSaveButtonClasses, AdminCancelButtonClasses } from '../../services/styleService';

type TrailerFormProps = RouteComponentProps<{ trailerId: string }> & typeof TrailersStore.actionCreators & TrailersStore.TrailersState;

type TrailerFormState = {
    errors: {[key: string]:string[]},
    success: boolean,
    isEditMode: boolean,
    editedTrailer: Trailer,
    drivers: StringDictionary
}

class TrailerForm extends React.PureComponent<TrailerFormProps, TrailerFormState> {
    constructor(props: TrailerFormProps) {
        super(props);

        const trailerIdNumber = parseInt(this.props.match.params.trailerId) || 0;
        let editedTrailer : Trailer | undefined;
        if (trailerIdNumber > 0) {
            editedTrailer = props.trailers.find((trailer) => trailer.id === trailerIdNumber);
            if (!editedTrailer) {
                console.warn(`Trailer with id ${trailerIdNumber} not loaded`);
            }
        }

        // Creates null object
        if (!editedTrailer) {
            editedTrailer = {
                id: 0,
                number: '',
                description: '',
                maintainerId: 0,
                maintainerName: '',
                isActive: true,
                isTemporary: false
            }
        }

        this.state = {
            errors: {},
            success: false,
            isEditMode: trailerIdNumber > 0,
            editedTrailer: editedTrailer!,
            drivers: {}
        }
    }

    public componentDidMount() {
        ApiRequest('api/driver/getall')
        .then(response => response.json() as Promise<Driver[]>)
        .then(result => {
            let drivers: StringDictionary = {};

            result.forEach(driver => drivers[driver.id] = driver.name);

            this.setState({
                drivers: drivers
            });
        });
    }

    public render() {
        if (this.state.success) {
            return <Redirect to='/admin/trailers' />
        }
        if (Object.keys(this.state.drivers).length === 0) {
            return <Loader></Loader>
        }

        var trailer = this.state.editedTrailer;

        return (
        <form className="form" onSubmit={this.onSubmit.bind(this)}>
            <h3>
                {this.state.isEditMode ? `Edycja naczepy ${trailer.number}` : "Nowa naczepa" }
            </h3>
            {this.state.isEditMode ? <input type="hidden" name="id" value={trailer.id}/> : ''}
            <FormTextField name="number" label="Numer" defaultValue={trailer.number} errors={this.state.errors} />
            <FormTextField name="description" label="Opis" defaultValue={trailer.description} errors={this.state.errors} />
            <FormSelect label='Opiekun' allowPromptSelection={true} prompt="brak" values={this.state.drivers} defaultValue={trailer.maintainerId.toString()} name='maintainerId' />
            {this.state.isEditMode && <FormCheckboxField label="Tymczasowa" value="true" defaultChecked={trailer.isTemporary} name="isTemporary"/>}
            {this.state.isEditMode && <FormCheckboxField label="Aktywna" value="true" defaultChecked={trailer.isActive} name="isActive"/>}
            <button type="submit" className={AdminSaveButtonClasses}>Zapisz</button>
            <ButtonWithRoute className={AdminCancelButtonClasses} path='/admin/trailers'>Anuluj</ButtonWithRoute>
        </form>
        );
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.validateForm()) {
            await this.submitForm(formEvent.target as HTMLFormElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        const url = this.state.isEditMode ? 'api/trailer/update' : 'api/trailer/create';

        var result = await ApiRequest(url, data);

        if (result.status === 200) {
            this.props.requestTrailers(true);
            this.setState({success: true});
            return true;
        } else {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
        }
    }

    private errorText(fieldName: string) : string {
        if (this.state.errors[fieldName] && this.state.errors[fieldName][0]) {
            return this.state.errors[fieldName][0];
        } else {
            return '';
        }
    }

    private haveErrors(fieldName: string) : boolean {
        return this.state.errors[fieldName] != null;
    }
}

export default connect(
    (state: ApplicationState) => state.trailers,
    TrailersStore.actionCreators
)(TrailerForm as any);