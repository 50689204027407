import * as React from 'react';
import { connect } from 'react-redux';
import * as DriversStore from '../../store/Drivers';
import { Redirect } from 'react-router';
import { ApiRequest } from '../../services/apiClient';
import ButtonWithRoute from '../Common/ButtonWithRoute';

type AddDriversFormProps = typeof DriversStore.actionCreators & DriversStore.DriversState;

interface PotentialDriver{
    userId: number,
    name: string
}

type AddDriversFormState = {
    errors: {[key: string]:string[]},
    potentialDrivers: PotentialDriver[],
    success: boolean
}

class AddDriversForm extends React.PureComponent<AddDriversFormProps, AddDriversFormState> {
    constructor(props: AddDriversFormProps) {
        super(props);

        this.state = {
            errors: {},
            potentialDrivers: [],
            success: false
        }
    }

    public componentDidMount() {
        this.fetchPotentialDrivers();
    }

    public render() {
        if (this.state.success) {
            return <Redirect to='/admin/drivers' />
        }
        return (
        <form className="form" onSubmit={this.onSubmit.bind(this)}>
            <h3>Dodaj kierowców</h3>
            <div>
                {this.state.potentialDrivers.map((potentialDriver: PotentialDriver) => 
                    <div key={potentialDriver.userId} className="form-check form-group">
                        <input className="form-check-input" type="checkbox" id={"potentialDriver_"+potentialDriver.userId} name="userIds" value={potentialDriver.userId}/>
                        <label className="form-check-label" htmlFor={"potentialDriver_"+potentialDriver.userId}>{potentialDriver.name}</label>
                        <div className="invalid-feedback">{this.errorText("potentialDriver_"+potentialDriver.userId)}</div>
                    </div>
                )}
            </div>
            <div className="row-form">
                <button type="submit" className="btn btn-primary">Dodaj</button>
                <ButtonWithRoute className="btn btn-outline-secondary ml-2" path='/admin/drivers'>Anuluj</ButtonWithRoute>
            </div>
        </form>
        );
    }

    private fetchPotentialDrivers()
    {
        ApiRequest('/api/driver/getAllPotential')
            .then(response => response.json() as Promise<PotentialDriver[]>)
            .then(data => this.setState({
                potentialDrivers: data
            }));
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.validateForm()) {
            await this.submitForm(formEvent.target as HTMLFormElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        var result = await ApiRequest('api/driver/add', data);

        if (result.status === 200) {
            this.props.requestDrivers(true);
            this.setState({success: true});
            return true;
        } else {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
        }
    }

    private errorText(fieldName: string) : string {
        if (this.state.errors[fieldName] && this.state.errors[fieldName][0]) {
            return this.state.errors[fieldName][0];
        } else {
            return '';
        }
    }

    private haveErrors(fieldName: string) : boolean {
        return this.state.errors[fieldName] != null;
    }
}

export default connect(
    null,
    DriversStore.actionCreators
)(AddDriversForm as any);