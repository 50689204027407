import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiRequest } from '../services/apiClient';

export interface User {
    id: number;
    loginName: string;
    firstName: string;
    lastName: string;
    userTypeName: string;
    userTypeId: number;
    phone: string;
    isActive: boolean;
}

export interface UsersState {
    isLoading: boolean;
    users: User[];
};

// Actions
interface RequestUsersAction {
    type: 'REQUEST_USERS';
}

interface ReceiveUsersAction {
    type: 'RECEIVE_USERS';
    users: User[];
}

const unloadedState: UsersState = { isLoading: false, users: [] };

type KnownAction = RequestUsersAction | ReceiveUsersAction;

export const actionCreators = {
    requestUsers: (forceReload: boolean) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.users && ( appState.users.users.length === 0 || forceReload)) {
            ApiRequest('api/user/getall')
                .then(response => {
                    if (response.status !== 200)
                    {
                        throw new Error(`Received status ${response.status}`);
                    }
                    return response.json() as Promise<User[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_USERS', users: data});
                })
                .catch(() => {
                    console.log("catched");
                });
            dispatch({ type: 'REQUEST_USERS' });
        }
    },

    deleteUser: (id: number) : AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const formData = new FormData();

        formData.set('id', id.toString());

        const response = await ApiRequest('api/user/delete',formData);
        const resultData = await response.json();
        if (resultData === true) {
            // TODO - Necessary to show confirmation or error
        }
    },
};

export const reducer: Reducer<UsersState> = (state: UsersState | undefined, incomingAction: Action) : UsersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type) {
        case 'REQUEST_USERS':
            return {
                users: state.users,
                isLoading: true
            };
        case 'RECEIVE_USERS':
            return {
                users: action.users,
                isLoading: false
            };
    }
    return state;
};