import * as React from 'react';
import ZottForm from './ZottForm';

type EndWorkFormProps = {};

type EndWorkFormState = {};

export default class EndWorkForm extends React.PureComponent<EndWorkFormProps, EndWorkFormState> {
    public render() {
        return(
            <ZottForm operationName="EndWork" operationTitle="Koniec pracy" operationButtonText="Zakończ pracę" />
        );
    }
}