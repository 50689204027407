import * as React from 'react';
import { StringDictionary } from '../../types/global';

export function OperationDataElement(dict: StringDictionary)
{
    return (<div>{
        Object.keys(dict).map((key : string) =>
            <p key={key}>{key}: {dict[key]}</p>)
        }</div>);
}
