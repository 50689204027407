import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiRequest } from '../services/apiClient';

export interface UserType {
    id: number;
    name: string;
    permissionIds: number[];
    infiniteLogin: boolean;
}

export interface UserTypesState {
    isLoading: boolean;
    userTypes: UserType[];
};

// Actions
interface RequestUserTypesAction {
    type: 'REQUEST_USER_TYPES';
}

interface ReceiveUserTypesAction {
    type: 'RECEIVE_USER_TYPES';
    userTypes: UserType[];
}

interface EditUserTypeAction {
    type: 'EDIT_USER_TYPE',
    userType: UserType
}

interface DeleteUserTypeAction {
    type: 'DELETE_USER_TYPE',
    userTypeId: number
}

const unloadedState: UserTypesState = { userTypes: [], isLoading: false };

type KnownAction = RequestUserTypesAction
    | ReceiveUserTypesAction
    | EditUserTypeAction
    | DeleteUserTypeAction;

export const actionCreators = {
    requestUserTypes: (forceReload: boolean) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.userTypes && ( appState.userTypes.userTypes.length === 0 || forceReload)) {
            ApiRequest('api/userType/getall')
                .then(response => response.json() as Promise<UserType[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_USER_TYPES', userTypes: data});
                });
            dispatch({ type: 'REQUEST_USER_TYPES' });
        }
    },

    // TODO - fill below
    updateUserType: (userType: UserType) : AppThunkAction<KnownAction> => (dispatch, getState) => {
    },

    deleteUserType: (id: number) : AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const formData = new FormData();

        formData.set('id', id.toString());

        const response = await ApiRequest('api/userType/delete', formData);
        const resultData = await response.json();
        if (resultData === true) {
            // TODO - Necessary to show confirmation or error
        }
    },
};

export const reducer: Reducer<UserTypesState> = (state: UserTypesState | undefined, incomingAction: Action) : UserTypesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type) {
        case 'REQUEST_USER_TYPES':
            return {
                userTypes: state.userTypes,
                isLoading: true
            };
        case 'RECEIVE_USER_TYPES':
            return {
                userTypes: action.userTypes,
                isLoading: false
            };
        // TODO - not used
        case 'DELETE_USER_TYPE':
            return {
                userTypes: [],      // Remove whole collection so it will be loaded when necessary
                isLoading: false
            }
    }
    return state;
};