import * as React from 'react';
import { Redirect } from 'react-router';
import FormTextField from '../Common/FormTextField';
import { ApiRequest } from '../../services/apiClient';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { AdminSaveButtonClasses, AdminCancelButtonClasses } from '../../services/styleService';
import Repeatable from '../Common/Repeatable';
import Loader from '../Common/Loader';

type AdminSettings = 
{
    notificationEmails: string[],
    beforeUnloadingDelayInMinutes: number,
    phoneToFresh: string,
    gotoFreshNotificationEmails: string[],
    palletsNotificationEmails: string[],
    palletsNumberUpdateLimit: number
}

type ThisFormProps = {};

type ThisFormState = {
    errors: {[key: string]:string[]},
    success: boolean,
    settings: AdminSettings,
    loaded: boolean
}

export default class AdminSettingsPanel extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            errors: {},
            success: false,
            settings: {
                notificationEmails: [],
                beforeUnloadingDelayInMinutes: 0,
                phoneToFresh: '',
                gotoFreshNotificationEmails: [],
                palletsNotificationEmails: [],
                palletsNumberUpdateLimit: 0
            },
            loaded: false
        }
    }

    public render() {
        if (!this.state.loaded) {
            return <Loader/>;
        }
        if (this.state.success) {
            return <Redirect to='/admin' />
        }

        var settings = this.state.settings;
        var dateNow = Date.now();

        return (
            <form className="form" onSubmit={this.onSubmit.bind(this)}>
                <h3>Ustawienia administracyjne</h3>
                <hr/>
                <h4>Notyfikacje email rozładunków</h4>
                <FormTextField key={"ptf"+dateNow} type="text" name="phoneToFresh" label="Telefon do Fresh" defaultValue={settings.phoneToFresh} errors={this.state.errors} />
                <FormTextField key={"budih"+dateNow} type="number" name="beforeUnloadingDelayInMinutes" label="Maksymalne opóźnienie rozładunku [minuty]" defaultValue={settings.beforeUnloadingDelayInMinutes} errors={this.state.errors} />
                <Repeatable key={"ne"+dateNow} id="notificationEmails" label="Wyślij email do" defaultValues={settings.notificationEmails} renderItem={this.renderEmailField} getNewItemValue={()=>''}/>
                <hr/>
                <h4>Notyfikacje o wyjeździe do Fresh</h4>
                <Repeatable key={"gfne"+dateNow} id="gotoFreshNotificationEmails" label="Wyślij email do" defaultValues={settings.gotoFreshNotificationEmails} renderItem={this.renderGotoEmailField} getNewItemValue={()=>''}/>
                <hr/>
                <h4>Notyfikacje o liczbie palet</h4>
                <FormTextField key={"pnul"+dateNow} type="number" name="palletsNumberUpdateLimit" label="Wyślij email gdy liczba palet większa niż" defaultValue={settings.palletsNumberUpdateLimit} errors={this.state.errors} />
                <Repeatable key={"pne"+dateNow} id="palletsNotificationEmails" label="Wyślij email do" defaultValues={settings.palletsNotificationEmails} renderItem={this.renderPalletsEmailField} getNewItemValue={()=>''}/>
                <hr/>
                <button type="submit" className={AdminSaveButtonClasses}>Zapisz</button>
                <ButtonWithRoute className={AdminCancelButtonClasses} path='/admin'>Anuluj</ButtonWithRoute>
            </form>
        );
    }

    public componentDidMount() {
        this.fetchSettings();
    }

    private async fetchSettings()
    {
        var result = await ApiRequest('api/settings/get');

        this.setState({
            loaded: true
        });

        if (result.status !== 200) {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({
                    errors: resultData.errors
                });
            }
            return;
        }

        const settings = await result.json() as AdminSettings;

        this.setState({
            settings: settings
        });
    }

    private renderEmailField(value: string, index: number, values: string[], commonData: any) {
        return (
            <FormTextField type="email" inputMode="email" placeholder="Wprowadź email" name="notificationEmails" defaultValue={value} />
        );
    }

    private renderGotoEmailField(value: string, index: number, values: string[], commonData: any) {
        return (
            <FormTextField type="email" inputMode="email" placeholder="Wprowadź email" name="gotoFreshNotificationEmails" defaultValue={value} />
        );
    }

    private renderPalletsEmailField(value: string, index: number, values: string[], commonData: any) {
        return (
            <FormTextField type="email" inputMode="email" placeholder="Wprowadź email" name="palletsNotificationEmails" defaultValue={value} />
        );
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.validateForm()) {
            await this.submitForm(formEvent.target as HTMLFormElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        var result = await ApiRequest('api/settings/save', data);

        if (result.status === 200) {
            this.setState({success: true});
            return true;
        } else {
            this.setState({
                settings: this.FormDataToAdminSettings(data)
            });
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
        }
    }

    private FormDataToAdminSettings(formData : FormData) : AdminSettings
    {
        const allEmails = formData.getAll('notificationEmails');
        const gotoFreshNotificationEmails = formData.getAll('gotoFreshNotificationEmails');
        const beforeUnloadingDelayInMinutes = parseInt(formData.get('beforeUnloadingDelayInMinutes') as string);
        const palletsNotificationEmails = formData.getAll('palletsNotificationEmails');
        const palletsNumberUpdateLimit = parseInt(formData.get('palletsNumberUpdateLimit') as string);

        const result : AdminSettings = {
            beforeUnloadingDelayInMinutes,
            notificationEmails: allEmails.map(v => v.toString()),
            phoneToFresh: formData.get('phoneToFresh') as string,
            gotoFreshNotificationEmails: gotoFreshNotificationEmails.map(v => v.toString()),
            palletsNotificationEmails: palletsNotificationEmails.map(v => v.toString()),
            palletsNumberUpdateLimit
        };

        return result;
    }
}
