import * as React from 'react';
import { TransportReportEntry } from '../../types/global';
import ButtonWithRoute from '../Common/ButtonWithRoute';

type ThisFormProps = {
    transports: TransportReportEntry[] | undefined,
};

export default class TransportsTable extends React.PureComponent<ThisFormProps, {}> {
    public render() {
        if (!this.props.transports || this.props.transports.length === 0) {
            return (
                <p>Brak transportów</p>
            );
        }

        return (
            <div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Typ towaru</th>
                            <th>Wz</th>
                            <th>Kierowca</th>
                            <th>Ciągnik</th>
                            <th>Naczepa</th>
                            <th>Godzina dojazdu</th>
                            <th>Godzina podstawienia</th>
                            <th>Godzina zakończenia rozładunku</th>
                            <th>Czas rozładunku</th>
                            <th>Łączny czas rozładunku</th>
                            <th>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.transports!.map((ts: TransportReportEntry) => 
                            <tr key={ts.id}>
                                <td>{ts.finishedOnDateTimeText}</td>
                                <td>{ts.cargoType}</td>
                                <td>{ts.wz}</td>
                                <td>{ts.driverName}</td>
                                <td>{ts.tractorRegNumber}</td>
                                <td>{ts.trailerNumber}</td>
                                <td>{ts.freshArrivalTimeText}</td>
                                <td>{ts.setAtRampTimeText}</td>
                                <td>{ts.endUnloadingTimeText}</td>
                                <td>{ts.unloadingDuration}</td>
                                <td>{ts.totalUnloadingDuration}</td>
                                <td className="actions">
                                    <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/zott/transportlog/' + ts.id}>Historia</ButtonWithRoute>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}