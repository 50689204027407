import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiRequest } from '../services/apiClient';
import { Trailer } from '../types/global';

export interface TrailersState {
    isLoading: boolean;
    trailers: Trailer[];
};

// Actions
interface RequestTrailersAction {
    type: 'REQUEST_TRAILERS';
}

interface ReceiveTrailersAction {
    type: 'RECEIVE_TRAILERS';
    trailers: Trailer[];
}

const unloadedState: TrailersState = { isLoading: false, trailers: [] };

type KnownAction = RequestTrailersAction | ReceiveTrailersAction;

export const actionCreators = {
    requestTrailers: (forceReload: boolean) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.trailers && ( appState.trailers.trailers.length === 0 || forceReload)) {
            ApiRequest('api/trailer/getall')
                .then(response => {
                    if (response.status !== 200)
                    {
                        throw new Error(`Received status ${response.status}`);
                    }
                    return response.json() as Promise<Trailer[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_TRAILERS', trailers: data});
                })
                .catch(() => {
                    console.log("catched");
                });
            dispatch({ type: 'REQUEST_TRAILERS' });
        }
    },

    deleteTrailer: (id: number) : AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const formData = new FormData();

        formData.set('id', id.toString());

        const response = await ApiRequest('api/trailer/delete',formData);
        const resultData = await response.json();
        if (resultData === true) {
            // TODO - Necessary to show confirmation or error
        }
    },
};

export const reducer: Reducer<TrailersState> = (state: TrailersState | undefined, incomingAction: Action) : TrailersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type) {
        case 'REQUEST_TRAILERS':
            return {
                trailers: state.trailers,
                isLoading: true
            };
        case 'RECEIVE_TRAILERS':

            return {
                trailers: action.trailers,
                isLoading: false
            };
    }
    return state!;
};