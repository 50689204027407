export interface StringDictionary {
    [key: string] : string
}

export interface Tractor {
    id: number,
    registrationNumber: string,
    description: string,
    isActive: boolean
};

export interface LocationPoint {
    name: string,
    company: string,
    id: number
};

export enum KnownLocationPointIds {
    ParkingId = 1,
    ZottId = 2,
    FreshId = 3
};

export enum LocationEnum
{
    DrivingTo = 8,
    At = 9,
}

export interface Location {
    locationPoint: LocationPoint,
    locationEnum: LocationEnum
}

export interface LocationToGo {
    locationEnum: LocationEnum,
    locationPointId: number,
    locationTitle: string
}

export interface ZottTrailerStatus {
    trailerId: number,
    number: string,
    status: string,
    statusId: number,
    isLoaded: boolean,
    cargoType: string,
    cargoTypeId: number,
    wz: string,
    locationTitle: string,
    location: Location,
    zottLocationEnum: number,
    rampNumber: string,
    driverId: number,
    driverName: string,
    tractorRegNumber: string,
    tractorId: number,
    isTemporary: boolean,
    failure: boolean,
    service: boolean,
    loadWaitDurationMinutes: number | undefined,
    possibleExtraRouteLocationPoints: LocationPoint[]
}

export interface ZottTractorStatus
{
    tractorId: number,
    tractorRegNumber: string,
    driverId: number,
    driverName: string,
    trailerId: number,
    trailerNumber: string
}

export interface ZottPanelTrailerStatus {
    trailerStatus: ZottTrailerStatus;
    operationsCanPerform: string[];
}

export interface ZottDriverStatus {
    driverId: number,
    driverName: string,
    status: string,
    statusId: number,
    locationTitle: string,
    zottLocationEnum: number,
    tractorRegNumber: string,
    trailerStatus: ZottTrailerStatus,
}

export interface ServerMessage {
    id: number;
    messageTitleHtml: string;
    messageTextHtml: string;
    confirmed: boolean;
}

export interface ZottPanelDriverStatus {
    driverStatus: ZottDriverStatus,
    numberOfPallets: string,
    numberOfPalletsUpdateDateTime: string,
    numberOfInternationalPallets: string,
    numberOfInternationalPalletsUpdateDateTime: string,
    tractorsDrivingToOpoleCount: number | null,
    operationsCanPerform: string[],
    messages: ServerMessage[],
    locationsToGo: LocationToGo[]
}

export interface Trailer {
    id: number;
    number: string;
    description: string;
    maintainerId: number;
    maintainerName: string;
    isTemporary: boolean;
    isActive: boolean;
}

export interface OperationLogEntry {
    id: number;
    operationName: string;
    operationNameText: string;
    operationDateTimeText: string;
    additionalInfo: string;
    driverName: string;
    driverId: number;
    tractorRegNumber: string;
    tractorId: number;
    trailerNumber: string;
    trailerId: number;
    operationData: StringDictionary;
}

export interface BriefOperationLogEntry {
    id: number;
    operationName: string;
    operationNameText: string;
    operationDateTimeText: string;
    additionalInfoDict: StringDictionary;
    additionalInfo: any;
}

export interface TransportStatus {
    id: number,
    cargoType: string,
    wz: string,
    status: string,
    location: string
    driverName: string,
    tractorRegNumber: string,
    trailerNumber: string,
    finishedOnDate: Date,
    finishedOnDateTimeText: string,
}

export interface TransportReportEntry extends TransportStatus {
    freshArrivalTime: Date;
    setAtRampTime: Date;
    endUnloadingTime: Date;
    freshArrivalTimeText: string;
    setAtRampTimeText: string;
    endUnloadingTimeText: string;
    unloadingDuration: string;
    totalUnloadingDuration: string;
}

export interface TransportsMonthly {
    transports: TransportReportEntry[],
    startDate: string,
    endDate: string,
    oldestDate: string
}

export interface DestinationLocationData {
    locationTitle: string,
    returnPallets: string,
    numberOfPallets: number
}

export interface TransportExtraReportEntry {
    id: number,
    wz: string,
    kilometers: number,
    departureLocationTitle: string,
    destinationLocationsData: DestinationLocationData[],
    driverName: string,
    tractorRegNumber: string,
    trailerNumber: string,
    finishedOnDate: Date,
    finishedOnDateTimeText: string,
    finalLocationTitle: string
}

export interface TransportsExtraMonthly {
    transports: TransportExtraReportEntry[],
    startDate: string,
    endDate: string,
    oldestDate: string
}

export interface ShippingEntry {
    lp: number,
    transportEntryId: number,
    startLoadingDate: Date;
    shippingOperationDate: Date,
    shippingOperationDateText: string,
    startLoadingTimeText: string,
    endLoadingDate: Date,
    endLoadingTimeText: string,
    tractorId: number,
    tractorRegNumber: string,
    trailerId: number,
    trailerNumber: string,
    driverId: number,
    driverName: string,
    wz: string,
    numberOfPallets: number,
}

export interface FuelingEntry {
    lp: number
    id: number,
    userId: number,
    userName: string,
    registrationNumber: string,
    dateTime: Date,
    dateTimeText: string,
    kilometer: number,
    litres: number,
    priceNetto?: number,
    wz: string,
    notes: string,
    mileage?: number
}

export interface TrailerCleaningStatusEntry {
    id: number,
    trailerId: number,
    trailerNumber: string,
    tractorId: number,
    tractorRegistrationNumber: string,
    lastCleanedDate: Date,
    lastCleanedDateText: string,
    cleaningDriverId: number,
    cleaningDriverName: string,
    nextCleaningDate: Date,
    nextCleaningDateText: string,
    trailerMaintainerId: number,
    trailerMaintainerName: string
}

export interface TrailerCleaningReportEntry {
    lp: number,
    id: number,
    dateTime: Date,
    dateTimeText: string,
    trailerId: number,
    trailerNumber: string,
    tractorId: number,
    tractorRegistrationNumber: string,
    cleaningDriverId: number,
    cleaningDriverName: string,
    trailerTypeId: number,
    trailerTypeText: string
}

export interface PalletsUpdateEntry {
    id: number,
    palletsNumber: number,
    dateTime: Date,
    dateTimeText: string,
    userName: string,
}

export interface TransportingDriversDTO {
    drivers: string[],
    startDate: string,
    endDate: string,
    oldestDate: string
}

export enum ZottCargoTypeEnum {
    Normal = 1,
    Shipment,
    Pallets,
    Extra
}

export enum TrailerZottStatusEnum
{
    Unknown = 1,
    Unloaded,
    Loading,
    Loaded,
    BeforeUnloading,
    Unloading,
    ReturnFromExtra
}

export enum ZottLocationEnum
{
    Unknown = 1,
    Parking,
    DrivingToZott,
    Zott,
    DrivingToParking,
    Fresh,
    DrivingToFresh
}

export enum DriverStatusEnum
{
    NotWorking = 1,
    Working
}

export const ZottCargoTypesStringDictionary : StringDictionary = {
    "1": "Normalny",
    "2": "Przesyłki",
    "3": "Palety"
}

export const AllZottCargoTypesStringDictionary : StringDictionary = {
    "1": "Normalny",
    "2": "Przesyłki",
    "3": "Palety"
}

export const StartWorkLocationsStringDictionary : StringDictionary = {
    "2": "Parking",
    "4": "Zott",
    "6": "Fresh"
}

export const AllLocationsStringDictionary : StringDictionary = {
    "1": "Nieznana",
    "2": "Parking",
    "3": "Jazda do Zott",
    "4": "Zott",
    "5": "Jazda na parking",
    "6": "Fresh",
    "7": "Jazda do Fresh"
}

export const AllTrailerStatesStringDictionary : StringDictionary = {
    "1": "Nieznany",
    "2": "Pusta",
    "3": "Ładowanie",
    "4": "Załadowana",
    "5": "Wahadło (przed rozładunkiem)",
    "6": "Rozładunek"
}

export enum DataLoadingStateEnum
{
    Initial,
    Loading,
    Loaded
};
