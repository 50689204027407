import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { ApiRequest } from '../../services/apiClient';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { StringDictionary, ZottTrailerStatus, ZottTractorStatus, AllTrailerStatesStringDictionary, AllZottCargoTypesStringDictionary, AllLocationsStringDictionary } from '../../types/global';
import FormSelect from '../Common/FormSelect';
import FormTextField from '../Common/FormTextField';
import FormCheckboxField from '../Common/FormCheckboxField';

type ThisFormProps = RouteComponentProps<{ trailerId: string }> & {

};

type ThisFormState = {
    errors: {[key: string]:string[]},
    success: boolean,
    isLoading: boolean,
    trailerStatus: ZottTrailerStatus | undefined,
    tractorsDictionary: StringDictionary,
    tractors: ZottTractorStatus[],
    tractorsSelectKey: string,
    tractorDescription: string
}

export default class SetTrailerStatusForm extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            errors: {},
            success: false,
            isLoading: true,
            trailerStatus: undefined,
            tractors: [],
            tractorsDictionary: {},
            tractorsSelectKey: 'tractors1',
            tractorDescription: ''
        }
    }

    public componentDidMount() {
        this.fetchAllTractors();
        this.fetchTrailerStatus();
    }

    public render() {
        if (this.state.success) {
            return <Redirect to='/admin' />
        }

        var ts = this.state.trailerStatus;
        if (!ts)
        {
            return null;
        }

        return (
            <form className="form" onSubmit={this.onSubmit.bind(this)}>
                <h3>Ustaw status naczepy {ts.number}</h3>
                <input type="hidden" name="trailerId" value={ts.trailerId} />
                <FormSelect label="Status naczepy" values={AllTrailerStatesStringDictionary} defaultValue={ts.statusId.toString()} name="status"/>
                <FormSelect label="Typ towaru" values={AllZottCargoTypesStringDictionary} defaultValue={ts.cargoTypeId.toString()} name="cargoType" />
                <FormTextField label="WZ" defaultValue={ts.wz} name="wz"/>
                <FormSelect label="Lokacja" values={AllLocationsStringDictionary} defaultValue={ts.zottLocationEnum.toString()} name="location" />
                <FormTextField label="Nr rampy" defaultValue={ts.rampNumber} name="rampNumber" />
                <FormSelect label="Ciągnik" onChange={this.onTractorSelected.bind(this)} key={this.state.tractorsSelectKey} values={this.state.tractorsDictionary} defaultValue={ts.tractorId.toString()} name="tractorId" description={this.state.tractorDescription} />
                <FormCheckboxField label="Awaria" defaultChecked={ts.failure} value="true" name="failure" />
                <FormCheckboxField label="Serwis" defaultChecked={ts.service} value="true" name="service" />
                <div className="row-form">
                    <button type="submit" className="btn btn-primary">Zapisz</button>
                    <ButtonWithRoute className="btn btn-outline-secondary ml-2" path='/admin/drivers'>Anuluj</ButtonWithRoute>
                </div>
            </form>
        );
    }

    private async fetchAllTractors()
    {
        var response = await ApiRequest('/api/zott/getalltractorsstatus');

        if (response.status === 200)
        {
            const tractors = await response.json() as ZottTractorStatus[]
            const tractorsDictionary = {} as StringDictionary;

            tractors.forEach(tractor => tractorsDictionary[tractor.tractorId] = `${tractor.tractorRegNumber} (${tractor.driverName})`);
            this.setState({
                tractors: tractors,
                tractorsDictionary: tractorsDictionary,
                tractorsSelectKey: 'tractors' + Date.now()
            });
        } else {
            this.setState({
                tractorsDictionary: {},
                tractors: []
            });
        }
    }

    private async fetchTrailerStatus()
    {
        var trailerId = this.props.match.params.trailerId;
        var formData = new FormData();

        formData.set('trailerId', trailerId);

        var response = await ApiRequest('/api/zott/gettrailerstatus', formData);

        this.setState({
            isLoading: false
        });
        if (response.status === 200)
        {
            var trailerStatus = await response.json() as ZottTrailerStatus;

            this.setState({
                trailerStatus: trailerStatus
            });
        } else {
            var responseData = await response.json();
            if (responseData.errors) {
                this.setState({
                    errors: responseData.errors
                });
            }
        }
    }

    private onTractorSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;
        const id = parseInt(value);

        this.setTractorDescription(id);
    }

    private setTractorDescription(tractorId: number)
    {
        const tractorStatus = this.state.tractors.find(tractor => tractor.tractorId === tractorId);

        this.setState({
            tractorDescription: tractorStatus != null ? this.buildTractorDescription(tractorStatus) : ''
        });
    }

    private buildTractorDescription(tractorStatus: ZottTractorStatus)
    {
        const parts:string[] = [];

        if (tractorStatus.driverName)
        {
            parts.push('Kierowca:' + tractorStatus.driverName);
        }
        if (tractorStatus.trailerNumber)
        {
            parts.push('Naczepa:' + tractorStatus.trailerNumber);
        }
        return parts.join(', ');
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.validateForm()) {
            await this.submitForm(formEvent.target as HTMLFormElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        var result = await ApiRequest('/api/zott/settrailerstatus', data);

        if (result.status === 200) {
            this.setState({success: true});
            return true;
        } else {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
        }
    }

    private errorText(fieldName: string) : string {
        if (this.state.errors[fieldName] && this.state.errors[fieldName][0]) {
            return this.state.errors[fieldName][0];
        } else {
            return '';
        }
    }

    private haveErrors(fieldName: string) : boolean {
        return this.state.errors[fieldName] != null;
    }
}
