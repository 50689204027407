import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiRequest } from '../services/apiClient';

export interface Driver {
    id: number;
    userId: number;
    name: string;
}

export interface DriversState {
    isLoading: boolean;
    drivers: Driver[];
};

// Actions
interface RequestDriversAction {
    type: 'REQUEST_DRIVERS';
}

interface ReceiveDriversAction {
    type: 'RECEIVE_DRIVERS';
    drivers: Driver[];
}

const unloadedState: DriversState = { isLoading: false, drivers: [] };

type KnownAction = RequestDriversAction | ReceiveDriversAction;

export const actionCreators = {
    requestDrivers: (forceReload: boolean) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.drivers && ( appState.drivers.drivers.length === 0 || forceReload)) {
            ApiRequest('api/driver/getall')
                .then(response => {
                    if (response.status !== 200)
                    {
                        throw new Error(`Received status ${response.status}`);
                    }
                    return response.json() as Promise<Driver[]>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_DRIVERS', drivers: data});
                })
                .catch(() => {
                    console.log("catched");
                });
            dispatch({ type: 'REQUEST_DRIVERS' });
        }
    },

    deleteDriver: (id: number) : AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const formData = new FormData();

        formData.set('id', id.toString());

        const response = await ApiRequest('api/driver/delete',formData);
        const resultData = await response.json();
        if (resultData === true) {
            // TODO - Necessary to show confirmation or error
        }
    },
};

export const reducer: Reducer<DriversState> = (state: DriversState | undefined, incomingAction: Action) : DriversState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type) {
        case 'REQUEST_DRIVERS':
            return {
                drivers: state.drivers,
                isLoading: true
            };
        case 'RECEIVE_DRIVERS':
            return {
                drivers: action.drivers,
                isLoading: false
            };
    }
    return state;
};