import * as React from 'react';
import ZottForm from './ZottForm';
import FormSelect from '../Common/FormSelect';
import { ZottCargoTypesStringDictionary, ZottCargoTypeEnum } from '../../types/global';

export default class StartLoadingForm extends React.PureComponent {
    public render() {
        return(
            <ZottForm operationName="StartLoading" operationTitle="Rozpoczęcie ładowania" operationButtonText="Rozpocznij ładowanie">
                <FormSelect defaultValue={ZottCargoTypeEnum.Normal.toFixed().toString()} values={ZottCargoTypesStringDictionary} label="Typ towaru" name="cargoType" />
            </ZottForm>
        );
    }
}