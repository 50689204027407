import * as React from 'react';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { ZottTrailerStatus } from '../../types/global';

type TrailerMaintenanceOperationsProps = {
    operationsCanPerform: string[] | undefined;
    trailerStatus: ZottTrailerStatus | undefined;
} & Partial<{trailerId: number}>

export default class TrailerMaintenanceOperations extends React.PureComponent<TrailerMaintenanceOperationsProps, {}> {
    public render() {
        const panelButtonClasses = "col-sm btn btn-lg btn-panel";
        const trailerIdSuffix: string = this.props.trailerId ? '/' + this.props.trailerId.toString() : '';

        return (
            <div className="row">
                {this.canPerform("TrailerFailure") && <ButtonWithRoute className={panelButtonClasses} path={"/zott/trailerfailure" + trailerIdSuffix}>Awaria naczepy</ButtonWithRoute>}
                {this.canPerform("TrailerFixed") && <ButtonWithRoute className={panelButtonClasses} path={"/zott/trailerfixed" + trailerIdSuffix}>Naczepa naprawiona</ButtonWithRoute>}
                {this.canPerform("TrailerService") && <ButtonWithRoute className={panelButtonClasses} path={"/zott/trailerservice" + trailerIdSuffix}>Serwis naczepy</ButtonWithRoute>}
                {this.canPerform("TrailerEndService") && <ButtonWithRoute className={panelButtonClasses} path={"/zott/trailerendservice" + trailerIdSuffix}>Koniec serwisu naczepy</ButtonWithRoute>}
                {this.canCleanTrailer() && <ButtonWithRoute className={panelButtonClasses} path={"/zott/trailer-cleaning"}>Mycie naczepy</ButtonWithRoute>}
            </div>
        );
    }

    private canPerform(operationName:string)
    {
        if (!this.props.operationsCanPerform)
        {
            return false;
        }

        return this.props.operationsCanPerform.indexOf(operationName) >= 0;
    }

    private canCleanTrailer() : boolean
    {
        return !!this.props.trailerStatus
            && this.props.trailerStatus.trailerId > 0;
    }
}