import * as React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import * as Authorization from '../store/Authorization';
import FormTextField from './Common/FormTextField';
import Loader from './Common/Loader';

type LoginProps = typeof Authorization.actionCreators;

type LoginState = {
    errors: {[key: string]:string[]},
    isLogging: boolean,
    success: boolean
}

class Login extends React.PureComponent<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);

        this.state = {
            errors: {},
            isLogging: false,
            success: false
        }
    }

    public componentDidMount() {
        this.setState({
            errors: {},
            isLogging: false,
            success: false
        });
    }

    public render() {
        if (this.state.isLogging) {
            return <Loader/>;
        }
        if (this.state.success) {
            return <Redirect to='/' />;
        }
        return (
            <form className="form" onSubmit={this.onSubmit.bind(this)}>
                <h3>Logowanie</h3>
                <FormTextField name="loginName" label="Login" errors={this.state.errors} />
                <FormTextField name="password" label="Hasło" type="password" defaultValue="" errors={this.state.errors} />
                <button type="submit" className="btn btn-primary btn-block">Zaloguj</button>
            </form>
        );
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        var formData = new FormData(formEvent.target as HTMLFormElement);

        fetch('api/auth/login', 
            {method: 'post', body: formData}
        )
        .then(result => {
            this.setState({
                isLogging: false
            });
            return result.json()})
        .then(resultData => {
            if (resultData.authToken) {
                this.props.login(resultData.authToken, resultData.logoutToken, resultData.permissionSystemNames);
                this.setState({
                    isLogging: false,
                    success: true
                });
            } else {
                this.setState({errors: resultData.errors});
            }
        });

        this.setState({
            isLogging: true
        });
    }
}

export default connect(null, Authorization.actionCreators)(Login as any);