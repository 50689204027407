import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Authorization from '../../store/Authorization';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { PanelButtonClasses } from '../../services/styleService';

type ThisFormProps = Authorization.AuthorizationState;

class ReportsPanel extends React.PureComponent<ThisFormProps, {}> {
    public render() {
        return(
            <div className="container panel">
                <h3>Raporty</h3>
                {this.hasPermission("reports:transportsmonthly")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/reports/transportsmonthly">Transporty</ButtonWithRoute>}
                {this.hasPermission("reports:transportsmonthly")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/reports/transports-extra-monthly">Transporty Extra</ButtonWithRoute>}
                {this.hasPermission("reports:shippings")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/reports/shippings">Załadunki</ButtonWithRoute>}
                {this.hasPermission("reports:fuelings")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/reports/fuelings">Tankowania</ButtonWithRoute>}
                {this.hasPermission("reports:trailercleaning")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/reports/trailer-cleanings">Mycia naczep</ButtonWithRoute>}
                {this.hasPermission("reports:palletsupdates")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/reports/pallets-updates">Aktualizacje liczby palet</ButtonWithRoute>}
                {this.hasPermission("reports:internationalpalletsupdates")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/reports/international-pallets-updates">Aktualizacje liczby palet międzynarodowych</ButtonWithRoute>}
                {this.hasPermission("reports:transportingdrivers")
                    && <ButtonWithRoute className={PanelButtonClasses} path="/reports/transporting-drivers">Kierowcy w transportach</ButtonWithRoute>}
            </div>
        );
    }

    private hasPermission(permissionName: string):boolean {
        return this.props.permissions.some(function(permission) {
            return permission.startsWith(permissionName);
        });
    }
}

export default connect(
    (state:ApplicationState) => state.authorization, 
    null
)(ReportsPanel as any);