import * as React from 'react';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import NotificationMessage from '../Common/NotificationMessage';
import { ApiRequest } from '../../services/apiClient';
import Loader from '../Common/Loader';
import { Tractor } from '../../types/global';
import { DangerousOperationButtonClasses } from '../../services/styleService';

type TractorsProps = {};

type TractorsState = {
    showDeleteConfirmation: boolean,
    deleteConfirmationTitle: string,
    deleteConfirmationText: string,
    idToDelete: number,
    isLoading: boolean,
    tractorsLoaded: boolean,
    tractors: Tractor[]
};


export default class Tractors extends React.PureComponent<TractorsProps, TractorsState> {
    constructor(props: TractorsProps) {
        super(props);

        this.state = {
            showDeleteConfirmation: false,
            deleteConfirmationTitle: '',
            deleteConfirmationText: '',
            idToDelete: 0,
            isLoading: false,
            tractorsLoaded: false,
            tractors: []
        }
    }

    public componentDidMount() {
        this.requestTractors();
    }

    public render() {
        if (this.state.isLoading) {
            return <Loader></Loader>;
        }
        if (!this.state.tractorsLoaded) {
            return null;
        }
        if (this.state.tractors.length === 0)
        {
            return (
                <div>
                    <div className="row">
                        <h3>Brak ciągników</h3>
                        <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/tractors/new">Nowy ciągnik</ButtonWithRoute>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className="row">
                    <h3>Ciągniki</h3>
                    <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/tractors/new">Nowy ciągnik</ButtonWithRoute>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Numer rejestracyjny</th>
                            <th>Opis</th>
                            <th>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.tractors.map((tractor: Tractor) => 
                            <tr key={tractor.id}>
                                <td>{tractor.registrationNumber}</td>
                                <td>{tractor.description}</td>
                                <td className="actions">
                                    <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/admin/tractors/edit/' + tractor.id}>Edytuj</ButtonWithRoute>
                                    <button onClick={() => this.confirmDelete(tractor)} className={DangerousOperationButtonClasses}>Usuń</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <NotificationMessage show={this.state.showDeleteConfirmation}
                    id="deleteConfirmation"
                    title={this.state.deleteConfirmationTitle}
                    message={this.state.deleteConfirmationText}
                    cancelButtonText="Anuluj"
                    okButtonText="Usuń"
                    cancelFunction={this.cancelDelete.bind(this)}
                    confirmationFunction={() => this.doDelete(this.state.idToDelete)} />
            </div>
        );
    }

    private requestTractors() {
        this.setState({
            isLoading: true
        });

        ApiRequest('api/tractor/getall')
        .then(result => {
            this.setState({
                isLoading: false
            });
            return result.json() as Promise<Tractor[]>}
        )
        .then(tractors => this.setState({
            tractorsLoaded: true,
            tractors: tractors
        }))
    }

    private async deleteTractor(id: number) {
        let formData = new FormData();

        formData.set('id', id.toString());

        const response = await ApiRequest('api/tractor/delete', formData);
        const data = await response.json();
        if (data === true) {
            // TODO - Show confirmation
        }
    }

    private confirmDelete(tractor: Tractor) {

        this.setState({
            showDeleteConfirmation: true,
            deleteConfirmationTitle: "Potwierdzenie usunięcia",
            deleteConfirmationText: "Usunąć ciągnik " + tractor.registrationNumber + " ?",
            idToDelete: tractor.id
        });
    }

    private cancelDelete() {
        this.setState({showDeleteConfirmation: false});
    }

    private async doDelete(id: number) {
        this.setState({showDeleteConfirmation: false});
        await this.deleteTractor(id);
        this.requestTractors();
    }
}
