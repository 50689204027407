import * as React from 'react';
import { connect } from 'react-redux';
import ZottForm from './ZottForm';
import UnhitchTrailerFields from './UnhitchTrailerFields';
import { ApplicationState } from '../../store';
import * as DriverStatus from '../../store/DriverStatus';
import { ZottTrailerStatus } from '../../types/global';

type UnHitchTrailerProps = DriverStatus.ZottPanelDriverStatusStore
& typeof DriverStatus.actionCreators;

type UnHitchTrailerState = {};

class UnHitchTrailerForm extends React.PureComponent<UnHitchTrailerProps, UnHitchTrailerState> {
    constructor(props: UnHitchTrailerProps)
    {
        super(props);

        this.state = {}
    }

    public render() {
        return (
            <ZottForm operationName="UnHitchTrailer" operationTitle="Odczep naczepę" operationButtonText="Odczep naczepę">
                <UnhitchTrailerFields currentTrailer={this.currentTrailer()} />
            </ZottForm>
        );
    }

    private currentTrailer() : ZottTrailerStatus | undefined {
        return this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.trailerStatus;
    }
}

export default connect(
    (state:ApplicationState) => state.driverStatus,
    DriverStatus.actionCreators
)(UnHitchTrailerForm as any);
