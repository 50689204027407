import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as DriverStatus from '../../store/DriverStatus';
import { ZottCargoTypeEnum } from '../../types/global';
import ZottForm from './ZottForm';
import FormTextField from '../Common/FormTextField';
import FormRadioGroup from '../Common/FormRadioGroup';

type ThisFormProps = DriverStatus.ZottPanelDriverStatusStore;

type ThisFormState = {
    returnPalletsSelectedValue: string
};

class EndUnloadingForm extends React.PureComponent<ThisFormProps, ThisFormState> {

    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            returnPalletsSelectedValue: ''
        };
    }

    public render() {
        return(
            <ZottForm operationName="EndUnloading" operationTitle="Zakończenie rozładunku" operationButtonText="Zakończ rozładunek">
                {this.showExtraFields() && <FormTextField type="number" name="kilometer" label="Przebieg" isMandatory={true} defaultValue={0} />}
                {this.showExtraFields() && <FormRadioGroup inline={true} label="Zwrot palet" isMandatory={true} name="returnPallets" values={["1", "0"]} labels={["Tak", "Nie"]} defaultValue={this.state.returnPalletsSelectedValue} onChange={this.onReturnPalletsChange.bind(this)} />}
                {this.showPalletsCount() && <FormTextField type="number" name="numberOfPallets" label="Liczba palet" isMandatory={true} defaultValue={0} />}
            </ZottForm>
        );
    }

    private showExtraFields() : boolean {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.trailerStatus)
        {
            return this.props.zottPanelDriverStatus.driverStatus.trailerStatus.cargoTypeId === ZottCargoTypeEnum.Extra;
        }
        return false;
    }

    private showPalletsCount() : boolean {
        return this.state.returnPalletsSelectedValue === '1';
    }

    private onReturnPalletsChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            returnPalletsSelectedValue: event.currentTarget.value
        });
    }
}

export default connect(
    (state:ApplicationState) => state.driverStatus,
        null
)(EndUnloadingForm as any);