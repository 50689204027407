import * as DateFormatter from './DateFormatter';
import {
    TransportStatus,
    TransportReportEntry,
    TransportExtraReportEntry,
    ShippingEntry,
    BriefOperationLogEntry,
    FuelingEntry,
    TrailerCleaningStatusEntry,
    TrailerCleaningReportEntry,
    PalletsUpdateEntry
} from '../types/global';
import { OperationDataElement } from '../components/Common/Helper';

function Reviver(key: string, value: string) : any {
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

    if (typeof value === "string" && dateFormat.test(value)) {
        return new Date(value);
    }
    
    return value;
}

export async function JsonParseResult(result: Response) {
    return JSON.parse(await result.text(), Reviver);
}

export function EnhanceTransportStatusCollection(tsc: TransportStatus[]) {
    tsc.forEach(ts => {
        if (ts.finishedOnDate) {
            ts.finishedOnDateTimeText = DateFormatter.DateToDateTimeString(ts.finishedOnDate);
        }
    });
}

export function EnhanceTransportReportEntryCollection(tsc: TransportReportEntry[]) {
    tsc.forEach(ts => {
        if (ts.finishedOnDate) {
            ts.finishedOnDateTimeText = DateFormatter.DateToDateTimeString(ts.finishedOnDate);
        }
        if (ts.freshArrivalTime) {
            ts.freshArrivalTimeText = DateFormatter.DateToDateTimeString(ts.freshArrivalTime);
        }
        if (ts.setAtRampTime) {
            ts.setAtRampTimeText = DateFormatter.DateToDateTimeString(ts.setAtRampTime);
        }
        if (ts.endUnloadingTime) {
            ts.endUnloadingTimeText = DateFormatter.DateToDateTimeString(ts.endUnloadingTime);
        }
    });
}

export function EnhancePalletsUpdateEntriesCollection(collection: PalletsUpdateEntry[]) {
    collection.forEach(item => {
        if (item.dateTime) {
            item.dateTimeText = DateFormatter.DateToDateTimeString(item.dateTime);
        }
    });
}

export function EnhanceTransportExtraCollection(tsc: TransportExtraReportEntry[]) {
    tsc.forEach(ts => {
        if (ts.finishedOnDate) {
            ts.finishedOnDateTimeText = DateFormatter.DateToDateTimeString(ts.finishedOnDate);
        }
    });
}

export function EnhanceShippingsCollection(sc: ShippingEntry[]) {
    sc.forEach(s => {
        if (s.startLoadingDate) {
            s.shippingOperationDate = s.startLoadingDate;
            s.shippingOperationDateText = DateFormatter.DateToDateString(s.shippingOperationDate);
            s.startLoadingTimeText = DateFormatter.DateToTimeString(s.startLoadingDate);
        }
        if (s.endLoadingDate) {
            s.endLoadingTimeText = DateFormatter.DateToTimeString(s.endLoadingDate);
        }
    });
}

export function EnhanceBriefOperationLogs(logsCollection: BriefOperationLogEntry[]) {
    logsCollection.forEach(s => {
        s.additionalInfo = OperationDataElement(s.additionalInfoDict)
    });
}

export function EnhanceFuelingsCollection(fuelingsCollection: FuelingEntry[]) {
    fuelingsCollection.forEach(f => {
        f.dateTimeText = DateFormatter.DateToDateTimeString(f.dateTime);
    });
}

export function EnhanceTrailerCleaningsCollection(trailerCleaningsCollection: TrailerCleaningStatusEntry[]) {
    trailerCleaningsCollection.forEach(tc => {
        tc.lastCleanedDateText = DateFormatter.DateToDateTimeString(tc.lastCleanedDate);
        tc.nextCleaningDateText = DateFormatter.DateToDateString(tc.nextCleaningDate);
    });
}

export function EnhanceTrailerCleaningsReportCollection(trailerCleaningsCollection: TrailerCleaningReportEntry[]) {
    trailerCleaningsCollection.forEach(tc => {
        tc.dateTimeText = DateFormatter.DateToDateTimeString(tc.dateTime);
    });
}