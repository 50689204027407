import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import ZottForm from './ZottForm';
import { RouteComponentProps } from 'react-router-dom';
import * as querystringify from 'querystringify';
import * as DriverStatus from '../../store/DriverStatus';
import { ZottCargoTypeEnum, KnownLocationPointIds, LocationEnum, ZottTrailerStatus, TrailerZottStatusEnum } from '../../types/global';
import FormTextField from '../Common/FormTextField';

type ThisFormState = {
    locationPointId: number,
    locationTitle: string,
    locationEnum: number
}

type ThisFormProps = RouteComponentProps & DriverStatus.ZottPanelDriverStatusStore;

class GoToLocationForm extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        const parsed = querystringify.parse(this.props.location.search.slice(1)) as any;

        this.state = {
            locationPointId: parseInt(parsed['id'] as string),
            locationTitle: parsed['location-title'] as string,
            locationEnum: parseInt(parsed['l'] as string)
        }
    }

    public render() {
        return(
            <ZottForm operationName="GoToLocation" operationTitle={this.state.locationTitle} operationButtonText="Ustaw lokację">
                <input type='hidden' name='locationPointId' value={this.state.locationPointId} />
                <input type='hidden' name='locationEnum' value={this.state.locationEnum} />
                {this.showKilometerField() && <FormTextField type="number" name="kilometer" label="Przebieg" isMandatory={true} defaultValue={0} />}
            </ZottForm>
        );
    }

    private showKilometerField() : boolean {
        return this.isAtParkingWithExtraCargoType()
            || this.isAtKnownLocationReturningFromExtraRoute();
    }

    private isAtParkingWithExtraCargoType() : boolean {
        if (this.state.locationPointId === KnownLocationPointIds.ParkingId
            && this.state.locationEnum === LocationEnum.At)
        {
            var trailerStatus = this.getTrailerStatus();
            return (trailerStatus != null
                && trailerStatus.isLoaded
                && trailerStatus.cargoTypeId === ZottCargoTypeEnum.Extra);
        }
        return false;
    }

    private isAtKnownLocationReturningFromExtraRoute() : boolean {
        var locationPointId = this.state.locationPointId;

        if ((locationPointId === KnownLocationPointIds.ParkingId
            || locationPointId === KnownLocationPointIds.ZottId
            || locationPointId === KnownLocationPointIds.FreshId)
            && this.state.locationEnum === LocationEnum.At)
        {
            var trailerStatus = this.getTrailerStatus();

            return (trailerStatus != null
                && trailerStatus.statusId === TrailerZottStatusEnum.ReturnFromExtra);
        }
        return false;
    }
    
    private getTrailerStatus() : ZottTrailerStatus | null {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.trailerStatus
        ) {
            return this.props.zottPanelDriverStatus.driverStatus.trailerStatus;
        }
        return null;
    }
}

export default connect(
    (state:ApplicationState) => state.driverStatus,
        null
)(GoToLocationForm as any);