import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { ApplicationState } from '../store';
import * as Authorization from '../store/Authorization';
import ButtonWithRoute from './Common/ButtonWithRoute';

type ThisComponentProps = typeof Authorization.actionCreators & Authorization.AuthorizationState;

type ThisComponentState = {
};

class Home extends React.PureComponent<ThisComponentProps, ThisComponentState>{
  public render() {

    const panelButtonClasses = "col-sm btn btn-lg btn-panel";

    return (
      <div>
        <Row className="panel-row">
          {this.hasPermission("admin") &&
            <Col lg="6">
              <ButtonWithRoute className={panelButtonClasses} path="/admin">Panel administratora</ButtonWithRoute>
            </Col>
          }
          {this.hasPermission("zott:panel") &&
            <Col lg="6">
              <ButtonWithRoute className={panelButtonClasses} path="/zott">Panel Zott</ButtonWithRoute>
            </Col>
          }
          {this.hasPermission("status") &&
            <Col lg="6">
              <ButtonWithRoute className={panelButtonClasses} path="/status">Statusy</ButtonWithRoute>
            </Col>
          }
          {this.hasPermission("fueling") &&
            <Col lg="6">
              <ButtonWithRoute className={panelButtonClasses} path="/fueling">Tankowanie</ButtonWithRoute>
            </Col>
          }
          {this.hasPermission("reports") &&
            <Col lg="6">
              <ButtonWithRoute className={panelButtonClasses} path="/reports">Raporty</ButtonWithRoute>
            </Col>
          }
        </Row>
      </div>
    );
  }

  private hasPermission(permissionName: string):boolean {
        return this.props.permissions.some(function(permission) {
            return permission.startsWith(permissionName);
        });
    }
};

export default connect(
    (state:ApplicationState) => state.authorization, 
    Authorization.actionCreators
)(Home as any);
