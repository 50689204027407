import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { StringDictionary, TransportExtraReportEntry } from '../../types/global';
import Loader from '../Common/Loader';
import { TransportsExtraMonthly } from '../../types/global';
import FormSelect from '../Common/FormSelect';
import TransportsExtraTable from './TransportsExtraTable';
import { ExportCSV } from '../Common/ExportCSVButton';
import * as DateFormatter from '../../helpers/DateFormatter';
import * as DaoHelper from '../../helpers/DaoHelper';
import { buildMonthsDictionary } from '../../helpers/FormHelper';

type ThisFormProps = {

};

type ThisFormState = {
    transportEntries: TransportExtraReportEntry[] | undefined,
    maxDestinationsCount: number,
    months: StringDictionary,
    error: string,
    isLoading: boolean,
    isLoaded: boolean,
    monthsSelectKey: string,
    isMonthsSelectSet: boolean,
    selectedDate: string
}


export default class TransportsExtraMonthlyReport extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            transportEntries: undefined,
            maxDestinationsCount: 0,
            months: {},
            error: '',
            isLoading: false,
            isLoaded: false,
            monthsSelectKey: 'monthsSelectKey',
            isMonthsSelectSet: false,
            selectedDate: ''
        }

        this.transportStatusToExcel = this.transportStatusToExcel.bind(this);
    }

    public render() {
        if (this.state.isLoading)
        {
            return <Loader/>;
        }
        if (!this.state.isLoaded)
        {
            return null;
        }
        return (
            <div className="container">
                <div className="row">
                    <h3>Transporty Extra - Raport Miesięczny</h3>
                </div>
                <div className="row align-items-center">
                    <div className="col">
                        <FormSelect onChange={this.onMonthSelected.bind(this)} key={this.state.monthsSelectKey} label="Miesiąc" values={this.state.months} defaultValue={this.state.selectedDate}></FormSelect>
                    </div>
                    <div className="col">
                        {this.hasTransports() && <ExportCSV className="btn btn-outline-secondary btn-sm ml-4" label="Eksportuj do Excela" csvData={this.transportsForExcel()} fileName={this.reportFileName()}/>}
                    </div>
                </div>
                <TransportsExtraTable transports={this.state.transportEntries} />
            </div>
        );
    }

    public componentDidMount() {
        this.fetchTransports(undefined);
    }

    private onMonthSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;

        this.setState({
            selectedDate: value
        });

        this.fetchTransports(value);
    }

    private async fetchTransports(monthString: string | undefined)
    {
        this.setState({
            isLoading: true
        });

        const formData = new FormData();

        if (monthString) {
            formData.set('monthDateTime', monthString);
        }

        const result = await ApiRequest('api/reports/transports-extra-monthly', formData);

        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            this.setState({
                error: 'Bład pobierania transportów'
            });
            return;
        }

        const transportsMonthly = await DaoHelper.JsonParseResult(result) as TransportsExtraMonthly;

        if (!this.state.isMonthsSelectSet) {
            const monthsDictionary = buildMonthsDictionary(transportsMonthly.oldestDate);
            this.setState({
                months: monthsDictionary,
                monthsSelectKey: 'monthsSelectKey' + Date.now(),
                isMonthsSelectSet: true
            });
        }

        DaoHelper.EnhanceTransportExtraCollection(transportsMonthly.transports);

        this.setState({
            isLoaded: true,
            transportEntries: transportsMonthly.transports,
            maxDestinationsCount: this.determineMaxDestinationsCount(transportsMonthly.transports)
        });
    }

    private transportsForExcel()
    {
        return this.state.transportEntries!.map(this.transportStatusToExcel.bind(this));
    }

    private transportStatusToExcel(te: TransportExtraReportEntry)
    {
        const excelRecord = 
        {
            'Data': DateFormatter.DateToExcelDateTimeString(te.finishedOnDate),
            'WZ': te.wz,
            'Wyjazd': te.departureLocationTitle,
            'Powrót': te.finalLocationTitle,
            'Kilometry': te.kilometers,
            'Kierowca': te.driverName,
            'Ciągnik': te.tractorRegNumber,
            'Naczepa': te.trailerNumber
        } as any;

        const maxDestinations = this.state.maxDestinationsCount;

        for(let i = 0; i < maxDestinations; i++)
        {
            const index = i + 1;
            if (i < te.destinationLocationsData.length)
            {
                const destData = te.destinationLocationsData[i];
                excelRecord[`Miejsce_docelowe_${index}`] = destData.locationTitle;
                excelRecord[`Zwrot_palet_${index}`] = destData.returnPallets;
                excelRecord[`Liczba_palet_${index}`] = destData.numberOfPallets;
            } else {
                excelRecord[`Miejsce_docelowe_${index}`] = '';
                excelRecord[`Zwrot_palet_${index}`] = '';
                excelRecord[`Liczba_palet_${index}`] = '';
            }
        }

        return excelRecord;
    }

    private determineMaxDestinationsCount(transportEntries: TransportExtraReportEntry[]) : number
    {
        let maxDestinations = 1;

        for(let i = 0; i < transportEntries.length; i++)
        {
            const entryDestinationsCount = transportEntries[i].destinationLocationsData.length;

            if (entryDestinationsCount > maxDestinations) {
                maxDestinations = entryDestinationsCount;
            }
        }

        return maxDestinations;
    }

    private reportFileName() : string
    {
        return 'RaportMiesięcznyTransportów_' + this.state.selectedDate;
    }

    private hasTransports() : boolean
    {
        return !!this.state.transportEntries
            && this.state.transportEntries.length > 0;
    }
}