import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { TransportReportEntry } from '../../types/global';
import Loader from '../Common/Loader';
import { TransportsMonthly } from '../../types/global';
import moment from 'moment';
import TransportsTable from './TransportsTable';
import { ExportCSV } from '../Common/ExportCSVButton';
import * as DateFormatter from '../../helpers/DateFormatter';
import * as DaoHelper from '../../helpers/DaoHelper';
import FormGeneralErrors from '../Common/FormGeneralErrors';
import FormDatePicker from '../Common/FormDatePicker';
import { FileDateFormat } from '../../helpers/DateFormatter';

type TransportReportData = {
    fromDate: Date,
    toDate: Date,
};

type ThisFormProps = {

};

type ThisFormState = {
    fromDate: Date,
    toDate: Date,
    transportReportEntries: TransportReportEntry[] | undefined,
    error: string,
    isLoading: boolean,
    isLoaded: boolean,
    monthsSelectKey: string,
    errors: {[key: string]:string[]},
    showForm: boolean,
    currentReportData: TransportReportData | undefined
}


export default class TransportsMonthlyReport extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            transportReportEntries: undefined,
            fromDate: moment().startOf('month').toDate(),
            toDate: new Date(),
            error: '',
            isLoading: false,
            isLoaded: false,
            monthsSelectKey: 'monthsSelectKey',
            errors: {},
            showForm: true,
            currentReportData: undefined
        }
    }

    public render() {
        if (this.state.isLoading)
        {
            return <Loader/>;
        }
        return (
            <div className="container">
                <div className="row">
                    <h3>Raport Transportów</h3>
                </div>
                <FormGeneralErrors errors={this.state.errors}/>
                {this.state.showForm &&
                <form className="form" onSubmit={this.fetchTransports.bind(this)}>
                    <FormDatePicker errors={this.state.errors} label="Od" name="fromDate" selected={this.state.fromDate} onChange={this.onFromDateChange.bind(this)}/>
                    <FormDatePicker errors={this.state.errors} label="Do" name="toDate" selected={this.state.toDate} onChange={this.onToDateChange.bind(this)}/>
                    <div className="form-group">
                        <button type="submit" className="btn btn-outline-secondary btn-sm ml-4">Generuj raport</button>
                    </div>
                </form>
                }
                {this.state.isLoaded &&
                <div className="row align-items-center">
                    <div className="col">
                        <span>Raport z {this.dateRangeTitle()} </span>
                        {this.hasTransports() && <ExportCSV className="btn btn-outline-secondary btn-sm ml-4" label="Eksportuj do Excela" csvData={this.transportsForExcel()} fileName={this.reportFileName()}/>}
                        {!this.state.showForm && <button onClick={() => this.showForm()} className="btn btn-outline-secondary btn-sm ml-4">Kolejny raport</button>}
                    </div>
                </div>}
                {this.state.isLoaded && <TransportsTable transports={this.state.transportReportEntries} />}
            </div>
        );
    }

    private async fetchTransports(formEvent: React.FormEvent<HTMLFormElement>)
    {
        this.setState({
            isLoading: true
        });

        formEvent.preventDefault();

        var formElement = formEvent.target as HTMLFormElement;
        const formData = new FormData(formElement);

        var reportData : TransportReportData = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        };

        const result = await ApiRequest('api/reports/transportsmonthly', formData);

        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
            return;
        }

        const transportsMonthly = await DaoHelper.JsonParseResult(result) as TransportsMonthly;
        DaoHelper.EnhanceTransportReportEntryCollection(transportsMonthly.transports);

        var showForm = !transportsMonthly.transports || !transportsMonthly.transports.length;

        this.setState({
            errors: {},
            showForm: showForm,
            isLoaded: true,
            transportReportEntries: transportsMonthly.transports,
            currentReportData: reportData
        });
    }

    private onFromDateChange(date: Date) {
        this.setState({
            fromDate: date
        });
    }

    private onToDateChange(date: Date) {
        this.setState({
            toDate: date
        });
    }

    private showForm() {
        this.setState({
            showForm: true
        });
    }

    private dateRangeTitle() : string {
        const reportData = this.state.currentReportData;
        if (!reportData) {
            return '';
        }

        return moment(reportData.fromDate).format('DD/MM') + ' - ' + moment(reportData.toDate).format('DD/MM');
    }

    private transportsForExcel()
    {
        return this.state.transportReportEntries!.map(this.transportStatusToExcel);
    }

    private transportStatusToExcel(ts: TransportReportEntry)
    {
        return {
            'Data': DateFormatter.DateToExcelDateTimeString(ts.finishedOnDate),
            'Typ transportu': ts.cargoType,
            'WZ': ts.wz,
            'Kierowca': ts.driverName,
            'Ciągnik': ts.tractorRegNumber,
            'Naczepa': ts.trailerNumber,
            'Godzina dojazdu': DateFormatter.DateToExcelDateTimeString(ts.freshArrivalTime),
            'Godzina podstawienia': DateFormatter.DateToExcelDateTimeString(ts.setAtRampTime),
            'Godzina zakończenia rozładunku': DateFormatter.DateToExcelDateTimeString(ts.endUnloadingTime),
            'Czas rozładunku': ts.unloadingDuration,
            'Łączny czas': ts.totalUnloadingDuration
        };
    }

    private reportFileName() : string
    {
        var reportData = this.state.currentReportData;
        
        return 'RaportTransportów_' + moment(reportData!.fromDate).format(FileDateFormat) + '_' + moment(reportData!.toDate).format(FileDateFormat);
    }

    private hasTransports() : boolean
    {
        return !!this.state.transportReportEntries
            && this.state.transportReportEntries.length > 0;
    }
}