import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { TrailerCleaningStatusEntry, StringDictionary, DataLoadingStateEnum } from '../../types/global';
import Loader from '../Common/Loader';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import SortableTable from '../Common/SortableTable';
import * as DaoHelper from '../../helpers/DaoHelper';

type ThisComponentProps = {
};

type ThisComponentState = {
    dataLoadingState: DataLoadingStateEnum,
    trailerCleanings: TrailerCleaningStatusEntry[] | undefined,
    error: string
};

export default class TrailerCleaningStatus extends React.PureComponent<ThisComponentProps, ThisComponentState> {
    constructor(props:ThisComponentProps) {
        super(props);

        this.state = {
            dataLoadingState: DataLoadingStateEnum.Initial,
            trailerCleanings: undefined,
            error: ''
        };
    }

    public render() {
        if (this.state.dataLoadingState === DataLoadingStateEnum.Loading)
        {
            return <Loader/>;
        }
        if (this.state.dataLoadingState !== DataLoadingStateEnum.Loaded)
        {
            return null;
        }
        if (this.state.trailerCleanings && this.state.trailerCleanings!.length === 0) {
            return <div className="row">
                <h3>Status mycia naczep - brak</h3>
            </div>;
        }
        return (
            <div>
                <div className="row">
                    <h3>Status mycia naczep</h3>
                </div>
                <SortableTable dataRows={this.state.trailerCleanings} columns={this.trailerCleaningColumns()} renderActions={(row) => <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/zott/trailer-cleaning-log/' + row.trailerId}>Historia</ButtonWithRoute>}/>
            </div>
        );
    }

    public componentDidMount() {
        this.fetchTrailerCleaningStatus();
    }

    private async fetchTrailerCleaningStatus() {
        this.setState({
            dataLoadingState: DataLoadingStateEnum.Loading
        });

        const result = await ApiRequest('api/zott/trailer-cleaning-status');

        if (result.status !== 200) {
            this.setState({
                dataLoadingState: DataLoadingStateEnum.Loaded,
                error: 'Bład pobierania statusu kierowców'
            });
            return;
        }

        const trailerCleaningStatuses = await DaoHelper.JsonParseResult(result) as TrailerCleaningStatusEntry[];

        DaoHelper.EnhanceTrailerCleaningsCollection(trailerCleaningStatuses);

        this.setState({
            dataLoadingState: DataLoadingStateEnum.Loaded,
            error: '',
            trailerCleanings: trailerCleaningStatuses
        });
    }

    private trailerCleaningColumns() : StringDictionary {
        const columns = {} as StringDictionary;

        columns['trailerNumber'] = 'Naczepa';
        columns['tractorRegistrationNumber'] = 'Ciągnik';
        columns['cleaningDriverName'] = 'Kierowca';
        columns['lastCleanedDate'] = 'Data ostatniego mycia';
        columns['nextCleaningDate'] = 'Data kolejnego mycia';
        columns['trailerMaintainerName'] = 'Opiekun naczepy';
        columns['actions'] = 'Akcje';

        return columns;
    }
}
