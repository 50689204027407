import * as React from 'react';
import ZottForm from './ZottForm';

// TODO - Obsolete, delete if not used
export default class FreshForm extends React.PureComponent {
    public render() {
        return(
            <ZottForm operationName="Fresh" operationTitle="Fresh" operationButtonText="Fresh" />
        );
    }
}