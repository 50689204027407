import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as PermissionsStore from '../../store/Permissions';

type PermissionsOwnProps = { defaultIds: number[] };

type PermissionsProps = PermissionsOwnProps
    & (PermissionsStore.PermissionsState | undefined)
    & typeof PermissionsStore.actionCreators;

class Permissions extends React.PureComponent<PermissionsProps> {

    public componentDidMount() {
        this.props.requestPermissions();
    }

    public render() {
        return (
            <div>
                <label>Uprawnienia</label>
                    {this.props.permissions.map((permission: PermissionsStore.Permission) => 
                        <div key={permission.id} className="form-check form-group">
                            <input defaultChecked={this.hasDefaultPermission(permission.id)} className="form-check-input" type="checkbox" id={"permission_"+permission.id} name="permissionIds" value={permission.id}/>
                            <label className="form-check-label" htmlFor={"permission_"+permission.id}>{permission.name}</label>
                        </div>
                    )}
            </div>
        );
    }

    private hasDefaultPermission(permissionId: number) : boolean {
        return this.props.defaultIds.indexOf(permissionId) >= 0;
    }
}

export default connect<PermissionsStore.PermissionsState | undefined, typeof PermissionsStore.actionCreators, PermissionsOwnProps, ApplicationState>(
    (state: ApplicationState, ownProps: PermissionsOwnProps) => state.permissions,
    PermissionsStore.actionCreators
)(Permissions as any);