import * as React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ApiRequest } from '../../services/apiClient';
import * as DriverStatus from '../../store/DriverStatus';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import Loader from '../Common/Loader';

type ThisFormProps = DriverStatus.ZottPanelDriverStatusStore;

enum TrailerCleaningFormState
{
    Initial,
    Canceled,
    Submitting,
    Saved
};

type ThisFormState = {
    formState: TrailerCleaningFormState,
    errors: {[key: string]:string[]},
};

class TrailerCleaningForm extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            formState: TrailerCleaningFormState.Initial,
            errors: {}
        };
    }
    
    public render() {
        if (this.isDone()) {
            return <Redirect to="/zott"/>;
        }
        return(
            <form className="form" onSubmit={this.onSubmit.bind(this)} autoComplete="off">
                <h3>Mycie naczepy {this.getTrailerNumber()}</h3>
                <input type="hidden" name="trailerId" value={this.trailerId()}></input>
                <input type="hidden" name="tractorId" value={this.tractorId()}></input>
                <input type="hidden" name="driverId" value={this.driverId()}></input>
                {this.isSubmiting() && <Loader/>}
                {!this.isSubmiting() && <button type="submit" className="btn btn-primary btn-block">Mycie</button>}
                {!this.isSubmiting() && <ButtonWithRoute className="btn btn-block" path="/zott" disabled={this.isSubmiting()}>Anuluj</ButtonWithRoute>}
            </form>
        );
    }

    private isSubmiting()
    {
        return this.state.formState === TrailerCleaningFormState.Submitting;
    }

    private isDone()
    {
        return this.state.formState === TrailerCleaningFormState.Saved
            || this.state.formState === TrailerCleaningFormState.Canceled;
    }

    private getTrailerNumber() : string
    {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.trailerStatus)
        {
            return this.props.zottPanelDriverStatus.driverStatus.trailerStatus.number;
        }
        return '';
    }

    private trailerId() : number
    {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.trailerStatus)
        {
            return this.props.zottPanelDriverStatus.driverStatus.trailerStatus.trailerId;
        }
        return 0;
    }

    private tractorId() : number{
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.trailerStatus)
        {
            return this.props.zottPanelDriverStatus.driverStatus.trailerStatus.tractorId;
        }
        return 0;
    }

    private driverId() : number
    {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus)
        {
            return this.props.zottPanelDriverStatus.driverStatus.driverId;
        }
        return 0;
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();
        if (this.state.formState !== TrailerCleaningFormState.Initial) {
            return;
        }

        this.setState({
            formState: TrailerCleaningFormState.Submitting
        });

        let formElement = formEvent.target as HTMLFormElement;
        let data = new FormData(formElement);

        var result = await ApiRequest('api/zott/trailer-cleaning', data);

        if (result.status === 200) {
            this.setState({
                formState: TrailerCleaningFormState.Saved
            });
        } else {
            var resultData = await result.json();
            this.setState({
                formState: TrailerCleaningFormState.Initial,
                errors: resultData.errors ? resultData.errors : {},
            });
        }
    }
}

export default connect(
    (state:ApplicationState) => state.driverStatus,
        DriverStatus.actionCreators
)(TrailerCleaningForm as any);