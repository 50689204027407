import * as React from 'react';

const defaultPropsValues = {
    isMandatory: false as boolean,
    inline: false as boolean,
    errors: {} as {[key: string]:string[]},
    labels: [] as string[],
    values: [] as string[],
    label: '' as string
};

type FormRadioGroupProps = {
    name: string,
    isMandatory?: boolean
} & Partial<DefaultProps> & React.InputHTMLAttributes<HTMLInputElement>;

type FormRadioGroupState = {
    value: string
}

type LabelValue = {
    label: string,
    value: string
};

type DefaultProps = Readonly<typeof defaultPropsValues>;

// TODO - add possibility to add other button attributes
// input: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
// because of this https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
export default class FormRadioGroup extends React.PureComponent<FormRadioGroupProps, FormRadioGroupState>{
    static defaultProps = defaultPropsValues;

    constructor(props: FormRadioGroupProps) {
        super(props);

        this.state = {
            value: ""
        }
    }

    public render() {
        let {name, inline, defaultValue, isMandatory} = this.props;
        const divClass = "form-check"
        + (inline ? " form-check-inline" : "");

        const labelClasses = [] as string[];
        if (isMandatory)
        {
            labelClasses.push('required');
        }
        if (this.haveErrors())
        {
            labelClasses.push('is-invalid');
        }
        const labelClass = labelClasses.join(' ');

        const labelValues = this.labelValues();
        const form = this;

        return (
            <div className="form-group">
                <div>
                    <label className={labelClass}>{this.props.label}</label>
                    <div className="invalid-feedback">{this.errorText()}</div>
                </div>
                {labelValues.map((lv: LabelValue) => (
                    <div key={name+lv.value} className={divClass}>
                        <input onChange={form.onRadioChange.bind(this)} className="form-check-input" type="radio" name={name} id={name+lv.value} value={lv.value} defaultChecked={lv.value===defaultValue} />
                        <label className="form-check-label" htmlFor={name+lv.value}>{lv.label}</label>
                    </div>
                ))}
            </div>
        );
    }

    private onRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            value: event.currentTarget.value
        });
        console.log("Group changed", event.currentTarget.value);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    private labelValues() : LabelValue[]
    {
        var labels = this.props.labels || [];
        var values = this.props.values || [];
        var labelsCount = labels.length;
        var valuesCount = values.length;

        const labelValues : LabelValue[] = [];

        for(let i = 0; i < valuesCount; i++)
        {
            labelValues.push({
                value: values[i],
                label: i < labelsCount ? labels[i] : ''
            });
        }

        return labelValues;
    }

    private errorText() : string {
        if (!this.props.name)
        {
            return '';
        }
        if (this.props.errors![this.props.name] && this.props.errors![this.props.name][0]) {
            return this.props.errors![this.props.name][0];
        } else {
            return '';
        }
    }

    private haveErrors() : boolean {
        return !!this.props.name && this.props.errors![this.props.name!] != null;
    }
}