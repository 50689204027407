import * as React from 'react';
import { BriefOperationLogEntry, StringDictionary } from '../../types/global';
import SortingTable from '../Common/SortableTable';

type ThisProps = {
    logs: BriefOperationLogEntry[] | undefined
}

type ThisState = {

}

export default class OperationLogTable extends React.PureComponent<ThisProps, ThisState> {
    public render() {
        return <SortingTable disableSorting={true} dataRows={this.props.logs} columns={this.columns()} renderNoData={this.renderNoData} />
    }

    private columns() : StringDictionary {
        const columns = {} as StringDictionary;

        columns['operationDateTimeText'] = 'Data/Czas';
        columns['operationNameText'] = 'Operacja';
        columns['additionalInfo'] = 'Dodatkowe informacje';

        return columns;
    }

    private renderNoData() {
        return (<p>Brak rekordów</p>);
    }
}