import * as React from 'react';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { PanelButtonClasses } from '../../services/styleService';

const FuelingPanel = () => {
    return (
        <div className="container panel">
            <h3>Tankowania</h3>
            <div className="row">
                <ButtonWithRoute className={PanelButtonClasses} path={'/fuelling/save-fueling'}>Nowe tankowanie</ButtonWithRoute>
            </div>
        </div>
    );
}

export default FuelingPanel;
