import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { StringDictionary } from '../../types/global';
import Loader from '../Common/Loader';
import { ShippingEntry } from '../../types/global';
import FormSelect from '../Common/FormSelect';
import moment from 'moment';
import SortableTable from '../Common/SortableTable';
import { ExportCSV } from '../Common/ExportCSVButton';
import * as DaoHelper from '../../helpers/DaoHelper';
import "react-datepicker/dist/react-datepicker.css";
import { FileDateFormat, DateToDateString } from '../../helpers/DateFormatter';
import FormDatePicker from '../Common/FormDatePicker';
import FormGeneralErrors from '../Common/FormGeneralErrors';

interface ShippingsSelectData {
    drivers: StringDictionary;
    tractors: StringDictionary;
}

type ShippingReportData = {
    fromDate: Date,
    toDate: Date,
    driverId: string,
    driverName: string,
    tractorId: string,
    tractorRegNumber: string
}

type ThisFormProps = {

};

type ThisFormState = {
    fromDate: Date,
    toDate: Date,
    shippings: ShippingEntry[] | undefined,
    drivers: StringDictionary,
    tractors: StringDictionary,
    errors: {[key: string]:string[]},
    isLoading: boolean,
    isLoaded: boolean,
    reportFromDate: Date | undefined,
    reportToDate: Date | undefined,
    currentReportData: ShippingReportData | undefined,
    showForm: boolean
}


export default class ShippingsReport extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
            shippings: undefined,
            drivers: {},
            tractors: {},
            errors: {},
            isLoading: false,
            isLoaded: false,
            reportFromDate: undefined,
            reportToDate: undefined,
            currentReportData: undefined,
            showForm: true
        }
    }

    public render() {
        if (this.state.isLoading)
        {
            return <Loader/>;
        }

        return (
            <div className="container">
                <h1>Załadunki</h1>
                <FormGeneralErrors errors={this.state.errors}/>
                {this.state.showForm &&
                <form className="form" onSubmit={this.getReportData.bind(this)}>
                    <FormDatePicker errors={this.state.errors} label="Od" name="fromDate" selected={this.state.fromDate} onChange={this.onFromDateChange.bind(this)}/>
                    <FormDatePicker errors={this.state.errors} label="Do" name="toDate" selected={this.state.toDate} onChange={this.onToDateChange.bind(this)}/>
                    <FormSelect name="driverId" values={this.state.drivers} label="Kierowca" allowPromptSelection={true}/>
                    <FormSelect name="tractorId" values={this.state.tractors} label="Ciągnik" allowPromptSelection={true}/>
                    <div className="form-group">
                        <button type="submit" className="btn btn-outline-secondary btn-sm ml-4">Generuj raport</button>
                    </div>
                </form>
                }
                {!this.state.showForm && <button onClick={() => this.showForm()} className="btn btn-outline-secondary btn-sm ml-4">Kolejny raport</button>}
                {this.hasShippings() && <ExportCSV className="btn btn-outline-secondary btn-sm ml-4" label="Eksportuj do Excela" csvData={this.shippingsForExcel()} fileName={this.reportFileName()}/>}
                {this.hasShippings() && <p>{this.reportTitle()}</p>}
                {this.state.isLoaded && <SortableTable idKey="lp" disableSorting={true} renderNoData={this.renderNoData} dataRows={this.state.shippings} columns={this.shippingsColumns()} />}
            </div>
        );
    }

    public componentDidMount() {
        this.fetchShippingsReportSelectData();
    }

    private showForm() {
        this.setState({
            showForm: true
        });
    }

    private renderNoData() {
        return (
            <p>Brak załadunków</p>
        );
    }

    private reportTitle() : string {
        if (!this.state.currentReportData) return '';

        const reportData = this.state.currentReportData!;

        let title : string = 'Raport załadunków ' + DateToDateString(reportData.fromDate) + '-' + DateToDateString(reportData.toDate);
        if (reportData.driverName) {
            title += ' ' + reportData.driverName;
        }
        if (reportData.tractorRegNumber) {
            title += ' ' + reportData.tractorRegNumber;
        }
        return title;
    }

    private async fetchShippingsReportSelectData() {
        this.setState({
            isLoading: true
        });

        const result = await ApiRequest('api/reports/shippingsselectdata');
        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
            return;
        }

        const selectData = await result.json() as ShippingsSelectData;

        this.setState({
            drivers: selectData.drivers,
            tractors: selectData.tractors
        });
    }

    private async getReportData(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        let formElement = formEvent.target as HTMLFormElement;

        let data = new FormData(formElement);

        var driverId = data.get('driverId') as string;
        var tractorId = data.get('tractorId') as string;

        if (driverId === '0') driverId = '';
        if (tractorId === '0') tractorId = '';

        var reportData : ShippingReportData = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            driverId: driverId,
            driverName: driverId ? this.state.drivers[driverId] : '',
            tractorId: tractorId,
            tractorRegNumber: tractorId ? this.state.tractors[tractorId] : ''
        };

        var result = await ApiRequest('api/reports/shippings', data);

        if (result.status !== 200) {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
            return;
        }

        const shippingRows = await DaoHelper.JsonParseResult(result) as ShippingEntry[];

        DaoHelper.EnhanceShippingsCollection(shippingRows);

        var showForm = !shippingRows || !shippingRows.length;

        this.setState({
            errors: {},
            shippings: shippingRows,
            isLoaded: true,
            currentReportData: reportData,
            showForm: showForm
        });
    }

    private shippingsColumns() {
        const columns = {} as StringDictionary;

        columns['lp'] = 'L.p.';
        columns['shippingOperationDateText'] = 'Data załadunku';
        columns['startLoadingTimeText'] = 'Godz. rozpoczęcia';
        columns['endLoadingTimeText'] = 'Godz. zakończenia';
        columns['trailerNumber'] = 'Naczepa';
        columns['wz'] = 'WZ';
        columns['tractorRegNumber'] = 'Ciągnik';
        columns['driverName'] = 'Kierowca';
        columns['numberOfPallets'] = 'Liczba palet';

        return columns;
    }

    private onFromDateChange(date: Date) {
        this.setState({
            fromDate: date
        });
    }

    private onToDateChange(date: Date) {
        this.setState({
            toDate: date
        });
    }

    private shippingsForExcel()
    {
        return this.state.shippings!.map(this.shippingToExcel);
    }

    private shippingToExcel(se: ShippingEntry)
    {
        return {
            'Lp': se.lp,
            'Data załadunku': se.shippingOperationDateText,
            'Godz. rozpoczęcia': se.startLoadingTimeText,
            'Godz. zakończenia': se.endLoadingTimeText,
            'Naczepa': se.trailerNumber,
            'WZ': se.wz,
            'Ciągnik': se.tractorRegNumber,
            'Kierowca': se.driverName
        };
    }

    private reportFileName() : string
    {
        var reportData = this.state.currentReportData;
        if (!reportData) return '';

        let fileName : string = 'RaportRozładunków_' + moment(reportData!.fromDate).format(FileDateFormat) + '_' + moment(reportData!.toDate).format(FileDateFormat);

        if (reportData!.driverName) fileName += '_' + reportData!.driverName;
        if (reportData!.tractorRegNumber) fileName += '_' + reportData!.tractorRegNumber;

        return fileName;
    }

    private hasShippings() : boolean
    {
        return !!this.state.shippings
            && this.state.shippings.length > 0;
    }
}