import * as React from 'react';
import { ZottTrailerStatus } from '../../types/global';
import TrailerStatusInfo from './TrailerStatusInfo';
import TrailerLoadingOperations from './TrailerLoadingOperations';
import TrailerMaintenanceOperations from './TrailerMaintenanceOperations';

type ZottPanelTrailerOperationsProps = {
    trailerStatus: ZottTrailerStatus | undefined;
    operationsCanPerform: string[] | undefined;
}

type ZottPanelTrailerOperationsState = {

}

export default class ZottPanelTrailerOperations extends React.PureComponent<ZottPanelTrailerOperationsProps, ZottPanelTrailerOperationsState> {
    public render() {
        if (!this.props.trailerStatus) {
            return null;
        }

        return (
            <div>
                <hr/>
                <TrailerStatusInfo trailerStatus={this.props.trailerStatus} showLocation={false}/>
                <TrailerLoadingOperations operationsCanPerform={this.props.operationsCanPerform} />
                <hr/>
                <TrailerMaintenanceOperations operationsCanPerform={this.props.operationsCanPerform} trailerStatus={this.props.trailerStatus}/>
            </div>
        );
    }
}