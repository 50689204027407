import * as React from 'react';
import * as UserTypesStore from '../../store/UserTypes';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';

type UserTypeSelectProps = UserTypeSelectOwnProps
    & typeof UserTypesStore.actionCreators
    & UserTypesStore.UserTypesState;

type UserTypeSelectOwnProps = {
    errors: {[key: string]:string[]}
} & Partial<DefaultProps>;

const defaultPropsValues = {
    defaultValue: 0 as number,
    name: "userTypeId" as string
};

type DefaultProps = Readonly<typeof defaultPropsValues>;

class UserTypeSelect extends React.PureComponent<UserTypeSelectProps> {
    static defaultProps = defaultPropsValues;

    public componentDidMount() {
        this.props.requestUserTypes(false);
    }

    public render() {
        return(
            <div className="form-group">
                <label htmlFor={this.props.name}>Typ użytkownika</label>
                <select className={"custom-select" + (this.haveErrors() ? " is-invalid" : "")} defaultValue={this.props.defaultValue!.toString()} name={this.props.name} id={this.props.name}>
                    <option disabled key="0" value="0">Wybierz typ użytkownika</option>
                    {this.props.userTypes.map((userType: UserTypesStore.UserType) =>
                        <option key={userType.id} value={userType.id}>
                            {userType.name}
                        </option>
                    )}
                </select>
                <div className="invalid-feedback">{this.errorText()}</div>
            </div>
        );
    }

    private errorText() : string {
        if (this.props.errors[this.props.name!] && this.props.errors[this.props.name!][0]) {
            return this.props.errors[this.props.name!][0];
        } else {
            return '';
        }
    }

    private haveErrors() : boolean {
        return this.props.errors[this.props.name!] != null;
    }
}

export default connect<UserTypesStore.UserTypesState | undefined, typeof UserTypesStore.actionCreators, UserTypeSelectOwnProps, ApplicationState>(
    (state: ApplicationState) => state.userTypes, // Selects which state properties are merged into the component's props
    UserTypesStore.actionCreators
)(UserTypeSelect as any);
