import { StringDictionary } from "../types/global";
import moment from 'moment';

interface ErrorTextProps {
    name?: string,
    errors?: {[key: string]:string[]}
}

export function errorText(props: ErrorTextProps) : string {
    if (!props.name || !props.errors)
    {
        return '';
    }
    if (props.errors![props.name] && props.errors![props.name][0]) {
        return props.errors![props.name][0];
    } else {
        return '';
    }
}

export function haveErrors(props: ErrorTextProps) : boolean {
    return !!props.errors && !!props.name && props.errors[props.name] != null;
}

export function buildMonthsDictionary(oldestDateString: string) : StringDictionary
{
    const oldestDate = oldestDateString ? new Date(oldestDateString) : new Date();
    const oldestDateMonth = moment(new Date(oldestDate.getFullYear(), oldestDate.getMonth(), 1));

    const currentDate = new Date();
    let dateMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));

    const maxMonths = 30;
    let monthsCount = 0;

    const resultMonths = {} as StringDictionary;

    while(dateMonth >= oldestDateMonth && monthsCount <= maxMonths)
    {
        resultMonths[dateMonth.format("MM/DD/YYYY")] = dateMonth.format("YYYY.MM");
        monthsCount++;
        dateMonth = dateMonth.subtract(1, "months");
    }

    return resultMonths;
}
