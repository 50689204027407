import * as React from 'react';
import ZottForm from './ZottForm';
import { ZottTrailerStatus } from '../../types/global';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as DriverStatus from '../../store/DriverStatus';
import UnhitchTrailerFields from './UnhitchTrailerFields';
import HitchTrailerFields from './HitchTrailerFields';

type ThisFormProps = DriverStatus.ZottPanelDriverStatusStore
    & typeof DriverStatus.actionCreators;

class ReHitchTrailerForm extends React.PureComponent<ThisFormProps, {}> {

    public render() {
        return (
            <ZottForm initFormError={this.props.fetchTrailersError} operationName="ReHitchTrailer" operationTitle="Przepinka" operationButtonText="Przepnij naczepę">
                <UnhitchTrailerFields currentTrailer={this.currentTrailer()} />
                <HitchTrailerFields trailersStates={this.props.trailersToHitchStates} />
            </ZottForm>
        );
    }

    public componentDidMount() {
        this.props.requestTrailersToHitch();
    }

    private currentTrailer() : ZottTrailerStatus | undefined {
        return this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.trailerStatus;
    }
}

export default connect(
    (state:ApplicationState) => state.driverStatus,
    DriverStatus.actionCreators
)(ReHitchTrailerForm as any);
