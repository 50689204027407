import * as React from 'react';
import { StringDictionary } from '../../types/global';

export type ColumnOptions = {
    precision?: number;         // If value is float this is a precission
    boolTrueText?: string;      // If value is boolean type this is text for true value
    boolFalseText?: string;     // If value is boolean type this is text for false value
};

export interface ColumnOptionsCollection {
    [key:string] : ColumnOptions
}

const defaultPropsValues = {
    idKey: 'id' as string,
    renderActions: undefined as ((row: any) => JSX.Element) | undefined,
    renderNoData: undefined as (() => JSX.Element) | undefined,
    disableSorting: false as boolean
};

type DefaultProps = Readonly<typeof defaultPropsValues>;

type SortableTableProps = {
    dataRows : any[] | undefined;
    columns: StringDictionary;
    columnOptions?: ColumnOptionsCollection;
} & Partial<DefaultProps>;

type SortableTableState = {
    sortParameter: string,
    sortOrder: boolean      // false - ascending, true - descending
}

export default class SortableTable extends React.PureComponent<SortableTableProps, SortableTableState>{
    static defaultProps = defaultPropsValues;

    constructor(props: SortableTableProps) {
        super(props);

        this.state = {
            sortParameter: '',
            sortOrder: false
        }
    }

    public render()
    {
        if (!this.props.dataRows || this.props.dataRows.length === 0) {
            return this.props.renderNoData ?
                this.props.renderNoData() :
                null;
        }

        var columnKeys = Object.keys(this.props.columns);

        return(
            <table className='table table-striped'>
                <thead>
                    <tr>
                        {columnKeys.map((key) => {
                            if (key === 'actions' || this.props.disableSorting) {
                                return (<th key={'th_'+key}>{this.props.columns[key]}</th>);
                            } else {
                                return this.sortableHeader(this.props.columns[key], key);
                            };
                        })}
                    </tr>
                </thead>
                <tbody>
                    {this.sortedRows()!.map((row: any) => {
                        const id = row[this.props.idKey || 'id'];
                        return (
                            <tr key={id}>
                                {columnKeys.map((key) => {
                                    if (key === 'actions') {
                                        return (
                                            <td key={id+key} className="actions">
                                                {this.props.renderActions && this.props.renderActions(row)}
                                            </td>
                                        );
                                    } else {
                                        return (<td key={id+key}>{this.returnRenderValue(row, key)}</td>);
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    private sortClass(paramName: string)
    {
        const classes : string[] = ['sortable'];

        if (this.state.sortParameter === paramName) {
            classes.push(this.state.sortOrder ? 'asc' : 'desc');
        }
        return classes.join(' ');
    }

    private sortBy(paramName: string)
    {
        if (this.state.sortParameter === paramName)
        {
            this.setState({
                sortOrder: !this.state.sortOrder
            });
        } else {
            this.setState({
                sortOrder: false,
                sortParameter: paramName
            });
        }
    }

    private returnRenderValue(row: any, key: string) {
        if (key.endsWith('Date')) {
            return row[key + 'Text'] || row[key + 'TimeText'] || '';
        }
        
        const valueType = typeof row[key];
        const valueOptions = this.props.columnOptions && this.props.columnOptions![key];
        const value = row[key];

        if (valueType === 'boolean') {
            if (valueOptions) {
                if (valueOptions!.boolTrueText && value) {
                    return valueOptions!.boolTrueText;
                }
                if (valueOptions!.boolFalseText && !value) {
                    return valueOptions!.boolFalseText;
                }
            }
            return row[key] ? (<span className="fa fa-check"></span>) : null;
        }

        if (valueType === 'number') {
            if (valueOptions) {
                if (valueOptions!.precision) {
                    return value.toFixed(valueOptions!.precision);
                }
            }
        }

        return value;
    }

    private compare(a: any, b : any) : number
    {
        const name = this.state.sortParameter;

        if (a[name] === b[name]) return 0;
        if (a[name] < b[name]) {
            return this.state.sortOrder ? 1 : -1;
        } else {
            return this.state.sortOrder ? -1 : 1;
        }
    }

    private sortedRows() : any[] | undefined
    {
        if (!this.props.dataRows) {
            return undefined;
        }

        if (this.state.sortParameter === '') {
            return this.props.dataRows!;
        }

        return this.props.dataRows!.sort(this.compare.bind(this));
    }

    private sortableHeader(label: string, paramName: string)
    {
        return (
            <th key={'th_' + paramName} className={this.sortClass(paramName)} onClick={() => this.sortBy(paramName)}>{label}</th>
        );
    }
}