import * as React from 'react';
import ZottForm from './ZottForm';

// TODO - Obsolete, delete if not used
export default class ParkingForm extends React.PureComponent {
    public render() {
        return(
            <ZottForm operationName="Parking" operationTitle="Parking" operationButtonText="Parking" />
        );
    }
}