import * as React from 'react';
import { StringDictionary, TransportingDriversDTO } from '../../types/global';
import Loader from '../Common/Loader';
import FormSelect from '../Common/FormSelect';
import { ApiRequest } from '../../services/apiClient';
import * as DaoHelper from '../../helpers/DaoHelper';
import { buildMonthsDictionary } from '../../helpers/FormHelper';
import { ExportCSV } from '../Common/ExportCSVButton';
import { DateToFormDateString } from '../../helpers/DateFormatter';

type ThisFormProps = {};

type ThisFormState = {
    months: StringDictionary,
    error: string,
    isLoading: boolean,
    isLoaded: boolean,
    monthsSelectKey: string,
    isMonthsSelectSet: boolean,
    selectedDate: string,
    drivers: string[],
    startDate?: Date,
    endDate?: Date
};

export default class FuelingsReport extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            months: {},
            error: '',
            isLoading: false,
            isLoaded: false,
            monthsSelectKey: 'monthsSelectKey',
            isMonthsSelectSet: false,
            selectedDate: '',
            drivers: []
        }

        this.driversForExcel = this.driversForExcel.bind(this);
        this.hasEntries = this.hasEntries.bind(this);
        this.fetchDrivers = this.fetchDrivers.bind(this);
        this.reportFileName = this.reportFileName.bind(this);
    }

    public componentDidMount() {
        this.fetchDrivers(undefined);
    }

    private async fetchDrivers(monthString?: string)
    {
        this.setState({
            isLoading: true
        });

        const formData = new FormData();

        if (monthString) {
            formData.set('monthDateTime', monthString);
        }

        const result = await ApiRequest('api/reports/transporting-drivers', formData);

        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            this.setState({
                error: 'Bład pobierania kierowców'
            });
            return;
        }

        const transportingDriversMonthly = await DaoHelper.JsonParseResult(result) as TransportingDriversDTO;

        if (!this.state.isMonthsSelectSet) {
            const monthsDictionary = buildMonthsDictionary(transportingDriversMonthly.oldestDate);

            console.log('Months dictionary', monthsDictionary);

            this.setState({
                months: monthsDictionary,
                monthsSelectKey: 'monthsSelectKey' + Date.now(),
                isMonthsSelectSet: true
            });
        }

        this.setState({
            isLoaded: true,
            drivers: transportingDriversMonthly.drivers,
            startDate: new Date(transportingDriversMonthly.startDate),
            endDate: new Date(transportingDriversMonthly.endDate)
        });
    }
    
    private onMonthSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;

        this.setState({
            selectedDate: value
        });

        this.fetchDrivers(value);
    }

    private hasEntries() {
        return this.state.drivers.length > 0;
    }

    private driversForExcel() {
        const drivers = this.state.drivers.map((driver, idx) => {
            return {
                'Idx': idx + 1,
                'Kierowca': driver
            } as any;
        });

        return drivers;
    }

    private reportFileName() {
        return 'Transportujacy_Kierowcy_' + DateToFormDateString(this.state.startDate!) + "-" + DateToFormDateString(this.state.endDate!);
    }

    public render() {
        if (this.state.isLoading)
        {
            return <Loader/>;
        }
        if (!this.state.isLoaded)
        {
            return null;
        }
        return (
            <div className="container">
                <div className="row">
                    <h3>Kierowcy w transportach - Raport Miesięczny {this.state.startDate && (<span>{DateToFormDateString(this.state.startDate)}-{DateToFormDateString(this.state.endDate!)}</span>)}</h3>
                </div>
                <div className="row align-items-center">
                    <div className="col">
                        <FormSelect onChange={this.onMonthSelected.bind(this)} key={this.state.monthsSelectKey} label="Miesiąc" values={this.state.months} defaultValue={this.state.selectedDate}></FormSelect>
                    </div>
                    <div className="col">
                        {this.hasEntries() && <ExportCSV className="btn btn-outline-secondary btn-sm ml-4" label="Eksportuj do Excela" csvData={this.driversForExcel()} fileName={this.reportFileName()}/>}
                    </div>
                </div>
                {this.hasEntries() && <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Kierowca</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.drivers.map((driver, idx) => <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{driver}</td>
                        </tr>)}
                    </tbody>
                </table>}
            </div>
        );
    }
}