import * as AuthService from './authService';
import * as Authorization from '../store/Authorization';

let reduxStore : any;

export function ApiRequest(url:string, data:FormData|null = null):Promise<Response>
{
    const promise = fetch(url, {
        method: 'POST',
        body: data,
        headers: AuthService.AuthHeader()
    });

    promise.then(
        response => {
            if (response.status === 401) {
                reduxStore.dispatch(Authorization.actionCreators.logout());
            }
        }
    );

    return promise;
}

export function SetStore(store:any) {
    reduxStore = store;
}