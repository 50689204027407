import * as React from 'react';
import ZottForm from './ZottForm';
import FormTextField from '../Common/FormTextField';

export default class UnloadAtRampForm extends React.PureComponent {
    public render() {
        return(
            <ZottForm operationName="UnloadAtRamp" operationTitle="Ustaw na rampie" operationButtonText="Ustaw">
                <FormTextField type="number" label="Numer rampy" name="rampNumber" isMandatory={true}/>
            </ZottForm>
        );
    }
}