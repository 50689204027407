import * as React from 'react';

type ThisProps = {
    defaultValues?: any[],
    commonData?: any,
    label?: string,
    id?: string,
    className?: string,
    renderItem: ((value: any,
        index: number,
        values: any[],
        commonData: any,
        errors?: {[key: string]:string[]},
        onChangeCallback?: (value:any) => void) => JSX.Element),
    getNewItemValue: ((index: number, values: any[], commonData: any) => any),
    minItems?: number,
    errors?: {[key: string]:string[]},
}

type ThisState = {
    values: any[]
}

export default class RepeatableWithErrors extends React.PureComponent<ThisProps, ThisState>
{
    constructor(props: ThisProps) {
        super(props);

        this.state = {
            values: props.defaultValues || []
        }
    }

    public render()
    {
        var repeatable = this;
        var className = "repeatable";
        var dateNow = Date.now();
        var baseItemsKey = this.props.id || "repeatable";
        var values = this.state.values;
        const renderRemoveButtons = !this.props.minItems || values.length > this.props.minItems;

        if (this.props.className) {
            className += " " + this.props.className;
        }

        return(
            <div id={this.props.id} className={className}>
                {this.props.label && <p>{this.props.label}</p>}
                {
                    values.map((value, index, values) => 
                        (
                            <div className="itemContainer"  key={baseItemsKey + index + dateNow}>
                                {this.props.renderItem(value, index, values, this.props.commonData, this.getItemErrors(index), (value) => this.onValueChange(value, index))}
                                {renderRemoveButtons && <div className="fa fa-times removeButton" onClick={() => repeatable.removeItem(index)} />}
                            </div>
                        )
                    )
                }
                <div className="addItem fa fa-plus-circle" onClick={this.onAdd.bind(this)} />
            </div>
        );
    }

    private onValueChange(value: any, index: number) {
        var values = this.state.values;
        if (values.length <= index) {
            return;
        }

        values[index] = value;

        this.setState({
            values
        });
    }

    private getItemErrors(index: number)
    {
        if (!this.props.errors) {
            return undefined;
        }
        const indexSuffix = index.toString();
        const indexSuffixLength = indexSuffix.length;

        const errors = this.props.errors!;
        const itemErrors = {} as {[key: string]:string[]};

        Object.keys(this.props.errors).forEach((errorKey) => {
            if (errorKey.endsWith(indexSuffix)) {
                const destErrorKey = errorKey.substr(0, errorKey.length - indexSuffixLength);
                itemErrors[destErrorKey] = errors[errorKey];
            }
        });

        return itemErrors;
    }

    private onAdd()
    {
        var newItemValue = this.props.getNewItemValue(this.state.values.length, this.state.values, this.props.commonData);

        var values = this.state.values;
        values.push(newItemValue);

        this.setState({
            values: values
        });

        // Don't know why but it doesn't want to update
        this.forceUpdate();
    }

    private removeItem(index: number)
    {
        var values = this.state.values;

        values.splice(index, 1);

        this.setState({
            values: values
        });

        // Don't know why but it doesn't want to update
        this.forceUpdate();
    }
}
