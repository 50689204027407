import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import ZottForm from './ZottForm';
import FormTextField from '../Common/FormTextField';
import { ZottLocationEnum } from '../../types/global';
import * as DriverStatus from '../../store/DriverStatus';

type EndLoadingFormProps = DriverStatus.ZottPanelDriverStatusStore
    & typeof DriverStatus.actionCreators;

type EndLoadingFormState = {};

// [PALLETS_NUMBER REMOVE] - Remove numberOfPallets field and consequences

class EndLoadingForm extends React.PureComponent<EndLoadingFormProps, EndLoadingFormState> {
    public render() {
        return(
            <ZottForm operationName="EndLoading" operationTitle="Zakończenie ładowania" operationButtonText="Zakończ ładowanie">
                {this.isDriverInZott() && <FormTextField type="number" label="Liczba palet" name="numberOfPallets"/>}
                <FormTextField type="number" isMandatory={true} name="wz" label="WZ" />
            </ZottForm>
        );
    }

    public componentDidMount() {
        this.props.requestDriverStatus();
    }

    private isDriverInZott() : boolean {
        if (this.props.zottPanelDriverStatus)
        {
            console.log('isDriverInZott', this.props.zottPanelDriverStatus.driverStatus);
            return this.props.zottPanelDriverStatus.driverStatus.zottLocationEnum === ZottLocationEnum.Zott;
        }
        return false;
    }
}

export default connect(
    (state: ApplicationState) => state.driverStatus,
    DriverStatus.actionCreators
)(EndLoadingForm as any);