import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { TrailerCleaningStatusEntry, DataLoadingStateEnum, StringDictionary } from '../../types/global';
import { ApiRequest } from '../../services/apiClient';
import Loader from '../Common/Loader';
import { Alert } from 'reactstrap';
import SortingTable from '../Common/SortableTable';
import * as DaoHelper from '../../helpers/DaoHelper';

type ThisComponentProps = RouteComponentProps<{ trailerId: string }>;

type ThisComponentState = {
    trailerId: number,
    dataLoadingState: DataLoadingStateEnum,
    trailerCleaningLogs: TrailerCleaningStatusEntry[],
    error: string
};

export default class TrailerCleaningLog extends React.PureComponent<ThisComponentProps, ThisComponentState> {
    constructor(props: ThisComponentProps) {
        super(props);

        const id = parseInt(this.props.match.params.trailerId) || 0;
        this.state = {
            trailerId: id,
            dataLoadingState: DataLoadingStateEnum.Initial,
            trailerCleaningLogs: [],
            error: ''
        }
    }

    public render() {
        if (this.state.dataLoadingState === DataLoadingStateEnum.Loading) {
            return <Loader/>;
        }
        if (this.state.dataLoadingState !== DataLoadingStateEnum.Loaded) {
            return null;
        }
        return (
            <div>
                <div className="row">
                    <h3>Historia mycia naczepy {this.trailerNumber()}</h3>
                </div>
                {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                <SortingTable disableSorting={true} dataRows={this.state.trailerCleaningLogs} columns={this.columns()} renderNoData={this.renderNoData} />
            </div>
        );
    }

    public componentDidMount() {
        this.fetchTrailerCleaningLogs();
    }

    private async fetchTrailerCleaningLogs() {
        this.setState({
            dataLoadingState: DataLoadingStateEnum.Loading
        });

        const formData = new FormData();
        formData.set('trailerId', this.state.trailerId.toString());

        const response = await ApiRequest('api/zott/trailer-cleaning-log', formData);

        if (response.status !== 200) {
            this.setState({
                dataLoadingState: DataLoadingStateEnum.Loaded,
                error: 'Bład wczytywania historii mycia naczepy'
            });
            return;
        }

        const logs = await response.json() as TrailerCleaningStatusEntry[];

        DaoHelper.EnhanceTrailerCleaningsCollection(logs);

        this.setState({
            dataLoadingState: DataLoadingStateEnum.Loaded,
            trailerCleaningLogs: logs
        });
    }

    private columns() : StringDictionary {
        const columns = {} as StringDictionary;

        columns['lastCleanedDate'] = 'Data mycia';
        columns['cleaningDriverName'] = 'Kierowca';
        columns['tractorRegistrationNumber'] = 'Ciągnik';

        return columns;
    }

    private trailerNumber() : string {
        if (this.state.dataLoadingState === DataLoadingStateEnum.Loaded
            && this.state.trailerCleaningLogs
            && this.state.trailerCleaningLogs.length > 0) {
            return this.state.trailerCleaningLogs[0].trailerNumber;
        }
        return '';
    }

    private renderNoData() {
        return (<p>Brak rekordów</p>);
    }
}
