import moment from 'moment';

export const FileDateFormat = 'DD_MM_YYYY';
export const FormDateFormat = 'yyyy-MM-DD';
const DateFormat = 'DD/MM/YYYY';
const TimeFormat = 'HH:mm';
const ExcelDateTimeFormat = DateFormat + ' ' + TimeFormat;
const DateTimeFormat = DateFormat + ' ' + TimeFormat;

export function DateToExcelDateTimeString(date: Date | null) : string {
    if (!date) return '';
    return moment(date).format(ExcelDateTimeFormat);
}

export function DateToDateTimeString(date: Date) : string {
    return moment(date).format(DateTimeFormat);
}

export function DateToDateString(date: Date) : string {
    return moment(date).format(DateFormat);
}

export function DateToTimeString(date: Date) : string {
    return moment(date).format(TimeFormat);
}

export function DateToFormDateString(date: Date) : string {
    return moment(date).format(FormDateFormat);
}