import * as React from 'react';

type ThisProps = {
    defaultValues?: any[],
    commonData?: any,
    label?: string,
    id?: string,
    className?: string,
    renderItem: ((value: any, index: number, values: any[], commonData: any) => JSX.Element),
    getNewItemValue: ((index: number, values: any[], commonData: any) => any),
    minItems?: number,
}

type ThisState = {
    values: any[],
    itemKeys: string[]
}

export default class Repeatable extends React.PureComponent<ThisProps, ThisState>
{
    constructor(props: ThisProps) {
        super(props);

        const values = props.defaultValues || [];

        this.state = {
            values, 
            itemKeys: this.generateItemsKeys(values.length)
        }
    }

    public render()
    {
        var repeatable = this;
        var className = "repeatable";
        var values = this.state.values;
        const renderRemoveButtons = !this.props.minItems || values.length > this.props.minItems;

        if (this.props.className) {
            className += " " + this.props.className;
        }

        return(
            <div id={this.props.id} className={className}>
                {this.props.label && <p>{this.props.label}</p>}
                {
                    values.map((value, index, values) => 
                        (
                            <div className="itemContainer" key={this.state.itemKeys[index]}>
                                {this.props.renderItem(value, index, values, this.props.commonData)}
                                {renderRemoveButtons && <div className="fa fa-times removeButton" onClick={() => repeatable.removeItem(index)} />}
                            </div>
                        )
                    )
                }
                <div className="addItem fa fa-plus-circle" onClick={this.onAdd.bind(this)} />
            </div>
        );
    }

    private onAdd()
    {
        var newItemValue = this.props.getNewItemValue(this.state.values.length, this.state.values, this.props.commonData);
        var baseItemsKey = this.props.id || "repeatable";
        var dateNow = Date.now();
        var newItemKey = baseItemsKey + this.state.values.length + dateNow;

        this.setState({
            values: [...this.state.values, newItemValue],
            itemKeys: [...this.state.itemKeys, newItemKey]
        });
    }

    private removeItem(index: number)
    {
        this.setState({
            values: this.state.values.filter((item, itemIndex) => itemIndex !== index),
            itemKeys: this.state.itemKeys.filter((item, itemIndex) => itemIndex !== index)
        });
    }

    private generateItemsKeys(length: number) : string[]
    {
        const keys = [];
        var baseItemsKey = this.props.id || "repeatable";
        var dateNow = Date.now();

        for(let index = 0; index < length; index++)
        {
            keys.push(baseItemsKey + index + dateNow);
        }

        return keys;
    }
}
