import * as React from 'react';
import { LocationToGo } from '../../types/global';
import ButtonWithRoute from '../Common/ButtonWithRoute';

type ThisFormProps = {
    locationsToGo: LocationToGo[] | undefined;
    operationsCanPerform: string[] | undefined;
} & Partial<{location: string}>;

export default class DriverLocationOperations extends React.PureComponent<ThisFormProps, {}> {
    public render() {
        const panelButtonClasses = "col-sm btn btn-lg btn-panel";

        return (
            <div>
                <hr/>
                {this.props.location && <p><span className="strong">Lokacja: </span>{this.props.location}</p>}
                {this.canPerform("GoToLocation") &&
                <div className="row">
                    {this.props.locationsToGo && this.props.locationsToGo.map((ltg: LocationToGo) => 
                        <ButtonWithRoute key={'ltg'+ltg.locationEnum+ltg.locationPointId} className={panelButtonClasses} path={`/zott/gotolocation?id=${ltg.locationPointId}&l=${ltg.locationEnum}&location-title=${ltg.locationTitle}`}>{ltg.locationTitle}</ButtonWithRoute>
                    )}
                </div>}
            </div>
        );
    }

    private canPerform(operationName:string)
    {
        if (!this.props.operationsCanPerform)
        {
            return false;
        }

        return this.props.operationsCanPerform.indexOf(operationName) >= 0;
    }
}