import * as React from 'react'
import { Redirect } from 'react-router';

type ButtonWithRouteProps = {
    path: string
} & React.ButtonHTMLAttributes<HTMLButtonElement> & Partial<{clicked: boolean}>;

type ButtonWithRouteState = {
    clicked: boolean;
}

export default class ButtonWithRoute extends React.Component<ButtonWithRouteProps, ButtonWithRouteState> {
    constructor(props: ButtonWithRouteProps) {
        super(props);
        this.state = {
            clicked: false
        };
    }

    render() {
        let {path, clicked, onClick, ...buttonProps} = this.props;

        if (onClick === undefined) {
            onClick = () => {this.setState({clicked: true})}
        }

        if (this.state.clicked || this.props.clicked) {
            return <Redirect to={this.props.path} />;
        }
        return <button {...buttonProps} onClick={onClick}>{this.props.children}</button>;
    }
}
