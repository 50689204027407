import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import Loader from '../Common/Loader';
import { StringDictionary } from '../../types/global';
import { PalletsUpdateEntry } from '../../types/global';
import FormGeneralErrors from '../Common/FormGeneralErrors';
import FormDatePicker from '../Common/FormDatePicker';
import moment from 'moment';
import SortableTable from '../Common/SortableTable';
import * as DaoHelper from '../../helpers/DaoHelper';
import { DateToFormDateString } from '../../helpers/DateFormatter';

type ReportData = {
    fromDate: Date,
    toDate: Date,
};

type ThisComponentProps = {
    isInternational?: boolean
};

type ThisComponentState = {
    fromDate: Date,
    toDate: Date,
    isLoading: boolean,
    isLoaded: boolean,
    errors: { [key: string]: string[] },
    showForm: boolean,
    entries: PalletsUpdateEntry[] | undefined,
    currentReportData: ReportData | undefined,
};

export default class PalletsNumberUpdatesReport extends React.PureComponent<ThisComponentProps, ThisComponentState> {
    constructor(props: ThisComponentProps) {
        super(props);

        const now = new Date();

        this.state = {
            fromDate: now,
            toDate: now,
            isLoading: false,
            isLoaded: false,
            errors: {},
            showForm: false,
            entries: [],
            currentReportData: undefined
        }
    }

    public render() {
        if (this.state.isLoading) {
            return <Loader/>;
        }

        const title = this.props.isInternational === true
            ? 'Raport aktualizacji palet międzynarodowych'
            : 'Raport aktualizacji palet';

        return (
            <div className="container">
                <div className="row">
                    <h3>{title}</h3>
                </div>
                <FormGeneralErrors errors={this.state.errors} />
                {this.state.showForm &&
                    <form className="form" onSubmit={this.fetchEntries.bind(this)}>
                        <FormDatePicker errors={this.state.errors} label="Od" name="fromDate" selected={this.state.fromDate} onChange={this.onFromDateChange.bind(this)} />
                        <FormDatePicker errors={this.state.errors} label="Do" name="toDate" selected={this.state.toDate} onChange={this.onToDateChange.bind(this)} />
                        <div className="form-group">
                            <button type="submit" className="btn btn-outline-secondary btn-sm ml-4">Generuj raport</button>
                        </div>
                    </form>
                }
                {this.state.isLoaded &&
                    <div className="row align-items-center">
                        <div className="col">
                            <span>Raport z {this.dateRangeTitle()} </span>
                            {!this.state.showForm && <button onClick={() => this.showForm()} className="btn btn-outline-secondary btn-sm ml-4">Kolejny raport</button>}
                        </div>
                    </div>}
                {this.state.isLoaded && <SortableTable dataRows={this.state.entries} columns={this.columns()} renderNoData={this.renderNoData} disableSorting={true}/>}
            </div>
        );
    }

    public componentDidMount() {

        const formData = new FormData();

        formData.set('fromDate', DateToFormDateString(this.state.fromDate));
        formData.set('toDate', DateToFormDateString(this.state.toDate));

        this.fetchEntriesWithFormData(formData);
    }

    private async fetchEntriesWithFormData(formData: FormData) {

        var reportData: ReportData = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        };

        const url = this.props.isInternational === true
            ? 'api/reports/international-pallets-updates'
            : 'api/reports/pallets-updates';

        const result = await ApiRequest(url, formData);

        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({ errors: resultData.errors });
            }
            return;
        }

        const entries = await DaoHelper.JsonParseResult(result) as PalletsUpdateEntry[];
        DaoHelper.EnhancePalletsUpdateEntriesCollection(entries);

        this.setState({
            currentReportData: reportData,
            entries,
            isLoaded: true,
            showForm: false
        });
    }

    private async fetchEntries(formEvent: React.FormEvent<HTMLFormElement>) {
        this.setState({
            isLoading: true
        });

        formEvent.preventDefault();

        var formElement = formEvent.target as HTMLFormElement;
        const formData = new FormData(formElement);

        await this.fetchEntriesWithFormData(formData);
    }

    private columns(): StringDictionary {
        const columns = {} as StringDictionary;

        columns['dateTimeText'] = 'Data i godzina';
        columns['palletsNumber'] = 'Liczba palet';
        columns['userFullName'] = 'Uzytkownik';

        return columns;
    }

    private dateRangeTitle(): string {
        const reportData = this.state.currentReportData;
        if (!reportData) {
            return '';
        }

        return moment(reportData.fromDate).format('DD/MM') + ' - ' + moment(reportData.toDate).format('DD/MM');
    }

    private onFromDateChange(date: Date) {
        this.setState({
            fromDate: date
        });
    }

    private onToDateChange(date: Date) {
        this.setState({
            toDate: date
        });
    }

    private showForm() {
        this.setState({
            showForm: true
        });
    }

    private renderNoData() {
        return (<p>Brak rekordów</p>);
    }
}