import * as React from 'react';
import { connect } from 'react-redux';
import ZottForm from './ZottForm';
import FormSelect from '../Common/FormSelect';
import { StringDictionary, StartWorkLocationsStringDictionary } from '../../types/global';
import { ApiRequest } from '../../services/apiClient';
import * as DriverStatus from '../../store/DriverStatus';
import { ApplicationState } from '../../store';

type ThisFormProps = DriverStatus.ZottPanelDriverStatusStore & {

} ;

type ThisFormState = {
    tractors: StringDictionary,
    initFormError: string
};

class StartWorkForm extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            tractors: {},
            initFormError: ''
        };
    }

    public render() {
        return (
            <ZottForm initFormError={this.state.initFormError} operationName="StartWork" operationTitle="Rozpocznij pracę" operationButtonText="Rozpocznij pracę">
                <FormSelect values={this.state.tractors} label="Ciągnik" name="tractorId"></FormSelect>
                <FormSelect values={StartWorkLocationsStringDictionary} label="Lokacja" name="locationId" defaultValue={this.driverLocation().toString()}></FormSelect>
            </ZottForm>
        );
    }

    public componentDidMount() {

        this.fetchTractors();
    }

    private driverLocation() : number {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus) {
                return this.props.zottPanelDriverStatus.driverStatus.zottLocationEnum
        }
        return 0;
    }

    private async fetchTractors()
    {
        const response = await ApiRequest('api/zott/getstartworktractors');

        if (response.status !== 200) {
            this.setState({
                initFormError: 'Błąd pobierania ciągników'
            });
        }

        const tractors = await response.json() as StringDictionary;
        if (Object.keys(tractors).length === 0) {
            this.setState({
                initFormError: 'Brak zdefiniowanych ciągników'
            });
        } else {
            this.setState({
                tractors: tractors
            });
        }
    }
}

export default connect(
    (state: ApplicationState) => state.driverStatus,
    null
)(StartWorkForm as any);