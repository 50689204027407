import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ApiRequest } from '../../services/apiClient';
import * as DriverStatus from '../../store/DriverStatus';
import { Redirect } from 'react-router';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import FormAreaField from '../Common/FormAreaField';
import FormTextField from '../Common/FormTextField';
import Loader from '../Common/Loader';
import { Alert } from 'reactstrap';

type ThisFormProps = DriverStatus.ZottPanelDriverStatusStore;

enum SaveFuelingPhase
{
    Initial,
    Submitting,
    Submited,
    Confirmed,
};

type ThisFormState = {
    errors: {[key: string]:string[]},
    phase: SaveFuelingPhase
};

class SaveFuelingForm extends React.PureComponent<ThisFormProps,ThisFormState> {

    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            errors: {},
            phase: SaveFuelingPhase.Initial
        }
    }

    public render() {
        if (this.state.phase === SaveFuelingPhase.Confirmed) {
            return <Redirect to="/fueling"/>;
        }

        if (this.state.phase === SaveFuelingPhase.Submited) {
            return (
                <form className="form">
                    <Alert color="success">Zapisano tankowanie.</Alert>
                    <button
                        className="btn btn-primary btn-block"
                        onClick={this.onMessageConfirmed.bind(this)}>OK</button>
                </form>
            );
        }

       const isSubmitting = this.state.phase === SaveFuelingPhase.Submitting;

        return (
            <form className="form" onSubmit={this.onSubmit.bind(this)} autoComplete="off">
                <h3>Nowe tankowanie</h3>
                <FormTextField
                    name="registrationNumber"
                    className="reg-number-input"
                    label="Numer rejestracyjny"
                    defaultValue={this.currentRegistrationNumber()}
                    isMandatory={true}
                    onKeyPress={this.onRegNumberKeyPress}
                    errors={this.state.errors} />
                <FormTextField
                    type="number"
                    inputMode="numeric"
                    name="kilometer"
                    label="Przebieg"
                    isMandatory={true}
                    defaultValue={0}
                    errors={this.state.errors}/>
                <FormTextField
                    type="number"
                    inputMode="decimal"
                    name="litres"
                    label="Litry"
                    isMandatory={true}
                    step="0.01"
                    defaultValue={0}
                    errors={this.state.errors}/>
                <FormTextField
                    type="number"
                    inputMode="decimal"
                    name="priceNetto"
                    label="Wartość (netto)"
                    defaultValue=""
                    step="0.01"
                    errors={this.state.errors}/>
                <FormTextField
                    name="wz"
                    label="WZ"
                    defaultValue=""
                    isMandatory={true}
                    errors={this.state.errors} />
                <FormAreaField
                    name="notes"
                    label="Dodatkowe informacje"
                    errors={this.state.errors}/>
                <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isSubmitting}>Zapisz</button>
                <ButtonWithRoute
                    className="btn btn-block"
                    path="/zott"
                    disabled={isSubmitting}>Anuluj</ButtonWithRoute>
                {isSubmitting && <Loader />}
            </form>
        );
    }

    private onMessageConfirmed() {
        this.setState({
            phase: SaveFuelingPhase.Confirmed
        });
    }

    private currentRegistrationNumber() : string {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.tractorRegNumber) {

                let regNumber = this.props.zottPanelDriverStatus.driverStatus.tractorRegNumber.toUpperCase();
                
                // Regex below removes all spaces
                regNumber = regNumber.replace(/\s+/g, '');

                return regNumber;
            }

        return '';
    }

    private onRegNumberKeyPress(event: React.KeyboardEvent<HTMLInputElement>)
    {
        if (event.key === ' ') {
            event.preventDefault();
            return;
        }
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.state.phase === SaveFuelingPhase.Submitting) {
            return;
        }

        this.setState({
            phase: SaveFuelingPhase.Submitting
        });

        let formElement = formEvent.target as HTMLFormElement;
        let data = new FormData(formElement);

        data.set('registrationNumber', data.get('registrationNumber')!.toString().toUpperCase());

        var result = await ApiRequest('api/fueling', data);

        if (result.status === 200) {
            this.setState({
                phase: SaveFuelingPhase.Submited
            });
        } else {
            this.setState({
                phase: SaveFuelingPhase.Initial
            });
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({
                    errors: resultData.errors
                });
            }
        }
    }
}

export default connect(
    (state:ApplicationState) => state.driverStatus,
        DriverStatus.actionCreators
)(SaveFuelingForm as any);