import * as React from 'react';
import { StringDictionary } from '../../types/global';
import { FormText } from 'reactstrap';

const defaultPropsValues = {
    errors: {} as {[key: string]:string[]},
    placeholderText: "Wybierz" as string,
    prompt: "Wybierz..." as string,
    allowPromptSelection: false as boolean,
    description: '' as string,
    keysOrder: undefined as string[] | undefined
};

type DefaultProps = Readonly<typeof defaultPropsValues>;

type FormSelectProps = {
    values: StringDictionary,
    label: string
} & Partial<DefaultProps> & React.SelectHTMLAttributes<HTMLSelectElement>;

export default class FormSelect extends React.PureComponent<FormSelectProps>{
    static defaultProps = defaultPropsValues;

    public render() {
        var keys = this.keys();
        if (keys.length === 0)
        {
            return null;
        }

        let {placeholderText, errors, prompt, values, label, allowPromptSelection, description, keysOrder, ...selectProps} = this.props;

        selectProps.className = "custom-select" + (this.haveErrors() ? " is-invalid" : "");
        selectProps.id= this.props.id ? this.props.id : this.props.name;

        return (
            <div className="form-group">
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <select {...selectProps}>
                    <option disabled={!this.props.allowPromptSelection} key="0" value="0">{this.props.prompt}</option>
                    {keys.map(key =>
                        <option key={key} value={key}>
                            {this.props.values[key]}
                        </option>
                    )}
                </select>
                <div className="invalid-feedback">{this.errorText()}</div>
                {description && <FormText color="muted">{description!}</FormText>}
            </div>
        );
    }

    private keys() : string[] {
        return this.props.keysOrder || Object.keys(this.props.values);
    }

    private haveErrors() : boolean
    {
        return this.props.errors !== undefined && (this.props.errors[this.props.name!] !== undefined);
    }

    private errorText() : string
    {
        if (this.props.errors && this.props.errors[this.props.name!] && this.props.errors[this.props.name!][0]) {
            return this.props.errors[this.props.name!][0];
        } else {
            return '';
        }
    }
}