import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ApiRequest } from '../../services/apiClient';
import FormGeneralErrors from '../Common/FormGeneralErrors';
import * as Authorization from '../../store/Authorization';
import ZottPanelTrailerOperations from './ZottPanelTrailerOperations';
import ZottPanelDriverOperations from './ZottPanelDriverOperations';
import * as DriverStatus from '../../store/DriverStatus';
import { ZottTrailerStatus, ZottDriverStatus, ServerMessage, LocationToGo } from '../../types/global';
import Loader from '../Common/Loader';
import NotificationMessage from '../Common/NotificationMessage';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { Alert } from 'reactstrap';
import { PanelButtonClasses } from '../../services/styleService';

type ZottPanelProps = DriverStatus.ZottPanelDriverStatusStore
    & Authorization.AuthorizationState
    & typeof DriverStatus.actionCreators
    & typeof Authorization.actionCreators;

type ZottPanelState = {
    intervalId: NodeJS.Timeout | undefined,
    isBusy: boolean,
    emailsSentAlertVisible: boolean,
    emailsSentAlertColor: string,
    emailsSentText: string
}

class ZottPanel extends React.PureComponent<ZottPanelProps, ZottPanelState> {
    public constructor(props: ZottPanelProps) {
        super(props);

        this.state = {
            intervalId: undefined,
            isBusy: false,
            emailsSentAlertVisible: false,
            emailsSentAlertColor: 'success',
            emailsSentText: ''
        };
    };

    public render () {
        return (
            <div className="container panel">
                <h3>Panel Zott</h3>
                <FormGeneralErrors errors={this.props.errors}></FormGeneralErrors>
                <hr/>
                {this.renderPanelContents()}
            </div>
        );
    }

    public renderPanelContents()
    {
        const numberOfPallets = this.numberOfPallets();
        const numberOfPalletsUpdate = this.numberOfPalletsUpdateText();
        const numberOfInternationalPallets = this.numberOfInternationalPallets();
        const numberOfInternationalPalletsUpdate = this.numberOfInternationalPalletsUpdateText();
        const tractorsDrivingToOpoleCount = this.tractorsDrivingToOpoleCountText();
        const message = this.currentMessage();
        const messageTextHtml = message ? message!.messageTextHtml : '';
        const messageTitleHtml = message ? message!.messageTitleHtml : '';

        if (this.props.isLoading || this.state.isBusy) {
            return <Loader />;
        }
        return (<div>
            {numberOfPallets && <p><span className="strong">Ilość palet krajowych: </span>{numberOfPallets}</p>}
            {numberOfPalletsUpdate && <p><span className="strong">Ostatnia aktualizacja: </span>{numberOfPalletsUpdate}</p>}
            {this.hasPermission("zott:statusinternationalpalletsnumber") && numberOfInternationalPallets &&
                <p><span className="strong">Ilość palet międzynarodowych: </span>{numberOfInternationalPallets}</p>
            }
            {this.hasPermission("zott:statusinternationalpalletsnumber") && numberOfInternationalPalletsUpdate &&
                <p><span className="strong">Ostatnia aktualizacja: </span>{numberOfInternationalPalletsUpdate}</p>
            }
            {tractorsDrivingToOpoleCount && <p><span className="strong">Pojazdy w drodze do Opola: </span>{tractorsDrivingToOpoleCount}</p>}
            {this.hasPermission("zott:updatepalletsnumber") &&
                <div>
                    <hr/>
                    <ButtonWithRoute className={PanelButtonClasses} path="/zott/updatepalletsnumberbyzott">Podaj ilość palet</ButtonWithRoute>
                </div>
            }
            {this.hasPermission("zott:updatepalletsnumber") &&
                <div>
                    <button className={PanelButtonClasses} onClick={this.sendPalletsNumberNotification.bind(this)}>Wyślij email o liczbie palet</button>
                    <Alert color={this.state.emailsSentAlertColor} isOpen={this.state.emailsSentAlertVisible} toggle={this.dismissEmailsSentAlert.bind(this)} fade={false}>
                        {this.state.emailsSentText}
                    </Alert>
                </div>
            }
            {this.hasPermission("zott:updateinternationalpalletsnumber") &&
                <div>
                    <ButtonWithRoute className={PanelButtonClasses} path="/zott/update-international-pallets-number">Podaj ilość palet międzynarodowych</ButtonWithRoute>
                </div>
            }
            <ZottPanelDriverOperations locationsToGo={this.locationsToGo()} driverStatus={this.driverStatus()} operationsCanPerform={this.operationsCanPerform()}/>
            <ZottPanelTrailerOperations trailerStatus={this.trailerStatus()} operationsCanPerform={this.operationsCanPerform()}/>
            {<NotificationMessage show={message !== undefined}
                    id={'driverMessage'}
                    title={messageTitleHtml}
                    message={messageTextHtml}
                    messageAsHtml={true}
                    cancelButtonText=""
                    okButtonText="Ok"
                    confirmationFunction={this.onMessageConfirmed.bind(this)}
                    cancelFunction={this.onMessageConfirmed.bind(this)} />}
        </div>);
    }

    public componentDidMount() {
        this.requestData();
    }

    public componentWillUnmount() {
        if (this.state.intervalId) {
            clearTimeout(this.state.intervalId);
        }
    }

    private async sendPalletsNumberNotification() {
        this.setState({
            isBusy: true
        });

        var result = await ApiRequest('api/zott/pallets-number/notify');

        this.setState({
            isBusy: false
        });

        let wasEmailsSent = false;

        if (result.status === 200)
        {
            const resultJson = await result.json();
            if (resultJson.emailSent) {
                wasEmailsSent = true;
            }
        }

        if (wasEmailsSent) {
            this.setState({
                emailsSentAlertVisible: true,
                emailsSentAlertColor: 'success',
                emailsSentText: 'Email został wysłany'
            });
        } else {
            this.setState({
                emailsSentAlertVisible: true,
                emailsSentAlertColor: 'danger',
                emailsSentText: 'Email nie został wysłany'
            });
        }
    }

    private dismissEmailsSentAlert() {
        this.setState({
            emailsSentAlertVisible: false
        });
    }

    private requestData() {
        this.props.requestDriverStatus();

        this.setState({
            intervalId: setTimeout(this.requestData.bind(this), 60000)
        });
    }

    private numberOfPallets() : string | undefined {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.numberOfPallets) {
            return this.props.zottPanelDriverStatus.numberOfPallets;
        }
        return 'nieznana';
    }

    private numberOfPalletsUpdateText() : string {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.numberOfPalletsUpdateDateTime) {
                return this.props.zottPanelDriverStatus.numberOfPalletsUpdateDateTime;
            }
        return 'nieznana';
    }

    private numberOfInternationalPallets() : string | undefined {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.numberOfInternationalPallets) {
            return this.props.zottPanelDriverStatus.numberOfInternationalPallets;
        }
        return 'nieznana';
    }

    private numberOfInternationalPalletsUpdateText() : string {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.numberOfInternationalPalletsUpdateDateTime) {
                return this.props.zottPanelDriverStatus.numberOfInternationalPalletsUpdateDateTime;
            }
        return 'nieznana';
    }

    private tractorsDrivingToOpoleCountText() : string {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.tractorsDrivingToOpoleCount !== null) {
                return this.props.zottPanelDriverStatus.tractorsDrivingToOpoleCount.toString();
            }
        return '';
    }

    private driverStatus() : ZottDriverStatus | undefined {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus)
        {
            return this.props.zottPanelDriverStatus.driverStatus;
        }
        return undefined;
    }

    private locationsToGo() : LocationToGo[] | undefined {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.locationsToGo) {
            return this.props.zottPanelDriverStatus.locationsToGo;
        }
        return undefined;
    }

    private trailerStatus() : ZottTrailerStatus | undefined {
        if (this.props.zottPanelDriverStatus
            && this.props.zottPanelDriverStatus.driverStatus
            && this.props.zottPanelDriverStatus.driverStatus.trailerStatus)
        {
            return this.props.zottPanelDriverStatus.driverStatus.trailerStatus;
        }
        return undefined;
    }

    private operationsCanPerform() : string[] | undefined {
        if (!this.props.zottPanelDriverStatus) {
            return undefined;
        }
        return this.props.zottPanelDriverStatus.operationsCanPerform;
    }

    private currentMessage() : ServerMessage | undefined {
        var driverStatus = this.props.zottPanelDriverStatus;

        if (!driverStatus || !driverStatus.messages) {
            return undefined;
        }

        return driverStatus.messages[0];
    }

    private async onMessageConfirmed() {
        var currentMessage = this.currentMessage();
        if (!currentMessage) {
            return;
        }

        await this.props.confirmMessage(currentMessage.id);
        
        this.forceUpdate();
    }

    private hasPermission(permissionName: string):boolean {
        return this.props.permissions.some(function(permission) {
            return permission.startsWith(permissionName);
        });
    }
};

export default connect(
    (state:ApplicationState) => { return {
        ...state.driverStatus,
        ...state.authorization
    }},
    {
        ...DriverStatus.actionCreators,
        ...Authorization.actionCreators
    }
)(ZottPanel as any);