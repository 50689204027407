import * as React from 'react';
import { Alert } from 'reactstrap';

type FormGeneralErrorsProps = {
    errors: {[key: string]:string[]}
};

export default class FormGeneralErrors extends React.PureComponent<FormGeneralErrorsProps> {
    public render() {
        return (
            <div>
                {this.generalErrors().map(errorMsg => <Alert color="danger">{errorMsg}</Alert>)}
            </div>);
    }

    private generalErrors() : string[] {
        let result: string[] = [];
        if (this.props.errors)
        {
            if (this.props.errors[""] && this.props.errors[""].length > 0) {
                result = this.props.errors[""];
            }
        }
        return result;
    }
}