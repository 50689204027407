import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { BriefOperationLogEntry } from '../../types/global';
import { ApiRequest } from '../../services/apiClient';
import Loader from '../Common/Loader';
import { Alert } from 'reactstrap';
import OperationLogsTable from '../Zott/OperationLogsTable';
import { EnhanceBriefOperationLogs } from '../../helpers/DaoHelper';

type TrailerOperationLogProps = RouteComponentProps<{ trailerId: string }>;

type TrailerOperationLogState = {
    trailerId: number,
    isLoading: boolean,
    isLoaded: boolean
    trailerLogs: BriefOperationLogEntry[],
    error: string
};

export default class TrailerOperationLog extends React.PureComponent<TrailerOperationLogProps, TrailerOperationLogState> {
    constructor(props: TrailerOperationLogProps) {
        super(props);

        const id = parseInt(this.props.match.params.trailerId) || 0;
        this.state = {
            trailerId: id,
            isLoading: false,
            isLoaded: false,
            trailerLogs: [],
            error: ''
        }
    }

    public render() {
        if (this.state.isLoading) {
            return <Loader/>;
        }
        if (!this.state.isLoaded) {
            return null;
        }
        return (
            <div>
                <div className="row">
                    <h3>Historia operacji naczepy</h3>
                </div>
                {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                <OperationLogsTable logs={this.state.trailerLogs} />
            </div>
        );
    }

    public componentDidMount() {
        this.fetchTrailerLogs();
    }

    private async fetchTrailerLogs()
    {
        this.setState({
            isLoading: true
        });

        const formData = new FormData();
        formData.set('trailerId', this.state.trailerId.toString());

        const response = await ApiRequest('api/zott/traileroperationlog', formData);

        if (response.status !== 200) {
            this.setState({
                isLoading: false,
                isLoaded: true,
                error: 'Bład wczytywania logu naczepy'
            });
            return;
        }

        const trailerLogs = await response.json() as BriefOperationLogEntry[];

        EnhanceBriefOperationLogs(trailerLogs);

        this.setState({
            isLoaded: true,
            isLoading: false,
            trailerLogs: trailerLogs
        });
    }
}