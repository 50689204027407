import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { connect } from 'react-redux';
import './Main.css'
import { ApplicationState } from '../store';
import * as Authorization from '../store/Authorization';
import { Redirect } from 'react-router';

const Layout = (props: { children?: React.ReactNode } & Authorization.AuthorizationState) => (
    <React.Fragment>
        <NavMenu/>
        {!props.isLogged && <Redirect to='/login' />}
        <Container>
            {props.children}
        </Container>
    </React.Fragment>
);

export default connect(
    (state:ApplicationState) => state.authorization, 
    null
)(Layout as any);