import * as React from 'react';
import { connect } from 'react-redux';
import * as UsersStore from '../../store/Users';
import { ApplicationState } from '../../store';
import { Redirect, RouteComponentProps } from 'react-router';
import FormTextField from '../Common/FormTextField';
import UserTypeSelect from './UserTypeSelect';
import { ApiRequest } from '../../services/apiClient';
import FormCheckboxField from '../Common/FormCheckboxField';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { AdminSaveButtonClasses, AdminCancelButtonClasses } from '../../services/styleService';

type UserFormProps = RouteComponentProps<{ userId: string }> & typeof UsersStore.actionCreators & UsersStore.UsersState;

type UserFormState = {
    errors: {[key: string]:string[]},
    success: boolean,
    isEditMode: boolean,
    editedUser: UsersStore.User
}

class UserForm extends React.PureComponent<UserFormProps, UserFormState> {
    constructor(props: UserFormProps) {
        super(props);

        const userIdNumber = parseInt(this.props.match.params.userId) || 0;
        let editedUser : UsersStore.User | undefined;
        if (userIdNumber > 0) {
            editedUser = props.users.find((user) => user.id === userIdNumber);
            if (!editedUser) {
                console.warn(`User with id ${userIdNumber} not loaded`);
            }
        }

        // Creates null object
        if (!editedUser) {
            editedUser = {
                id: 0,
                loginName: '',
                firstName: '',
                lastName: '',
                userTypeId: 0,
                userTypeName: '',
                phone: '',
                isActive: false
            }
        }

        this.state = {
            errors: {},
            success: false,
            isEditMode: userIdNumber > 0,
            editedUser: editedUser!
        }
    }

    public render() {
        var user = this.state.editedUser;

        if (this.state.success) {
            return <Redirect to='/admin/users' />
        }
        return (
        <form className="form" onSubmit={this.onSubmit.bind(this)}>
            <h3>
                {this.state.isEditMode ? `Edycja użytkownika ${user.loginName}` : "Nowy użytkownik" }
            </h3>
            {this.state.isEditMode ? <input type="hidden" name="id" value={user.id}/> : ''}
            <FormTextField name="loginName" label="Login" defaultValue={user.loginName} errors={this.state.errors} />
            <FormTextField name="firstName" label="Imię" defaultValue={user.firstName} errors={this.state.errors} />
            <FormTextField name="lastName" label="Nazwisko" defaultValue={user.lastName} errors={this.state.errors} />
            <FormTextField name="phone" inputMode="tel" label="Telefon" defaultValue={user.phone} errors={this.state.errors} />
            <FormTextField name="password" label={this.state.isEditMode ? "Hasło (wprowadź by zmienić)" : "Hasło"} type="password" defaultValue="" errors={this.state.errors} />
            <UserTypeSelect defaultValue={user.userTypeId} errors={this.state.errors}/>
            {this.state.isEditMode && <FormCheckboxField label="Aktywny" value="true" defaultChecked={user.isActive} name="isActive"/>}
            <button type="submit" className={AdminSaveButtonClasses}>Zapisz</button>
            <ButtonWithRoute className={AdminCancelButtonClasses} path='/admin/users'>Anuluj</ButtonWithRoute>
        </form>
        );
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.validateForm()) {
            await this.submitForm(formEvent.target as HTMLFormElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        const url = this.state.isEditMode ? 'api/user/update' : 'api/user/create';

        var result = await ApiRequest(url, data);

        if (result.status === 200) {
            this.props.requestUsers(true);
            this.setState({success: true});
            return true;
        } else {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
        }
    }

    private errorText(fieldName: string) : string {
        if (this.state.errors[fieldName] && this.state.errors[fieldName][0]) {
            return this.state.errors[fieldName][0];
        } else {
            return '';
        }
    }

    private haveErrors(fieldName: string) : boolean {
        return this.state.errors[fieldName] != null;
    }
}

export default connect(
    (state: ApplicationState) => state.users,
    UsersStore.actionCreators
)(UserForm as any);