import * as React from 'react';
import FormTextField from '../Common/FormTextField';
import { ApiRequest } from '../../services/apiClient';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { Redirect } from 'react-router';

enum FormPhase
{
    Initial,
    Submitting,
    Submited,
    Confirmed,
};

type ThisFormProps = {
    url?: string,
    label?: string
};

type ThisFormState = {
    errors: {[key: string]:string[]},
    phase: FormPhase
};

export default class UpdatePalletsNumberByZottForm extends React.PureComponent<ThisFormProps,ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            errors: {},
            phase: FormPhase.Initial
        }
    }

    public render() {
        if (this.state.phase === FormPhase.Submited) {
            return <Redirect to="/zott"/>
        }

        const isSubmitting = this.state.phase === FormPhase.Submitting;
        const label = this.props.label || "Liczba palet";

        return(
            <form className="form" onSubmit={this.onSubmit.bind(this)} autoComplete="off">
                <FormTextField isMandatory={true} type="number" label={label} name="numberOfPallets"/>
                <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isSubmitting}>Zapisz</button>
                <ButtonWithRoute className="btn btn-block" path="/zott">Anuluj</ButtonWithRoute>
            </form>
        );
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.state.phase === FormPhase.Submitting) {
            return;
        }

        this.setState({
            phase: FormPhase.Submitting
        });

        let formElement = formEvent.target as HTMLFormElement;
        let data = new FormData(formElement);

        const url = this.props.url || 'api/zott/pallets-number';

        var result = await ApiRequest(url, data);

        if (result.status === 200) {
            this.setState({
                phase: FormPhase.Submited
            });
        } else {
            this.setState({
                phase: FormPhase.Initial
            });
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({
                    errors: resultData.errors
                });
            }
        }
    }
}