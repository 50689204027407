import * as React from 'react';

type NotificationMessageProps = {
    id: string,
    title: string,
    message: string,
    cancelButtonText?: string,
    okButtonText?: string,
    show: boolean,
    cancelFunction?: () => void,
    confirmationFunction?: () => void,
    messageAsHtml?: boolean
};

// TODO - implement default props
export default class NotificationMessage extends React.PureComponent<NotificationMessageProps> {
    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="modal" tab-index="-1" id={this.props.id} role="dialog" aria-labelledby="..." style={{display: 'block'}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={this.props.cancelFunction}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {!this.props.messageAsHtml && <p>{this.props.message}</p>}
                            {this.props.messageAsHtml && <p dangerouslySetInnerHTML={{ __html: this.props.message }} />}
                        </div>
                        <div className="modal-footer">
                            {this.props.cancelButtonText && <button onClick={this.props.cancelFunction} type="button" className="btn btn-secondary" data-dismiss="modal">{this.props.cancelButtonText}</button>}
                            {this.props.okButtonText && <button onClick={this.props.confirmationFunction} type="button" className="btn btn-primary">{this.props.okButtonText}</button>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
