import * as React from 'react';

type FormAreaFieldProps = {
    name: string,
    label: string,
} & Partial<DefaultProps>;

const defaultPropsValues = {
    defaultValue: '' as string,
    isMandatory: false as boolean,
    errors: {} as {[key: string]:string[]}
};

type DefaultProps = Readonly<typeof defaultPropsValues>;

// TODO - add possibility to add other button attributes
// input: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
// because of this https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
export default class FormAreaField extends React.PureComponent<FormAreaFieldProps>{
    static defaultProps = defaultPropsValues;

    // TODO - utilise isMandatory prop
    public render() {
        return (
            <div className="form-group">
                <label htmlFor={this.props.name}>{this.props.label}</label>
                <textarea defaultValue={this.props.defaultValue} className={"form-control" + (this.haveErrors() ? " is-invalid" : "")} name={this.props.name} id={this.props.name} />
                <div className="invalid-feedback">{this.errorText()}</div>
            </div>
        );
    }

    private errorText() : string {
        if (this.props.errors![this.props.name] && this.props.errors![this.props.name][0]) {
            return this.props.errors![this.props.name][0];
        } else {
            return '';
        }
    }

    private haveErrors() : boolean {
        return this.props.errors![this.props.name] != null;
    }
}