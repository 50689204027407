import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { ZottTrailerStatus, StringDictionary } from '../../types/global';
import Loader from '../Common/Loader';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { HasPermission } from '../../services/authService';
import SortableTable from '../Common/SortableTable';

type ZottTrailersStatusProps = {

};

type ZottTrailersStatusState = {
    trailersStatuses: ZottTrailerStatus[] | undefined,
    error: string,
    isLoading: boolean,
    isLoaded: boolean
}


export default class ZottTrailersStatus extends React.PureComponent<ZottTrailersStatusProps, ZottTrailersStatusState> {
    constructor(props: ZottTrailersStatusProps) {
        super(props);

        this.state = {
            trailersStatuses: undefined,
            error: '',
            isLoading: false,
            isLoaded: false
        }
    }

    public render() {
        if (this.state.isLoading)
        {
            return <Loader/>;
        }
        if (!this.state.isLoaded)
        {
            return null;
        }
        if (this.state.trailersStatuses && this.state.trailersStatuses!.length === 0) {
            return <div className="row">
                <h3>Status naczep - Brak naczep</h3>
            </div>
        }

        return (
            <div>
                <div className="row">
                    <h3>Status naczep</h3>
                </div>
                <SortableTable dataRows={this.state.trailersStatuses} idKey="trailerId" columns={this.trailerStatusColumns()} renderActions={this.renderActions} />
            </div>
            );
    }

    public componentDidMount() {
        this.fetchTrailers();
    }

    private renderActions(row: any) {
        return (
            <div>
                <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/zott/trailerlog/' + row['trailerId']}>Historia</ButtonWithRoute>
                {HasPermission("zott:managetrailerstate") && <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/zott/managetrailerstatus/' + row['trailerId']}>Zarządzaj</ButtonWithRoute>}
            </div>
        );
    }

    private trailerStatusColumns()
    {
        const columns = {} as StringDictionary;

        columns['number'] = 'Naczepa';
        columns['status'] = 'Status';
        columns['cargoType'] = 'Ładunek';
        columns['wz'] = 'WZ';
        columns['locationTitle'] = 'Lokacja';
        columns['rampNumber'] = 'Nr rampy';
        columns['driverName'] = 'Kierowca';
        columns['actions'] = 'Akcje';

        return columns;
    }

    private async fetchTrailers()
    {
        this.setState({
            isLoading: true
        });

        const result = await ApiRequest('api/zott/trailersstatus');

        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            this.setState({
                error: 'Bład pobierania statusu naczep'
            });
            return;
        }

        const trailersStatuses = await result.json() as ZottTrailerStatus[];

        this.setState({
            isLoaded: true,
            trailersStatuses: trailersStatuses
        });
    }
}