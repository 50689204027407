import * as React from 'react';
import { connect } from 'react-redux';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { ApplicationState } from '../../store';
import * as Authorization from '../../store/Authorization';

type AdminPanelProps = Authorization.AuthorizationState;

const ButtonClasses = "col-sm btn btn-lg btn-panel";

class AdminPanel extends React.PureComponent<AdminPanelProps, {}> {

    public render () {
        return (
        <div className="container panel">
            <h3>Panel administratora</h3>
            <hr/>
            {this.hasPermission("admin:users") && <div className="row">
                <ButtonWithRoute className={ButtonClasses} path="/admin/users">Użytkownicy</ButtonWithRoute>
                <ButtonWithRoute className={ButtonClasses} path="/admin/user-types">Typy użytkowników</ButtonWithRoute>
            </div>}
            <hr/>
            {this.hasPermission("admin:drivers") && <div className="row">
                <ButtonWithRoute className={ButtonClasses} path="/admin/drivers">Kierowcy</ButtonWithRoute>
            </div>}
            <hr/>
            {this.hasPermission("admin:vehicles") && <div className="row">
                <ButtonWithRoute className={ButtonClasses} path="/admin/tractors">Ciągniki</ButtonWithRoute>
                <ButtonWithRoute className={ButtonClasses} path="/admin/trailers">Naczepy</ButtonWithRoute>
            </div>}
            <hr/>
            {this.hasPermission("admin:settings") && <div className="row">
                <ButtonWithRoute className={ButtonClasses} path="/admin/settings">Ustawienia</ButtonWithRoute>
            </div>}
        </div>
        );
    }

    private hasPermission(permissionName: string):boolean {
        return this.props.permissions.some(function(permission) {
            return permission.startsWith(permissionName);
        });
    }
};

export default connect(
    (state:ApplicationState) => state.authorization, 
    null
)(AdminPanel as any);