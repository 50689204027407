import * as React from 'react';
import ZottForm from './ZottForm';
import FormTextField from '../Common/FormTextField';
import ExtraRouteLocationFields, { OnChangeEventData } from './ExtraRouteLocationFields';
import { LocationData } from './ExtraRouteLocationFields';
import RepeatableWithErrors from '../Common/RepeatableWithErrors';

export default class StartExtraRouteForm extends React.PureComponent {
    public render() {
        return(
            <ZottForm operationName="StartExtraRoute" operationTitle="Trasa extra" operationButtonText="Rozpocznij trasę extra">
                <h5>Miejsce wyjazdu</h5>
                <ExtraRouteLocationFields fieldsNamePrefix="departure" showKilometerField={true}/>
                <FormTextField name="wz" label="WZ" isMandatory={true} />
                <h5>Miejsca docelowe</h5>
                <RepeatableWithErrors
                    defaultValues={this.defaultDestinations()}
                    id="extraRouteDestinations"
                    renderItem={this.renderDestination.bind(this)}
                    getNewItemValue={this.getNewDestination.bind(this)}
                    minItems={1} />
            </ZottForm>
        );
    }

    private renderDestination(
        value: any,
        index: number,
        values: any[],
        commonData: any,
        errors?: {[key: string]:string[]},
        onValueChange?: (value:any) => void )
    {
        var fieldsValue = value as LocationData;

        return <ExtraRouteLocationFields
            fieldsNamePrefix="destination"
            showKilometerField={false}
            data={fieldsValue} errors={errors}
            onChange={(eventData: OnChangeEventData) => onValueChange!(eventData.itemValue)}
            />
    }

    private defaultDestinations() : LocationData[]
    {
        const values : LocationData[] = [{
            locationName: '',
            companyName: '',
            kilometers: 0
        }];

        return values;
    }

    private getNewDestination()
    {
        let a : LocationData = {
            locationName: '',
            companyName: '',
            kilometers: 0
        }

        return a;
    }
}