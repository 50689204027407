import * as React from 'react';
import { ZottLocationEnum } from '../../types/global';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import NotificationMessage from '../Common/NotificationMessage';

type ThisFormProps = {
    location: ZottLocationEnum,
    operationsCanPerform: string[] | undefined
} & Partial<{trailerNumber: string}>;

type ThisFormState = {
    unhitchClickConfirmed: boolean,
    showConfirmation: boolean,
    confirmationTitle: string,
    confirmationText: string
}

export default class DriverHitchOperations extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            unhitchClickConfirmed: false,
            showConfirmation: false,
            confirmationTitle: '',
            confirmationText: ''
        }
    }

    public render() {
        const panelButtonClasses = "col-sm btn btn-lg btn-panel";

        return (
            <div>
                <hr/>
                {this.props.trailerNumber && <p><span className="strong">Naczepa: </span>{this.props.trailerNumber}</p>}
                <div className="row">
                    {this.canPerform("HitchTrailer") && <ButtonWithRoute className={panelButtonClasses} path="/zott/hitchtrailer">Zaczep naczepę</ButtonWithRoute>}
                    {this.canPerform("UnHitchTrailer") && <ButtonWithRoute onClick={this.onUnhitchClicked.bind(this)} clicked={this.state.unhitchClickConfirmed} className={panelButtonClasses} path="/zott/unhitchtrailer">Odczep naczepę</ButtonWithRoute>}
                    {this.canPerform("ReHitchTrailer") && <ButtonWithRoute className={panelButtonClasses} path="/zott/rehitchtrailer">Przepinka</ButtonWithRoute>}
                </div>
                <NotificationMessage show={this.state.showConfirmation}
                    id="deleteConfirmation"
                    title={this.state.confirmationTitle}
                    message={this.state.confirmationText}
                    cancelButtonText="Anuluj"
                    okButtonText="Tak"
                    cancelFunction={this.hideConfirmation.bind(this)}
                    confirmationFunction={this.onConfirmed.bind(this)} />
            </div>
        );
    }

    private canPerform(operationName:string)
    {
        if (!this.props.operationsCanPerform)
        {
            return false;
        }

        return this.props.operationsCanPerform.indexOf(operationName) >= 0;
    }

    private hideConfirmation() {
        this.setState({
            showConfirmation: false
        });
    }

    private onConfirmed() {
        this.setState({
            unhitchClickConfirmed: true
        });
    }

    private onUnhitchClicked(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    {
        if (this.isInImproperLocation()) {
            this.setState({
                showConfirmation: true,
                confirmationTitle: 'Odczep naczepę',
                confirmationText: 'Obecna lokacja nie jest odpowiednia do przeprowadzenia operacji. Czy na pewno chcesz przeprowadzić operację?',
            });
        } else {
            this.setState({
                unhitchClickConfirmed: true
            });
        }
    }

    private isInImproperLocation() : boolean {
        return this.props.location === ZottLocationEnum.Unknown
            || this.props.location === ZottLocationEnum.DrivingToFresh
            || this.props.location === ZottLocationEnum.DrivingToParking
            || this.props.location === ZottLocationEnum.DrivingToZott;
    }
}