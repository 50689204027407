import * as React from 'react';
import { connect } from 'react-redux';
import * as TrailersStore from '../../store/Trailers';
import { ApplicationState } from '../../store';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import NotificationMessage from '../Common/NotificationMessage';
import { StringDictionary, Trailer } from '../../types/global';
import { DangerousOperationButtonClasses } from '../../services/styleService';
import SortableTable from '../Common/SortableTable';

type TrailersProps = TrailersStore.TrailersState & typeof TrailersStore.actionCreators;
type TrailersState = {
    showDeleteConfirmation: boolean,
    deleteConfirmationTitle: string,
    deleteConfirmationText: string,
    idToDelete: number
};


class Trailers extends React.PureComponent<TrailersProps, TrailersState> {
    constructor(props: TrailersProps) {
        super(props);

        this.state = {
            showDeleteConfirmation: false,
            deleteConfirmationTitle: '',
            deleteConfirmationText: '',
            idToDelete: 0
        }
    }

    public componentDidMount() {
        this.props.requestTrailers(false);
    }

    public render() {
        if (this.props.trailers.length === 0) {
            return <div className="row">
                <h3>Brak naczep</h3>
                <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/trailers/new">Nowa naczepa</ButtonWithRoute>
            </div>
        }
        return (
            <div>
                <div className="row">
                    <h3>Naczepy</h3>
                    <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/trailers/new">Nowa naczepa</ButtonWithRoute>
                </div>
                <SortableTable dataRows={this.props.trailers} columns={this.columns()} renderActions={this.renderActions.bind(this)}/>
                <NotificationMessage show={this.state.showDeleteConfirmation}
                    id="deleteConfirmation"
                    title={this.state.deleteConfirmationTitle}
                    message={this.state.deleteConfirmationText}
                    cancelButtonText="Anuluj"
                    okButtonText="Usuń"
                    cancelFunction={this.cancelDelete.bind(this)}
                    confirmationFunction={() => this.doDelete(this.state.idToDelete)} />
            </div>
        );
    }

    private columns() {
        const columns = {} as StringDictionary;

        columns['number'] = 'Numer';
        columns['description'] = 'Opis';
        columns['maintainerName'] = 'Opiekun';
        columns['isActive'] = 'Aktywna';
        columns['isTemporary'] = 'Tymczasowa';
        columns['actions'] = 'Akcje';

        return columns;
    }
    
    private renderActions(trailer: any) {
        return (
            <div>
                <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/admin/trailers/edit/' + trailer.id}>Edytuj</ButtonWithRoute>
                <button onClick={() => this.confirmDelete(trailer)} className={DangerousOperationButtonClasses}>Usuń</button>
            </div>
        );
    }

    private confirmDelete(trailer: Trailer) {

        this.setState({
            showDeleteConfirmation: true,
            deleteConfirmationTitle: "Potwierdzenie usunięcia",
            deleteConfirmationText: "Usunąć naczepę " + trailer.number + " ?",
            idToDelete: trailer.id
        });
    }

    private cancelDelete() {
        this.setState({showDeleteConfirmation: false});
    }

    private async doDelete(id: number) {
        this.setState({showDeleteConfirmation: false});
        await this.props.deleteTrailer(id);
        this.props.requestTrailers(true);
    }

    private trailerAttrs(trailer: Trailer) : string {
        let attrs = [];

        if (trailer.isTemporary) {
            attrs.push("Tymczasowa");
        }
        if (trailer.isActive) {
            attrs.push("Aktywna");
        }

        return attrs.join(',');
    }
}

export default connect(
    (state: ApplicationState) => state.trailers, // Selects which state properties are merged into the component's props
    TrailersStore.actionCreators
)(Trailers as any);
