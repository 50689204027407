import * as React from 'react';
import { ZottDriverStatus, DriverStatusEnum, ZottLocationEnum, LocationToGo } from '../../types/global';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import DriverLocationOperations from './DriverLocationOperations';
import DriverHitchOperations from './DriverHitchOperations';

type ThisFormProps = {
    driverStatus: ZottDriverStatus | undefined;
    operationsCanPerform: string[] | undefined;
    locationsToGo: LocationToGo[] | undefined;
}

type ThisFormState = {

}

export default class ZottPanelDriverOperations extends React.PureComponent<ThisFormProps, ThisFormState> {
    public render() {
        const driverStatus = this.props.driverStatus;
        if (!driverStatus) {
            return null;
        }

        const panelButtonClasses = "col-sm btn btn-lg btn-panel";

        return (
            <div>
                <hr/>
                <p><span className="strong">Kierowca: </span>{driverStatus.driverName}</p>
                <p><span className="strong">Status: </span>{driverStatus.status}</p>
                {driverStatus.tractorRegNumber && <p><span className="strong">Ciągnik: </span>{driverStatus.tractorRegNumber}</p>}
                <div className="row">
                    {this.canPerform("StartWork") && <ButtonWithRoute className={panelButtonClasses} path="/zott/startwork">Rozpocznij pracę</ButtonWithRoute>}
                    {this.canPerform("EndWork") && <ButtonWithRoute className={panelButtonClasses} path="/zott/endwork">Zakończ pracę</ButtonWithRoute>}
                </div>
                {this.isWorking() && <DriverLocationOperations location={driverStatus.locationTitle} locationsToGo={this.props.locationsToGo} operationsCanPerform={this.props.operationsCanPerform}/>}
                {this.isWorking() && <DriverHitchOperations trailerNumber={this.trailerNumber()} location={this.driverLocation()} operationsCanPerform={this.props.operationsCanPerform}/>}
            </div>
        );
    }

    private trailerNumber() : string {
        if (this.props.driverStatus
            && this.props.driverStatus.trailerStatus) {
            return this.props.driverStatus.trailerStatus.number;
        }
        return 'Brak';
    }

    private isWorking() : boolean {
        return !!this.props.driverStatus
        && this.props.driverStatus.statusId === DriverStatusEnum.Working;
    }

    private driverLocation() {
        return this.props.driverStatus ? this.props.driverStatus.zottLocationEnum : ZottLocationEnum.Unknown;
    }

    private canPerform(operationName:string)
    {
        if (!this.props.operationsCanPerform)
        {
            return false;
        }

        return this.props.operationsCanPerform.indexOf(operationName) >= 0;
    }
}