import * as React from 'react';
import { connect } from 'react-redux';
import * as UserTypesStore from '../../store/UserTypes';
import { ApplicationState } from '../../store';
import Permissions from './Permissions';
import { Redirect, RouteComponentProps } from 'react-router';
import { ApiRequest } from '../../services/apiClient';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import { AdminSaveButtonClasses, AdminCancelButtonClasses } from '../../services/styleService';
import FormCheckboxField from '../Common/FormCheckboxField';

type UserTypeFormProps = RouteComponentProps<{ userTypeId: string }> & typeof UserTypesStore.actionCreators & UserTypesStore.UserTypesState;

type UserTypeFormState = {
    errors: {[key: string]:string[]},
    success: boolean,
    isEditMode: boolean,
    editedUser: UserTypesStore.UserType
}

class UserTypeForm extends React.PureComponent<UserTypeFormProps, UserTypeFormState> {
    constructor(props: UserTypeFormProps) {
        super(props);

        const userIdNumber = parseInt(this.props.match.params.userTypeId) || 0;
        let editedUser : UserTypesStore.UserType | undefined;
        if (userIdNumber > 0) {
            editedUser = props.userTypes.find((user) => user.id === userIdNumber);
            if (!editedUser) {
                console.warn(`User type with id ${userIdNumber} not loaded`);
            }
        }

        // Creates null object
        if (!editedUser) {
            editedUser = {
                id: 0,
                name: '',
                permissionIds: [],
                infiniteLogin: false
            }
        }

        this.state = {
            errors: {},
            success: false,
            isEditMode: userIdNumber > 0,
            editedUser: editedUser!
        }
    }

    public render() {
        if (this.state.success) {
            return <Redirect to='/admin/user-types' />
        }
        return (
        <form className="form" onSubmit={this.onSubmit.bind(this)}>
            <h3>
                {this.state.isEditMode ? `Edycja Typu uzytkownika ${this.state.editedUser.name}` : "Nowy typ użytkownika" }
            </h3>
            {this.state.isEditMode ? <input type="hidden" name="id" value={this.state.editedUser.id}/> : ''}
            <div className="form-group">
                <label htmlFor="name">Nazwa</label>
                <input defaultValue={this.state.editedUser.name} className={"form-control" + (this.haveErrors('name') ? " is-invalid" : "")} type="text" name="name"/>
                <div className="invalid-feedback">{this.errorText('name')}</div>
            </div>
            <FormCheckboxField value="true" label="Nieskończony login" name="infiniteLogin" defaultChecked={this.state.editedUser.infiniteLogin} />
            <Permissions defaultIds={this.state.editedUser.permissionIds}/>
            <button type="submit" className={AdminSaveButtonClasses}>Zapisz</button>
            <ButtonWithRoute className={AdminCancelButtonClasses} path='/admin/user-types'>Anuluj</ButtonWithRoute>
        </form>
        );
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.validateForm()) {
            await this.submitForm(formEvent.target as HTMLFormElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        const url = this.state.isEditMode ? 'api/usertype/update' : 'api/usertype/create';

        var result = await ApiRequest(url, data);
        if (result.status === 200) {
            this.props.requestUserTypes(true);
            this.setState({success: true});
            return true;
        } else {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
        }
    }

    private errorText(fieldName: string) : string {
        if (this.state.errors[fieldName] && this.state.errors[fieldName][0]) {
            return this.state.errors[fieldName][0];
        } else {
            return '';
        }
    }

    private haveErrors(fieldName: string) : boolean {
        return this.state.errors[fieldName] != null;
    }
}

export default connect(
    (state: ApplicationState) => state.userTypes,
    UserTypesStore.actionCreators
)(UserTypeForm as any);