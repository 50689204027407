import * as React from 'react';
import { ZottTrailerStatus } from '../../types/global';
import FormCheckboxField from '../Common/FormCheckboxField';

type ThisFormProps = {
    currentTrailer: ZottTrailerStatus | undefined
}

export default class UnhitchTrailerFields extends React.PureComponent<ThisFormProps, {}> {

    public render() {
        if (!this.props.currentTrailer) {
            return null;
        }

        const trailerStatus = this.props.currentTrailer;

        return(
            <div>
                <p>Obecna naczepa: {trailerStatus.number}{trailerStatus.isTemporary && " (tymczasowa)"}</p>
                {trailerStatus.isTemporary && <FormCheckboxField value="1" name="releaseTemporaryTrailer" label="Oddaj naczepę tymczasową" />}
            </div>
        );
    }
}