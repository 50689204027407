import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as AuthService from '../services/authService';

export interface AuthorizationState {
    isLogged: boolean;
    permissions: string[]
}

// Actions
interface LogoutAction {
    type: 'LOGOUT'
}

interface LoginRequestAction {
    type: 'LOGIN_REQUEST';
    loginName: string;
    password: string;
}

interface LoginAction {
    type: 'LOGIN';
    authToken: string;
    logoutToken: string;
    permissions: string[];
}

const unloadedState: AuthorizationState = {
    isLogged: AuthService.IsLoggedIn(),
    permissions: AuthService.CurrentUserPermissions()
}

type KnownAction = LogoutAction | LoginRequestAction | LoginAction;

export const actionCreators = {
    login: (authToken:string, logoutToken:string, permissions: string[]) : AppThunkAction<KnownAction> => (dispatch, getState) => {

        AuthService.Login(authToken, logoutToken, permissions);

        dispatch({
            type: 'LOGIN',
            authToken: authToken,
            logoutToken: logoutToken,
            permissions: permissions
        });
    },
    logout: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        AuthService.Logout();
        
        dispatch({
            type: 'LOGOUT'
        });
    }
}

export const reducer: Reducer<AuthorizationState> = (state: AuthorizationState | undefined, incomingAction: Action) : AuthorizationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch(action.type) {
        case 'LOGIN':
            return {
                isLogged: true,
                permissions: action.permissions
            }
        case 'LOGOUT':
            return {
                isLogged: false,
                permissions: []
            }
    }
    return state;
}