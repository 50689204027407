import * as React from 'react';
import FormTextField from '../Common/FormTextField';

export type LocationData = {
    locationName: string,
    companyName: string,
    kilometers: number
}

type ItemValue = {
    locationName: string,
    companyName: string,
    kilometer: number,
}

export type OnChangeEventData = {
    itemValue: ItemValue,
    fieldsNamePrefix: string,
}

type ThisFormProps = {
    fieldsNamePrefix: string,
    showKilometerField?: boolean,
    locationName?: string,
    companyName?: string,
    kilometer?: number,
    data?: LocationData,
    errors?: {[key: string]:string[]},
    onChange?: (event: OnChangeEventData) => void
}

type ThisFormState = ItemValue;

export default class ExtraRouteLocationFields extends React.PureComponent<ThisFormProps, ThisFormState> {

    constructor(props: ThisFormProps) {
        super(props);

        const data = this.props.data;

        this.state = {
            locationName: data ? data.locationName : this.props.locationName || '',
            companyName: data ? data.companyName : this.props.companyName || '',
            kilometer: data ? data.kilometers : this.props.kilometer || 0
        }

        this.onLocationChange = this.onLocationChange.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onKilometerChange = this.onKilometerChange.bind(this);
    }

    public render() {
       const locationName = this.state.locationName;
       const companyName = this.state.companyName;
       const kilometer = this.state.kilometer;

        return(
            <div>
                <FormTextField
                    name={this.props.fieldsNamePrefix+"Location"}
                    label="Lokacja"
                    isMandatory={true}
                    value={locationName}
                    errors={this.props.errors}
                    onChange={this.onLocationChange}/>
                <FormTextField
                    name={this.props.fieldsNamePrefix+"Company"}
                    label="Firma"
                    isMandatory={true}
                    value={companyName}
                    errors={this.props.errors} 
                    onChange={this.onCompanyChange}/>
                {this.props.showKilometerField
                    && <FormTextField
                        type="number"
                        name={this.props.fieldsNamePrefix+"Kilometer"}
                        label="Przebieg"
                        isMandatory={true}
                        value={kilometer}
                        errors={this.props.errors}
                        onChange={this.onKilometerChange}/>}
            </div>
        );
    }

    onLocationChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            locationName: event.currentTarget.value,
        });
        this.callOnChangeEvent(
            event.currentTarget.value,
            this.state.companyName,
            this.state.kilometer
        );
    }

    onCompanyChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            companyName: event.currentTarget.value,
        });
        this.callOnChangeEvent(
            this.state.locationName,
            event.currentTarget.value,
            this.state.kilometer
        );
    }

    onKilometerChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            kilometer: event.currentTarget.valueAsNumber,
        });

        this.callOnChangeEvent(
            this.state.locationName,
            this.state.companyName,
            event.currentTarget.valueAsNumber
        );
    }

    callOnChangeEvent(locationName: string, companyName: string, kilometer: number)
    {
        if (this.props.onChange) {
            this.props.onChange({
                itemValue: {
                    locationName,
                    companyName,
                    kilometer
                },
                fieldsNamePrefix: this.props.fieldsNamePrefix
            });
        }
    }
}