import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiRequest } from '../services/apiClient';

export interface Permission {
    name: string;
    id: number;
};

export interface PermissionsState {
    isLoading: boolean;
    permissions: Permission[];
};

// Actions
interface RequestPermissionsAction {
    type: 'REQUEST_PERMISSIONS';
}

interface ReceivePermissionsAction {
    type: 'RECEIVE_PERMISSIONS';
    permissions: Permission[];
}

const unloadedState: PermissionsState = { permissions: [], isLoading: false };

type KnownAction = RequestPermissionsAction | ReceivePermissionsAction;

export const actionCreators = {
    requestPermissions: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.permissions && appState.permissions.permissions.length === 0) {
            ApiRequest('api/permission')
                .then(response => response.json() as Promise<Permission[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_PERMISSIONS', permissions: data});
                });
            dispatch({ type: 'REQUEST_PERMISSIONS' });
        }
    }
};

export const reducer: Reducer<PermissionsState> = (state: PermissionsState | undefined, incomingAction: Action) : PermissionsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type) {
        case 'REQUEST_PERMISSIONS':
            return {
                permissions: state.permissions,
                isLoading: true
            };
        case 'RECEIVE_PERMISSIONS':
            return {
                permissions: action.permissions,
                isLoading: false
            };
    }
    return state;
};