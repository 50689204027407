import * as React from 'react';
import { connect } from 'react-redux';
import * as UsersStore from '../../store/Users';
import { ApplicationState } from '../../store';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import NotificationMessage from '../Common/NotificationMessage';
import Loader from '../Common/Loader';
import { DangerousOperationButtonClasses } from '../../services/styleService';

type UsersProps = UsersStore.UsersState & typeof UsersStore.actionCreators;
type UsersState = {
    showDeleteConfirmation: boolean,
    deleteConfirmationTitle: string,
    deleteConfirmationText: string,
    idToDelete: number
};


class Users extends React.PureComponent<UsersProps, UsersState> {
    constructor(props: UsersProps) {
        super(props);

        this.state = {
            showDeleteConfirmation: false,
            deleteConfirmationTitle: '',
            deleteConfirmationText: '',
            idToDelete: 0
        }
    }

    public componentDidMount() {
        this.props.requestUsers(false);
    }

    public render() {
        if (this.props.isLoading)
        {
            return <Loader/>;
        }
        if (this.props.users.length === 0) {
            return <div className="row">
                <h3>Brak użytkowników</h3>
                <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/users/new">Nowy użytkownik</ButtonWithRoute>
            </div>
        }
        return (
            <div>
                <div className="row">
                    <h3>Użytkownicy</h3>
                    <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/users/new">Nowy użytkownik</ButtonWithRoute>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Użytkownik</th>
                            <th>Imię i Nazwisko</th>
                            <th>Telefon</th>
                            <th>Typ użytkownika</th>
                            <th>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.users.map((user: UsersStore.User) => 
                            <tr className={user.isActive ? "" : "inactive"} key={user.id}>
                                <td>{user.loginName}</td>
                                <td>{user.firstName + " " + user.lastName}</td>
                                <td>{user.phone}</td>
                                <td>{user.userTypeName}</td>
                                <td className="actions">
                                    <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/admin/users/edit/' + user.id}>Edytuj</ButtonWithRoute>
                                    {user.isActive && <button onClick={() => this.confirmDelete(user)} className={DangerousOperationButtonClasses}>Usuń</button>}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <NotificationMessage show={this.state.showDeleteConfirmation}
                    id="deleteConfirmation"
                    title={this.state.deleteConfirmationTitle}
                    message={this.state.deleteConfirmationText}
                    cancelButtonText="Anuluj"
                    okButtonText="Usuń"
                    cancelFunction={this.cancelDelete.bind(this)}
                    confirmationFunction={() => this.doDelete(this.state.idToDelete)} />
            </div>
        );
    }

    private confirmDelete(user: UsersStore.User) {

        this.setState({
            showDeleteConfirmation: true,
            deleteConfirmationTitle: "Potwierdzenie usunięcia",
            deleteConfirmationText: "Usunąć użytkownika " + user.loginName + " ?",
            idToDelete: user.id
        });
    }

    private cancelDelete() {
        this.setState({showDeleteConfirmation: false});
    }

    private async doDelete(id: number) {
        this.setState({showDeleteConfirmation: false});
        await this.props.deleteUser(id);
        this.props.requestUsers(true);
    }
}

// TODO - merge also userTypes into props and their actions
export default connect(
    (state: ApplicationState) => state.users, // Selects which state properties are merged into the component's props
    UsersStore.actionCreators
)(Users as any);
