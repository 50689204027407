import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { TransportStatus, StringDictionary } from '../../types/global';
import Loader from '../Common/Loader';
import FormSelect from '../Common/FormSelect';
import FormTextField from '../Common/FormTextField';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import SortableTable from '../Common/SortableTable';
import * as DaoHelper from '../../helpers/DaoHelper';

type ThisFormProps = {

};

type ThisFormState = {
    transportsStatuses: TransportStatus[] | undefined,
    error: string,
    isLoading: boolean,
    isLoaded: boolean,
    selectedDaysNumber: string,
    daySelectKey: string,
    currentWz: string,
    wzInputKey: string
}

export default class TransportsStatus extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            transportsStatuses: undefined,
            error: '',
            isLoading: false,
            isLoaded: true,
            selectedDaysNumber: '-1',
            daySelectKey: 'daySelect',
            currentWz: '',
            wzInputKey: 'wzKey'
        }
    }

    readonly rangeSelectName : string = 'days';
    readonly searchByWzString : string = '-100';

    public render() {
        if (this.state.isLoading)
        {
            return <Loader/>;
        }
        if (!this.state.isLoaded)
        {
            return null;
        }

        return (
            <div>
                <div className="row">
                    <h3>Status transportów</h3>
                </div>
                <div className="row align-items-center">
                    <form className="form" onSubmit={this.fetchReports.bind(this)}>
                        <FormSelect onChange={this.onDaysSelected.bind(this)} name={this.rangeSelectName} key={this.state.daySelectKey} label="Zakres"
                            values={this.daysOptions()} keysOrder={this.daysOptionsOrder()}
                            defaultValue={this.state.selectedDaysNumber} />
                        {this.isSearchByWzSelected() && <FormTextField defaultValue={this.state.currentWz} key={this.state.wzInputKey} label="Wz" name="wz" type="number"/>}
                        {this.isSearchByWzSelected() && <button type='submit' className="btn btn-outline-secondary btn-block">Pobierz</button>}
                    </form>
                </div>
                <SortableTable dataRows={this.state.transportsStatuses} columns={this.transportsStatusColumns()}
                    renderActions={this.renderActions} renderNoData={this.renderNoData}/>
            </div>
        );
    }

    public componentDidMount() {
        const formData = new FormData();
        formData.set(this.rangeSelectName, this.state.selectedDaysNumber);

        this.fetchTransports(formData);
    }

    public isSearchByWzSelected() : boolean {
        return this.state.selectedDaysNumber === this.searchByWzString;
    }

    public fetchReports(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();
        const formElement = formEvent.target as HTMLFormElement;
        const formData = new FormData(formElement);

        this.fetchTransports(formData);
    }

    private onDaysSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;

        this.setState({
            selectedDaysNumber: value
        });

        if (value !== this.searchByWzString) {

            const formData = new FormData();
            formData.set(this.rangeSelectName, value);

            this.fetchTransports(formData);
        }
    }

    private daysOptions() : StringDictionary {
        const daysOptions : StringDictionary = {};

        daysOptions['-1'] = 'Nieukończone'
        daysOptions['1'] = 'Ukończone dzisiejsze';
        daysOptions['2'] = 'Ukończone 2 dni';
        daysOptions['3'] = 'Ukończone 3 dni';
        daysOptions['5'] = 'Ukończone 5 dni';
        daysOptions['7'] = 'Ukończone 7 dni';
        daysOptions[this.searchByWzString] = 'Szukaj po numerze WZ';

        return daysOptions;
    }

    private daysOptionsOrder() : string[] {
        return ['-1', '1', '2', '3', '5', '7', this.searchByWzString];
    }

    private transportsStatusColumns() : StringDictionary
    {
        const columns = {} as StringDictionary;

        columns['finishedOnDate'] = 'Zakończono';
        columns['cargoType'] = 'Ładunek';
        columns['wz'] = 'WZ';
        columns['status'] = 'Status';
        columns['locationTitle'] = 'Lokacja';
        columns['driverName'] = 'Kierowca';
        columns['tractorRegNumber'] = 'Ciągnik';
        columns['trailerNumber'] = 'Naczepa';
        columns['actions'] = 'Akcje';

        return columns;
    }

    private renderActions(row: any) {
        return (
            <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/zott/transportlog/' + row.id}>Historia</ButtonWithRoute>
        );
    }

    private renderNoData() {
        return (
            <p>Brak transportów</p>
        );
    }

    private async fetchTransports(formData: FormData)
    {
        this.setState({
            isLoading: true
        });

        const currentWz = formData.get('wz') as string;
        const result = await ApiRequest('api/zott/transportsstatus', formData);

        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            this.setState({
                error: 'Bład pobierania statusu transportów'
            });
            return;
        }

        const transportsStatuses = await DaoHelper.JsonParseResult(result) as TransportStatus[];

        if (transportsStatuses) {
            DaoHelper.EnhanceTransportStatusCollection(transportsStatuses);
        }

        this.setState({
            isLoaded: true,
            transportsStatuses: transportsStatuses,
            currentWz: this.state.selectedDaysNumber === this.searchByWzString ? currentWz : this.state.currentWz,
            wzInputKey: 'wzKey' + Date.now()
        });
    }
}