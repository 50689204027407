import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { StringDictionary, TrailerCleaningReportEntry, DataLoadingStateEnum } from '../../types/global';
import moment from 'moment';
import Loader from '../Common/Loader';
import SortableTable from '../Common/SortableTable';
import { ExportCSV } from '../Common/ExportCSVButton';
import * as DaoHelper from '../../helpers/DaoHelper';
import "react-datepicker/dist/react-datepicker.css";
import FormGeneralErrors from '../Common/FormGeneralErrors';
import FormDatePicker from '../Common/FormDatePicker';
import { FileDateFormat, DateToDateString } from '../../helpers/DateFormatter';

type ReportData = {
    fromDate: Date,
    toDate: Date
}

type ThisComponentProps = {
};

type ThisComponentState = {
    fromDate: Date,
    toDate: Date,
    dataLoadingState: DataLoadingStateEnum,
    errors: {[key: string]:string[]},
    showForm: boolean,
    currentReportData: ReportData | undefined,
    rows?: TrailerCleaningReportEntry[]
}

export default class TrailerCleaningsReport extends React.PureComponent<ThisComponentProps, ThisComponentState> {
    constructor(props: ThisComponentProps) {
        super(props);

        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
            dataLoadingState: DataLoadingStateEnum.Initial,
            errors: {},
            showForm: true,
            currentReportData: undefined,
        }
    }

    public render() {
        if (this.state.dataLoadingState === DataLoadingStateEnum.Loading)
        {
            return <Loader/>;
        }
        return(
            <div className="container">
                <h1>Mycia naczep</h1>
                <FormGeneralErrors errors={this.state.errors}/>
                {this.state.showForm &&
                <form className="form" onSubmit={this.getReportData.bind(this)}>
                    <FormDatePicker errors={this.state.errors} label="Od" name="fromDate" selected={this.state.fromDate} onChange={this.onFromDateChange.bind(this)}/>
                    <FormDatePicker errors={this.state.errors} label="Do" name="toDate" selected={this.state.toDate} onChange={this.onToDateChange.bind(this)}/>
                    <div className="form-group">
                        <button type="submit" className="btn btn-outline-secondary btn-sm ml-4">Generuj raport</button>
                    </div>
                </form>
                }            
                {!this.state.showForm && <button onClick={() => this.showForm()} className="btn btn-outline-secondary btn-sm ml-4">Kolejny raport</button>}
                {this.hasReportData() && <ExportCSV className="btn btn-outline-secondary btn-sm ml-4" label="Eksportuj do Excela" csvData={this.dataForExcel()} fileName={this.reportFileName()}/>}
                {this.hasReportData() && <p>{this.reportTitle()}</p>}
                {this.state.dataLoadingState === DataLoadingStateEnum.Loaded
                    && <SortableTable idKey="lp" disableSorting={true} renderNoData={this.renderNoData} dataRows={this.state.rows} columns={this.dataColumns()} />}
            </div>
        );
    }

    private showForm() {
        this.setState({
            showForm: true
        });
    }

    private async getReportData(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        const formElement = formEvent.target as HTMLFormElement;
        const data = new FormData(formElement);

        const reportData : ReportData = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }

        this.setState({
            dataLoadingState: DataLoadingStateEnum.Loading
        });

        const result = await ApiRequest('api/reports/trailer-cleanings', data);
        if (result.status !== 200) {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({
                    errors: resultData.errors,
                    dataLoadingState: DataLoadingStateEnum.Loaded
                });
            }
            return;
        }

        const rows = await DaoHelper.JsonParseResult(result) as TrailerCleaningReportEntry[];

        DaoHelper.EnhanceTrailerCleaningsReportCollection(rows);

        var showForm = !rows || !rows.length;

        this.setState({
            errors: {},
            rows: rows,
            dataLoadingState: DataLoadingStateEnum.Loaded,
            currentReportData: reportData,
            showForm: showForm
        });
    }

    private dataColumns() {
        const columns = {} as StringDictionary;

        columns['lp'] = 'L.p.';
        columns['dateTimeText'] = 'Data';
        columns['trailerNumber'] = 'Naczepa';
        columns['tractorRegistrationNumber'] = 'Ciągnik';
        columns['cleaningDriverName'] = 'Kierowca';
        columns['trailerTypeText'] = 'Typ naczepy';

        return columns;
    }

    private onFromDateChange(date: Date) {
        this.setState({
            fromDate: date
        });
    }

    private onToDateChange(date: Date) {
        this.setState({
            toDate: date
        });
    }

    private hasReportData() : boolean
    {
        return !!this.state.rows
            && this.state.rows.length > 0;
    }

    private dataForExcel()
    {
        return this.state.rows!.map(this.rowToExcel);
    }

    private rowToExcel(tc: TrailerCleaningReportEntry)
    {
        return {
            'Data': tc.dateTimeText,
            'Naczepa': tc.trailerNumber,
            'Ciągnik': tc.tractorRegistrationNumber,
            'Kierowca': tc.cleaningDriverName,
            'Typ naczepy': tc.trailerTypeText
        };
    }

    private reportFileName() : string
    {
        var reportData = this.state.currentReportData;
        if (!reportData) return '';

        let fileName : string = 'RaportMyciaNaczep_' + moment(reportData!.fromDate).format(FileDateFormat) + '_' + moment(reportData!.toDate).format(FileDateFormat);

        return fileName;
    }

    private reportTitle() : string {
        if (!this.state.currentReportData) return '';

        const reportData = this.state.currentReportData!;

        let title : string = 'Raport mycia naczep ' + DateToDateString(reportData.fromDate) + '-' + DateToDateString(reportData.toDate);
        
        return title;
    }

    private renderNoData() {
        return (
            <p>Brak tankowań</p>
        );
    }
}
