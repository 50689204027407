import * as React from 'react';
import { ZottLocationEnum, ZottTrailerStatus, TrailerZottStatusEnum } from '../../types/global';
import { Alert } from 'reactstrap';

type TrailerStatusInfoProps = {
    trailerStatus: ZottTrailerStatus | undefined,
    showLocation: boolean | undefined
}

export default class TrailerStatusInfo extends React.PureComponent<TrailerStatusInfoProps, {}> {
    public render() {
        const trailerStatus = this.props.trailerStatus;
        if (!trailerStatus) {
            return null;
        }

        return(
            <div>
                <p><span className="strong">Status naczepy: </span>{trailerStatus.status}</p>
                {this.props.showLocation && <p><span className="strong">Lokacja: </span>{trailerStatus.locationTitle}</p>}
                {this.canShowCargoType() && <p><span className="strong">Ładunek: </span>{trailerStatus.cargoType}</p>}
                {this.canShowWz() && <p><span className="strong">WZ: </span>{trailerStatus.wz}</p>}
                {this.canShowRampNumber() && <p><span className="strong">Numer rampy: </span>{trailerStatus.rampNumber}</p>}
                {trailerStatus.failure && <Alert color="danger">Awaria</Alert>}
                {trailerStatus.service && <Alert color="warning">Serwis</Alert>}
            </div>
        );
    }

    private canShowCargoType() : boolean {
        const trailerStatus = this.props.trailerStatus;
        return trailerStatus!.isLoaded || trailerStatus!.statusId === TrailerZottStatusEnum.ReturnFromExtra;
    }

    private canShowWz() : boolean {
        const trailerStatus = this.props.trailerStatus;

        return trailerStatus!.isLoaded || trailerStatus!.statusId === TrailerZottStatusEnum.ReturnFromExtra;
    }

    private canShowRampNumber() {
        const trailerStatus = this.props.trailerStatus;
        return trailerStatus!.zottLocationEnum === ZottLocationEnum.Fresh
            && trailerStatus!.rampNumber;
    }
}