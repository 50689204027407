import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { BriefOperationLogEntry } from '../../types/global';
import { ApiRequest } from '../../services/apiClient';
import { Alert } from 'reactstrap';
import { Driver } from '../../store/Drivers';
import Loader from '../Common/Loader';
import FormDatePicker from '../Common/FormDatePicker';
import FormTextField from '../Common/FormTextField';
import { JsonParseResult, EnhanceBriefOperationLogs } from '../../helpers/DaoHelper';
import { DateToDateString } from '../../helpers/DateFormatter';
import OperationLogsTable from '../Zott/OperationLogsTable';

type DriverOperationLogResponse = {
    driver: Driver,
    logs: BriefOperationLogEntry[],
    dateFrom: Date,
    dateTo: Date
}

type DriverOperationLogProps = RouteComponentProps<{ driverId: string }>;

type DriverOperationLogState = {
    driverId: number,
    isLoading: boolean,
    isLoaded: boolean,
    driver: Driver | undefined,
    driverLogs: BriefOperationLogEntry[],
    dateRangeText: string
    error: string,
    showForm: boolean,
    dateUntil: Date,
    daysRange: string,
    daysRangeKey: string
};

export default class DriverOperationLog extends React.PureComponent<DriverOperationLogProps, DriverOperationLogState> {
    constructor(props: DriverOperationLogProps) {
        super(props);

        const id = parseInt(this.props.match.params.driverId) || 0;
        this.state = {
            driverId: id,
            isLoading: false,
            isLoaded: false,
            driver: undefined,
            driverLogs: [],
            dateRangeText: '',
            error: '',
            showForm: false,
            dateUntil: new Date(),
            daysRange: '7',
            daysRangeKey: 'daysRange'
        }
    }

    public render() {
        if (this.state.isLoading) {
            return <Loader/>;
        }
        if (!this.state.isLoaded) {
            return null;
        }
        return (
            <div>
                <div className="row">
                    <h3>Historia operacji kierowcy {this.driverDescription()}</h3>
                </div>
                {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                {this.state.showForm &&
                    <form className="form" onSubmit={this.fetchDriverLogsFromForm.bind(this)}>
                        <FormDatePicker label="Do dnia" name="dateUntil" selected={this.state.dateUntil} onChange={this.onDateUntilChange.bind(this)}/>
                        <FormTextField key={this.state.daysRangeKey} min={1} label="Liczba dni" type="number" name="daysRange" defaultValue={this.state.daysRange}/>
                        <div className="form-group">
                            <button type="submit" className="btn btn-outline-secondary btn-sm ml-4">Pobierz</button>
                        </div>
                    </form>
                }
                {!this.state.showForm && <button onClick={() => this.showForm()} className="btn btn-outline-secondary btn-sm ml-4">Zmień zakres</button>}
                <OperationLogsTable logs={this.state.driverLogs} />
            </div>
        );
    }

    public componentDidMount() {
        this.fetchDriverLogs();
    }

    private showForm() {
        this.setState({
            showForm: true
        });
    }

    private fetchDriverLogsFromForm(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        let formElement = formEvent.target as HTMLFormElement;
        let data = new FormData(formElement);

        this.fetchDriverLogs(data);
    }

    private onDateUntilChange(date: Date) {
        this.setState({
            dateUntil: date
        });
    }

    private driverDescription() {
        if (!this.state.driver) {
            return '';
        }

        return `${this.state.driver.name} ${this.state.dateRangeText}`;
    }

    private async fetchDriverLogs(data?: FormData)
    {
        this.setState({
            isLoading: true,
            showForm: false
        });

        if (data) {
            this.setState({
                daysRange: data.get('daysRange') as string,
                daysRangeKey: 'daysRange' + Date.now()
            });
        }

        const formData = data ? data : new FormData();
        formData.set('driverId', this.state.driverId.toString());

        const response = await ApiRequest('api/zott/driveroperationlog', formData);

        if (response.status !== 200) {
            this.setState({
                isLoading: false,
                isLoaded: true,
                error: 'Bład wczytywania logu kierowcy'
            });
            return;
        }

        const responseData = await JsonParseResult(response) as DriverOperationLogResponse;

        EnhanceBriefOperationLogs(responseData.logs);

        this.setState({
            isLoaded: true,
            isLoading: false,
            driver: responseData.driver,
            driverLogs: responseData.logs,
            dateRangeText: `${DateToDateString(responseData.dateFrom)}-${DateToDateString(responseData.dateTo)}`
        });
    }
}