import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { ZottDriverStatus, StringDictionary } from '../../types/global';
import Loader from '../Common/Loader';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import SortableTable from '../Common/SortableTable';

type DriverStatusRow = {
    id: number,
    name: string,
    status: string,
    locationTitle: string,
    regNumber: string,
    trailerNumber: string,
    cargoType: string,
    wz: string,
    rampNumber: string
}

type ZottDriversStatusProps = {
};

type ZottDriversStatusState = {
    driversStatuses: ZottDriverStatus[] | undefined,
    driversRows: DriverStatusRow[] | undefined,
    error: string,
    isLoading: boolean,
    isLoaded: boolean,
}


export default class ZottDriversStatus extends React.PureComponent<ZottDriversStatusProps, ZottDriversStatusState> {
    constructor(props: ZottDriversStatusProps) {
        super(props);

        this.state = {
            driversStatuses: undefined,
            driversRows: undefined,
            error: '',
            isLoading: false,
            isLoaded: false,
        }
    }

    public render() {
        if (this.state.isLoading)
        {
            return <Loader/>;
        }
        if (!this.state.isLoaded)
        {
            return null;
        }
        if (this.state.driversStatuses && this.state.driversStatuses!.length === 0) {
            return <div className="row">
                <h3>Status kierowców - Brak kierowców</h3>
            </div>;
        }

        return (
            <div>
                <div className="row">
                    <h3>Status kierowców</h3>
                </div>
                <SortableTable dataRows={this.state.driversRows} columns={this.driverStatusColumns()} renderActions={(row) => <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/zott/driverlog/' + row.id}>Historia</ButtonWithRoute>}/>
            </div>
        );
    }

    public componentDidMount() {
        this.fetchDriversStatus();
    }

    private driverStatusColumns() : StringDictionary {
        const columns = {} as StringDictionary;

        columns['name'] = 'Kierowca';
        columns['status'] = 'Status';
        columns['locationTitle'] = 'Lokacja';
        columns['regNumber'] = 'Ciągnik';
        columns['trailerNumber'] = 'Naczepa';
        columns['cargoType'] = 'Ładunek';
        columns['wz'] = 'WZ';
        columns['rampNumber'] = 'Nr rampy';
        columns['actions'] = 'Akcje';

        return columns;
    }

    private async fetchDriversStatus()
    {
        this.setState({
            isLoading: true
        });

        const result = await ApiRequest('api/zott/driversstatus');

        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            this.setState({
                error: 'Bład pobierania statusu kierowców'
            });
            return;
        }

        const driversStatuses = await result.json() as ZottDriverStatus[];
        const driversRows = driversStatuses && driversStatuses.map((ds) => ({
                id: ds.driverId,
                name: ds.driverName,
                status: ds.status,
                locationTitle: ds.locationTitle,
                regNumber: ds.tractorRegNumber,
                trailerNumber: ds.trailerStatus ? ds.trailerStatus.number : '',
                cargoType: ds.trailerStatus ? ds.trailerStatus.cargoType : '',
                wz: ds.trailerStatus ? ds.trailerStatus.wz : '',
                rampNumber: ds.trailerStatus ? ds.trailerStatus.rampNumber : ''
            }) as DriverStatusRow
        );

        this.setState({
            isLoaded: true,
            driversStatuses: driversStatuses,
            driversRows: driversRows
        });
    }
}