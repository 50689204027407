import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiRequest } from '../services/apiClient';
import { ZottTrailerStatus, ZottPanelDriverStatus } from '../types/global';

export interface ZottPanelDriverStatusStore {
    zottPanelDriverStatus: ZottPanelDriverStatus | undefined,
    isLoading: boolean,
    errors: {[key: string]:string[]},
    trailersToHitchStates: ZottTrailerStatus[],
    fetchTrailersError: string
}

// Actions
interface RequestPanelDriverStatusAction {
    type: 'REQUEST_PANEL_DRIVER_STATUS';
}

interface ReceivePanelDriverStatusAction {
    type: 'RECEIVE_PANEL_DRIVER_STATUS';
    zottPanelDriverStatus: ZottPanelDriverStatus
}

interface PanelDriverStatusErrorsAction {
    type: 'PANEL_DRIVER_STATUS_ERRORS';
    errors: {[key: string]:string[]};
}

interface RequestTrailersToHitchAction {
    type: 'REQUEST_TRAILERS_TO_HITCH'
}

interface ReceiveTrailersToHitchAction {
    type: 'RECEIVE_TRAILERS_TO_HITCH';
    trailersToHitchStates: ZottTrailerStatus[]
}

interface ReceiveTrailersErrorAction {
    type: 'RECEIVE_TRAILERS_ERROR';
    error: string;
}

interface ConfirmMessageAction {
    type: 'CONFIRM_MESSAGE';
    id: number;
}

const defaultStoreState : ZottPanelDriverStatusStore = {
    zottPanelDriverStatus: undefined,
    isLoading: false,
    errors: {},
    trailersToHitchStates: [],
    fetchTrailersError: ''
}

type KnownAction = RequestPanelDriverStatusAction
    | ReceivePanelDriverStatusAction
    | PanelDriverStatusErrorsAction
    | RequestTrailersToHitchAction
    | ReceiveTrailersToHitchAction
    | ReceiveTrailersErrorAction
    | ConfirmMessageAction;

export const actionCreators = {
    requestDriverStatus: () : AppThunkAction<KnownAction> => async (dispatch, getState) => {

        dispatch({
            type: 'REQUEST_PANEL_DRIVER_STATUS'
        });

        const result = await ApiRequest('/api/zott/getdriverstatus');

        if (result.status === 200)
        {
            const panelDriverStatus = await result.json() as ZottPanelDriverStatus;

            dispatch({
                type: 'RECEIVE_PANEL_DRIVER_STATUS',
                zottPanelDriverStatus: panelDriverStatus,
            })
        } else if (result.status === 400) {
            const resultData = await result.json();
            if (resultData.errors) {
                dispatch({
                    type: 'PANEL_DRIVER_STATUS_ERRORS',
                    errors: resultData.errors
                });
            }
        }
    },

    requestTrailersToHitch: () : AppThunkAction<KnownAction> => async (dispatch, getState) => {

        dispatch({
            type: 'REQUEST_PANEL_DRIVER_STATUS'
        });

        const response = await ApiRequest('api/zott/gethitchtrailersstatus');

        if (response.status === 200)
        {
            const trailersStates = await response.json() as ZottTrailerStatus[];

            if (trailersStates.length === 0) {
                dispatch({
                    type: 'RECEIVE_TRAILERS_ERROR',
                    error: 'Brak naczep w bieżącej lokacji'
                });
                dispatch({
                    type: 'RECEIVE_TRAILERS_TO_HITCH',
                    trailersToHitchStates: trailersStates
                });
            } else {
                dispatch({
                    type: 'RECEIVE_TRAILERS_TO_HITCH',
                    trailersToHitchStates: trailersStates
                });
            }
        } else {
            const resultData = await response.json();
            if (resultData.errors) {
                dispatch({
                    type: 'PANEL_DRIVER_STATUS_ERRORS',
                    errors: resultData.errors
                });
            } else {
                // TODO - Maybe set some error
            }
        }
    },

    confirmMessage: (id: number) : AppThunkAction<KnownAction> => async (dispatch, getState) => {

        var formData = new FormData();

        formData.set('id', id.toString());

        const response = await ApiRequest('api/zott/confirmmessage', formData);

        if (response.status === 200) {
            dispatch({
                type: 'CONFIRM_MESSAGE',
                id: id
            });
        }
    },
}

export const reducer: Reducer<ZottPanelDriverStatusStore> = (state: ZottPanelDriverStatusStore | undefined, incomingAction: Action) : ZottPanelDriverStatusStore => {
    if (state === undefined) {
        return defaultStoreState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type) {
        case 'REQUEST_PANEL_DRIVER_STATUS':
            return {
                zottPanelDriverStatus: state.zottPanelDriverStatus,
                isLoading: true,
                errors: {},
                trailersToHitchStates: state.trailersToHitchStates,
                fetchTrailersError: state.fetchTrailersError
            };
        case 'RECEIVE_PANEL_DRIVER_STATUS':
            return {
                zottPanelDriverStatus: action.zottPanelDriverStatus,
                isLoading: false,
                errors: {},
                trailersToHitchStates: state.trailersToHitchStates,
                fetchTrailersError: state.fetchTrailersError
            };
        case 'PANEL_DRIVER_STATUS_ERRORS':
            return {
                zottPanelDriverStatus: undefined,
                isLoading: false,
                errors: action.errors,
                trailersToHitchStates: state.trailersToHitchStates,
                fetchTrailersError: state.fetchTrailersError
            };
        case 'REQUEST_TRAILERS_TO_HITCH':
            return {
                zottPanelDriverStatus: state.zottPanelDriverStatus,
                isLoading: state.isLoading,
                errors: state.errors,
                trailersToHitchStates: [],
                fetchTrailersError: ''
            };
        case 'RECEIVE_TRAILERS_TO_HITCH':
            return {
                zottPanelDriverStatus: state.zottPanelDriverStatus,
                isLoading: state.isLoading,
                errors: state.errors,
                trailersToHitchStates: action.trailersToHitchStates,
                fetchTrailersError: ''
            };
        case 'CONFIRM_MESSAGE':
            var zottPanelDriverStatus = state.zottPanelDriverStatus;

            if (zottPanelDriverStatus && zottPanelDriverStatus!.messages)
            {
                zottPanelDriverStatus!.messages = zottPanelDriverStatus!.messages.filter((value) => value.id !== action.id);
            }

            return {
                zottPanelDriverStatus: zottPanelDriverStatus,
                isLoading: state.isLoading,
                errors: state.errors,
                trailersToHitchStates: state.trailersToHitchStates,
                fetchTrailersError: state.fetchTrailersError
            };
    }
    return state!;
};