import * as React from 'react';
import { connect } from 'react-redux';
import { ApiRequest } from '../../services/apiClient';
import { Redirect, RouteComponentProps } from 'react-router';
import { Driver } from '../../store/Drivers';
import ButtonWithRoute from '../Common/ButtonWithRoute';

interface UserTypeDriversDAO {
    userTypeId: number
    userTypeName: string;
    allDrivers: Driver[];
    userTypeDriverIds: number[];
}

type UserTypeDriversFormProps = RouteComponentProps<{ userTypeId: string }>;

type UserTypeDriversFormState = {
    userTypeId: number,
    userTypeName: string,
    errors: {[key: string]:string[]},
    drivers: Driver[],
    userTypeDriverIds: number[],
    success: boolean
}

class UserTypeDriversForm extends React.PureComponent<UserTypeDriversFormProps, UserTypeDriversFormState> {
    constructor(props: UserTypeDriversFormProps) {
        super(props);

        this.state = {
            userTypeId: 0,
            userTypeName: "",
            errors: {},
            drivers: [],
            userTypeDriverIds: [],
            success: false
        }
    }

    public componentDidMount() {
        this.fetchAllDriversAndUserTypeDrivers();
    }

    public render() {
        let backUrl = '/admin/user-types';
        if (this.state.success) {
            return <Redirect to={backUrl} />
        }
        return (
        <form className="form" onSubmit={this.onSubmit.bind(this)}>
            <input type="hidden" name="userTypeId" value={this.props.match.params.userTypeId}/>
            <h3>{this.state.userTypeName} - wybór kierowców</h3>
            <div>
                {this.state.drivers.map((driver: Driver) => 
                    <div key={driver.id} className="form-check form-group">
                        <input defaultChecked={this.isUserTypeDriver(driver.id)} className="form-check-input" type="checkbox" id={"driver_" + driver.id} name="driverIds" value={driver.id}/>
                        <label className="form-check-label" htmlFor={"driver_" + driver.id}>{driver.name}</label>
                        <div className="invalid-feedback">{this.errorText("driver_" + driver.id)}</div>
                    </div>
                )}
            </div>
            <div className="row-form">
                <button type="submit" className="btn btn-primary">Zapisz</button>
                <ButtonWithRoute className="btn btn-outline-secondary ml-2" path={backUrl}>Anuluj</ButtonWithRoute>
            </div>
        </form>
        );
    }

    private isUserTypeDriver(driverId: number) : boolean {
        return this.state.userTypeDriverIds.indexOf(driverId) >= 0;
    }

    private fetchAllDriversAndUserTypeDrivers() {

        var userTypeData = new FormData();
        var userTypeId = this.props.match.params.userTypeId;

        userTypeData.append('userTypeId', userTypeId);
        ApiRequest('api/userType/getDriversFormData', userTypeData)
            .then(response => response.json() as Promise<UserTypeDriversDAO>)
            .then(userTypeDrivers => this.setState({
                userTypeId: userTypeDrivers.userTypeId,
                userTypeName: userTypeDrivers.userTypeName,
                drivers: userTypeDrivers.allDrivers,
                userTypeDriverIds: userTypeDrivers.userTypeDriverIds
            }));
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.validateForm()) {
            await this.submitForm(formEvent.target as HTMLFormElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        var result = await ApiRequest('api/usertype/updatedrivers', data);

        if (result.status === 200) {
            this.setState({success: true});
            return true;
        } else {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
        }
    }

    private errorText(fieldName: string) : string {
        if (this.state.errors[fieldName] && this.state.errors[fieldName][0]) {
            return this.state.errors[fieldName][0];
        } else {
            return '';
        }
    }
}

export default connect(
    null,
    null
)(UserTypeDriversForm as any);