import * as React from 'react';
import { connect } from 'react-redux';
import * as DriversStore from '../../store/Drivers';
import { ApplicationState } from '../../store';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import NotificationMessage from '../Common/NotificationMessage';
import { DangerousOperationButtonClasses } from '../../services/styleService';

type DriversProps = DriversStore.DriversState & typeof DriversStore.actionCreators;
type DriversState = {
    showDeleteConfirmation: boolean,
    deleteConfirmationTitle: string,
    deleteConfirmationText: string,
    idToDelete: number
};


class Drivers extends React.PureComponent<DriversProps, DriversState> {
    constructor(props: DriversProps) {
        super(props);

        this.state = {
            showDeleteConfirmation: false,
            deleteConfirmationTitle: '',
            deleteConfirmationText: '',
            idToDelete: 0
        }
    }

    public componentDidMount() {
        this.props.requestDrivers(true);
    }

    public render() {
        if (this.props.drivers.length === 0) {
            return <div className="row">
                <h3>Brak kierowców</h3>
                <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/drivers/add">Dodaj kierowców</ButtonWithRoute>
            </div>
        }
        return (
            <div>
                <div className="row">
                    <h3>Kierowcy</h3>
                    <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/drivers/add">Dodaj kierowców</ButtonWithRoute>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Imię i nazwisko</th>
                            <th>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.drivers.map((driver: DriversStore.Driver) => 
                            <tr key={driver.id}>
                                <td>{driver.name}</td>
                                <td className="actions">
                                    <button onClick={() => this.confirmDelete(driver)} className={DangerousOperationButtonClasses}>Usuń</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <NotificationMessage show={this.state.showDeleteConfirmation}
                    id="deleteConfirmation"
                    title={this.state.deleteConfirmationTitle}
                    message={this.state.deleteConfirmationText}
                    cancelButtonText="Anuluj"
                    okButtonText="Usuń"
                    cancelFunction={this.cancelDelete.bind(this)}
                    confirmationFunction={() => this.doDelete(this.state.idToDelete)} />
            </div>
        );
    }

    private confirmDelete(driver: DriversStore.Driver) {

        this.setState({
            showDeleteConfirmation: true,
            deleteConfirmationTitle: "Potwierdzenie usunięcia",
            deleteConfirmationText: "Usunąć kierowcę " + driver.name + " ?",
            idToDelete: driver.id
        });
    }

    private cancelDelete() {
        this.setState({showDeleteConfirmation: false});
    }

    private async doDelete(id: number) {
        this.setState({showDeleteConfirmation: false});
        await this.props.deleteDriver(id);
        this.props.requestDrivers(true);
    }
}

export default connect(
    (state: ApplicationState) => state.drivers, // Selects which state properties are merged into the component's props
    DriversStore.actionCreators
)(Drivers as any);
