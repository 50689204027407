import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as UserTypesStore from '../../store/UserTypes';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import NotificationMessage from '../Common/NotificationMessage';
import { DangerousOperationButtonClasses } from '../../services/styleService';

const mydriversPermissionId: number = 5;

type UserTypesProps = UserTypesStore.UserTypesState &
    typeof UserTypesStore.actionCreators;

type UserTypesState = {
    showDeleteConfirmation: boolean,
    deleteConfirmationTitle: string,
    deleteConfirmationText: string,
    idToDelete: number
}

class UserTypes extends React.PureComponent<UserTypesProps, UserTypesState> {
    constructor(props: UserTypesProps) {
        super(props);

        this.state = {
            showDeleteConfirmation: false,
            deleteConfirmationTitle: '',
            deleteConfirmationText: '',
            idToDelete: 0
        }
    }
    
    public componentDidMount() {
        this.props.requestUserTypes(false);
    }

    public render() {
        if (this.props.userTypes.length === 0) {
            return <div className="row">
                <h3>Brak typów użytkowników</h3>
                <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/user-types/new">Nowy typ użytkownika</ButtonWithRoute>
            </div>
        }
        return (
            <div>
                <div className="row">
                    <h3>Typy użytkowników</h3>
                    <ButtonWithRoute className="btn btn-outline-secondary btn-sm ml-4" path="/admin/user-types/new">Nowy typ użytkownika</ButtonWithRoute>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Nazwa</th>
                            <th>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.userTypes.map((userType: UserTypesStore.UserType) => 
                            <tr key={userType.id}>
                                <td>{userType.name}</td>
                                <td className="actions">
                                    <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/admin/user-types/edit/' + userType.id}>Edytuj</ButtonWithRoute>
                                    {this.canUserTypeHaveDrivers(userType) && <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/admin/user-types/editdrivers/' + userType.id}>Kierowcy</ButtonWithRoute>}
                                    <button onClick={() => this.confirmDelete(userType)} className={DangerousOperationButtonClasses}>Usuń</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <NotificationMessage show={this.state.showDeleteConfirmation}
                    id="deleteConfirmation"
                    title={this.state.deleteConfirmationTitle}
                    message={this.state.deleteConfirmationText}
                    cancelButtonText="Anuluj"
                    okButtonText="Usuń"
                    cancelFunction={this.cancelDelete.bind(this)}
                    confirmationFunction={() => this.doDelete(this.state.idToDelete)} />
            </div>
        );
    }

    private canUserTypeHaveDrivers(userType: UserTypesStore.UserType) : boolean
    {
        return userType.permissionIds.indexOf(mydriversPermissionId) >= 0;
    }

    private cancelDelete() {
        this.setState({showDeleteConfirmation: false});
    }

    private async doDelete(id: number) {
        this.setState({showDeleteConfirmation: false});
        await this.props.deleteUserType(id);
        this.props.requestUserTypes(true);
    }

    private confirmDelete(userType: UserTypesStore.UserType) {

        this.setState({
            showDeleteConfirmation: true,
            deleteConfirmationTitle: "Potwierdzenie usunięcia",
            deleteConfirmationText: "Usunąć typ użytkownika " + userType.name + " ?",
            idToDelete: userType.id
        });
    }
}

export default connect(
    (state: ApplicationState) => state.userTypes, // Selects which state properties are merged into the component's props
    UserTypesStore.actionCreators
)(UserTypes as any);