import * as React from 'react';
import { Redirect } from 'react-router';
import FormAreaField from '../Common/FormAreaField';
import { ApiRequest } from '../../services/apiClient';
import ButtonWithRoute from '../Common/ButtonWithRoute';
import FormGeneralErrors from '../Common/FormGeneralErrors';
import { Alert } from 'reactstrap';
import Loader from '../Common/Loader';

type ZottFormProps = {
    operationName: string,
    operationTitle: string,
    operationButtonText: string
} & Partial<DefaultProps>;

type ZottFormState = {
    success: boolean
    errors: {[key: string]:string[]},
    submitting: boolean
};

const defaultPropsValues = {
      initFormError: '' as string
};

type DefaultProps = Readonly<typeof defaultPropsValues>;

export default class ZottForm extends React.PureComponent<ZottFormProps, ZottFormState> {
    constructor(props: ZottFormProps) {
        super(props);

        this.state = {
            success: false,
            errors: {},
            submitting: false
        };
    }

    public render() {
        if (this.props.initFormError) {
            return(
                <form className="form">
                    <h3>{this.props.operationTitle}</h3>
                    <Alert color="danger">{this.props.initFormError}</Alert>
                    <ButtonWithRoute className="btn btn-block" path="/zott">Anuluj</ButtonWithRoute>
                </form>
            );
        }

        if (this.state.success) {
            return <Redirect to="/zott"/>
        }

        const childrenWithProps = React.Children.map(this.props.children, (child, index) => {
            return (child)
                ? React.cloneElement(child as React.ReactElement<any>, {errors: this.state.errors})
                : null
            });

        return (
            <form className="form" onSubmit={this.onSubmit.bind(this)} autoComplete="off">
                <h3>{this.props.operationTitle}</h3>
                <FormGeneralErrors errors={this.state.errors}></FormGeneralErrors>
                {childrenWithProps}
                <FormAreaField name="additionalInfo" label="Dodatkowe informacje" errors={this.state.errors}/>
                <button type="submit" className="btn btn-primary btn-block" disabled={this.state.submitting}>{this.props.operationButtonText}</button>
                <ButtonWithRoute className="btn btn-block" path="/zott" disabled={this.state.submitting} >Anuluj</ButtonWithRoute>
                {this.state.submitting && <Loader />}
            </form>
        );
    }

    private async onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        if (this.state.submitting) {
            return;
        }

        let formElement = formEvent.target as HTMLFormElement;

        // TODO - validation function as props
        if (this.validateForm()) {
            await this.submitForm(formElement);
        }
    }

    private validateForm() : boolean {
        return true;
    }

    private async submitForm(formElement: HTMLFormElement) {
        let data = new FormData(formElement);

        this.setState({
            submitting: true
        });

        data.set('operationName', this.props.operationName);
        data.set('operationDateTime', new Date().toISOString())

        var result = await ApiRequest('api/zott/operation', data);

        this.setState({
            submitting: false
        });

        if (result.status === 200) {
            this.setState({
                success: true
            });
        } else {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({
                    errors: resultData.errors
                });
            }
        }
    }
}