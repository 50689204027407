import * as React from 'react';
import { ApiRequest } from '../../services/apiClient';
import { StringDictionary, FuelingEntry } from '../../types/global';
import moment from 'moment';
import Loader from '../Common/Loader';
import FormSelect from '../Common/FormSelect';
import SortableTable, { ColumnOptionsCollection } from '../Common/SortableTable';
import { ExportCSV } from '../Common/ExportCSVButton';
import * as DaoHelper from '../../helpers/DaoHelper';
import "react-datepicker/dist/react-datepicker.css";
import FormGeneralErrors from '../Common/FormGeneralErrors';
import FormDatePicker from '../Common/FormDatePicker';
import { FileDateFormat, DateToDateString } from '../../helpers/DateFormatter';

type FuelingsSelectData = {
    users: StringDictionary
};

type FuelingsReportData = {
    fromDate: Date,
    toDate: Date,
    userId: string,
    userName: string,
}

type ThisFormProps = {
};

type ThisFormState = {
    fromDate: Date,
    toDate: Date,
    users: StringDictionary,
    isLoading: boolean,
    isLoaded: boolean,
    errors: {[key: string]:string[]},
    showForm: boolean,
    currentReportData: FuelingsReportData | undefined,
    fuelingRows?: FuelingEntry[]
}

export default class FuelingsReport extends React.PureComponent<ThisFormProps, ThisFormState> {
    constructor(props: ThisFormProps) {
        super(props);

        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
            users: {},
            isLoading: false,
            isLoaded: false,
            errors: {},
            showForm: true,
            currentReportData: undefined,
        }
    }

    public render() {
        if (this.state.isLoading)
        {
            return <Loader/>;
        }
        return(
            <div className="container">
                <h1>Tankowania</h1>
                <FormGeneralErrors errors={this.state.errors}/>
                {this.state.showForm &&
                <form className="form" onSubmit={this.getReportData.bind(this)}>
                    <FormDatePicker errors={this.state.errors} label="Od" name="fromDate" selected={this.state.fromDate} onChange={this.onFromDateChange.bind(this)}/>
                    <FormDatePicker errors={this.state.errors} label="Do" name="toDate" selected={this.state.toDate} onChange={this.onToDateChange.bind(this)}/>
                    <FormSelect name="userId" values={this.state.users} label="Użytkownik" allowPromptSelection={true}/>
                    <div className="form-group">
                        <button type="submit" className="btn btn-outline-secondary btn-sm ml-4">Generuj raport</button>
                    </div>
                </form>
                }            
                {!this.state.showForm && <button onClick={() => this.showForm()} className="btn btn-outline-secondary btn-sm ml-4">Kolejny raport</button>}
                {this.hasReportData() && <ExportCSV className="btn btn-outline-secondary btn-sm ml-4" label="Eksportuj do Excela" csvData={this.dataForExcel()} fileName={this.reportFileName()}/>}
                {this.hasReportData() && <p>{this.reportTitle()}</p>}
                {this.state.isLoaded
                    && <SortableTable
                        idKey="lp"
                        disableSorting={true}
                        renderNoData={this.renderNoData}
                        dataRows={this.state.fuelingRows}
                        columns={this.dataColumns()}
                        columnOptions={this.columnOptions()} />}
            </div>
        );
    }

    public componentDidMount() {
        this.fetchFuelingsReportSelectData();
    }

    private showForm() {
        this.setState({
            showForm: true
        });
    }

    private async fetchFuelingsReportSelectData() {
        this.setState({
            isLoading: true
        });

        const result = await ApiRequest('api/reports/fuelingsselectdata');

        this.setState({
            isLoading: false
        });

        if (result.status !== 200) {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
            return;
        }

        const selectData = await result.json() as FuelingsSelectData;

        this.setState({
            users: selectData.users
        });
    }

    private async getReportData(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        const formElement = formEvent.target as HTMLFormElement;
        const data = new FormData(formElement);

        let userId = data.get('userId') as string;
        if (userId === '0') userId = '';

        const reportData : FuelingsReportData = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: userId,
            userName: this.state.users[userId],
        }

        const result = await ApiRequest('api/reports/fuelings', data);
        if (result.status !== 200) {
            var resultData = await result.json();
            if (resultData.errors) {
                this.setState({errors: resultData.errors});
            }
            return;
        }

        const fuelingRows = await DaoHelper.JsonParseResult(result) as FuelingEntry[];

        DaoHelper.EnhanceFuelingsCollection(fuelingRows);

        var showForm = !fuelingRows || !fuelingRows.length;

        this.setState({
            errors: {},
            fuelingRows: fuelingRows,
            isLoaded: true,
            currentReportData: reportData,
            showForm: showForm
        });
    }

    private dataColumns() {
        const columns = {} as StringDictionary;

        columns['lp'] = 'L.p.';
        columns['userName'] = 'Użytkownik';
        columns['registrationNumber'] = 'Numer rej.';
        columns['dateTimeText'] = 'Data';
        columns['kilometer'] = 'Przebieg';
        columns['litres'] = 'Litry';
        columns['mileage'] = 'Spalanie';
        columns['priceNetto'] = 'Wartość netto';
        columns['wz'] = 'WZ';
        columns['notes'] = 'Notatki';

        return columns;
    }

    private columnOptions() {
        const columnOptions = {} as ColumnOptionsCollection;

        columnOptions['mileage'] = {
            precision: 2
        };

        return columnOptions;
    }

    private onFromDateChange(date: Date) {
        this.setState({
            fromDate: date
        });
    }

    private onToDateChange(date: Date) {
        this.setState({
            toDate: date
        });
    }

    private hasReportData() : boolean
    {
        return !!this.state.fuelingRows
            && this.state.fuelingRows.length > 0;
    }

    private dataForExcel()
    {
        return this.state.fuelingRows!.map(this.rowToExcel);
    }

    private rowToExcel(f: FuelingEntry)
    {
        return {
            'Użytkownik': f.userName,
            'Numer rej.': f.registrationNumber,
            'Data': f.dateTimeText,
            'Przebieg': f.kilometer,
            'Litry': f.litres,
            'Spalanie': f.mileage,
            'Wartość netto': f.priceNetto,
            'WZ': f.wz,
            'Notatki': f.notes,
        };
    }

    private reportFileName() : string
    {
        var reportData = this.state.currentReportData;
        if (!reportData) return '';

        let fileName : string = 'RaportTankowań_' + moment(reportData!.fromDate).format(FileDateFormat) + '_' + moment(reportData!.toDate).format(FileDateFormat);

        if (reportData!.userName) fileName += '_' + reportData!.userName;

        return fileName;
    }

    private reportTitle() : string {
        if (!this.state.currentReportData) return '';

        const reportData = this.state.currentReportData!;

        let title : string = 'Raport tankowań ' + DateToDateString(reportData.fromDate) + '-' + DateToDateString(reportData.toDate);
        if (reportData.userName) {
            title += ' ' + reportData.userName;
        }
        return title;
    }

    private renderNoData() {
        return (
            <p>Brak tankowań</p>
        );
    }
}
